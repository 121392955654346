export default {
  getShipstationInfo(state) {
    return state.shipstationInfo;
  },
  getPairingWareHouses(state) {
    return state.pairingWarehouses;
  },
  getPairingStores(state) {
    return state.pairingStores;
  },
  getStepCompleted: (state) => (step) => {
    switch (step) {
      case 1:
        return (
          state.shipstationInfo.storeCode.trim() &&
          state.shipstationInfo.apiKey.trim() &&
          state.shipstationInfo.apiSecret.trim() &&
          state.shipstationInfo.apiKey.length > 8 &&
          state.shipstationInfo.apiSecret.length > 8
        );
      case 2:
        return state.pairingStores.length > 0;
      case 3:
        return state.pairingWarehouses.length > 0;
      default:
        return true; // Return false for any invalid step number
    }
  },
};
