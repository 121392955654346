import state from './moduleSimilarProductState';
import mutations from './moduleSimilarProductMutations';
import actions from './moduleSimilarProductActions';
import getters from './moduleSimilarProductGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
