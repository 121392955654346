export default {
  postponeObject: (state) => {
    return {
      postponed: state.postponed,
      postponedAt: state.postponedAt,
      postponedForMinutes: state.postponedForMinutes,
      postponedForStore: state.postponedForStore,
      postponedForMarketplace: state.postponedForMarketplace,
    };
  },
};
