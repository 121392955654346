import api from '@/api';

export default {
  checkJobStatus(
    { commit, dispatch },
    { taskId, templateType, isUpload = false, storeIndex }
  ) {
    const COMMIT_TO = isUpload ? 'UPDATE_UPLOAD_TASK' : 'UPDATE_DOWNLOAD_TASK';

    commit(COMMIT_TO, { status: 'PENDING', storeIndex, taskId, templateType });
    api.bearer
      .post({ taskId, templateType }, 'parent-pricing/get-template-task-status')
      .then((response) => {
        const {
          data: {
            ApiStatusCode,
            Data: {
              status,
              errorMessage,
              downloadFileName,
              uploadSkuCount,
              errorSkuCount,
              successfulSkuCount,
              errorFileName,
            },
          },
        } = response;

        if (ApiStatusCode !== 200) throw new Error();
        switch (status) {
          case 0:
          case 1:
          case 4:
            setTimeout(() => {
              dispatch('checkJobStatus', {
                taskId,
                templateType,
                isUpload,
                storeIndex,
              });
            }, 5000);
            return;
          case 2:
            commit(COMMIT_TO, {
              status: 'SUCCESS',
              message: isUpload
                ? 'Parent ASIN Template successfully uploaded'
                : 'Parent ASIN Template is ready to download',
              url: isUpload ? errorFileName : downloadFileName,
              uploadSkuCount,
              errorSkuCount,
              successfulSkuCount,
              storeIndex,
            });
            return;
          case 3:
          default:
            throw { errorMessage };
        }
      })
      .catch(({ errorMessage }) => {
        commit(COMMIT_TO, {
          status: 'ERROR',
          message: errorMessage
            ? errorMessage
            : isUpload
            ? 'Something went wrong while uploading template file. Please try again later'
            : 'Something went wrong while obtaining template file. Please try again later...',
          storeIndex,
        });
      });
  },
  clearTask({ commit }, { storeIndex, isUpload = false }) {
    const COMMIT_TO = isUpload ? 'UPDATE_UPLOAD_TASK' : 'UPDATE_DOWNLOAD_TASK';
    commit(COMMIT_TO, { storeIndex });
  },
};
