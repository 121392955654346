export default {
  SET_ACTIVE_STORE(state, activeStore) {
    state.activeStore = activeStore;
    localStorage.setItem(
      'activeVendorStore',
      activeStore ? JSON.stringify(activeStore) : null
    );
  },
  SET_SELECTED_DATE(state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  SET_CONNECT_ACCOUNT_REQUESTED(state, isConnectAccountRequested) {
    state.isConnectAccountRequested = isConnectAccountRequested;
  },
  SET_COMPONENT_ORDER(state, componentOrder) {
    state.componentOrder = componentOrder;
    localStorage.setItem(
      'vendorComponentOrder',
      JSON.stringify(componentOrder)
    );
  },
  ADD_HIDDEN_SERIES(state, { component, series }) {
    state.hiddenSeries[component].push(series);
  },
  REMOVE_HIDDEN_SERIES(state, { component, series }) {
    state.hiddenSeries[component] = state.hiddenSeries[component].filter(
      (s) => s !== series
    );
  },
  SET_VENDOR_INFO_CARD_STATUS(state, payload) {
    state.vendorInfoCardStatus = payload;
  },
  SET_VENDOR_INFO_REAL_TIME_STATUS(state, payload) {
    state.vendorInfoRealTimeStatus = payload;
  },
};
