export default {
  general_overview: {
    title: 'General Overview',
    hint: 'You can overview your account here',
    terms: {
      previous_period: 'previous period',
      current_period: 'current period',
    },
    net_profit: {
      title: 'Net Profit',
      hint: 'Your calculated profit for the selected period. The calculation of profit is determined by the product costs associated with your SKUs. It is important to accurately input your product costs to ensure precise profit calculations.',
      fields: {
        net_profit: 'Net Profit',
        roi: 'ROI',
        margin: 'Margin',
      },
    },
    total_sales: {
      title: 'Total Sales',
      hint: 'Amount of ordered product sales with a fulfillment type breakdown. You can decide to include or exclude shipping amount and reimbursement amount',
      fields: {
        total_sales: 'Total Sales',
        organic_sales: 'Organic Sales',
        advertising_sales: 'Advertising Sales',
        fba_sales: 'FBA Sales',
        fbm_sales: 'FBM Sales',
        orders: 'Orders',
        units: 'Units',
        shipping_amount: 'Shipping Amount',
        avg_order_amount: 'Average Order Value',
      },
    },
    total_expense: {
      title: 'Total Expense',
      last_settlement_date: 'Last settlement date',
      hint: 'Expenses, fees, and cost amounts that affect the profitability of the store for the selected period. It is important to accurately input your product costs to ensure precise profit calculations.',
      fields: {
        non_total_expense: 'Non Adv. Cost',
        total_expense: 'Total Expense',
        fba_fee: 'FBA Fee',
        refund: 'Refund',
        referral_fee: 'Referral Fee',
        cogs: 'COGS',
        product_cost: 'Product Cost',
        fbm_shipping: 'FBM Shipping',
        additional_cost: 'Additional Cost',
        storage_fee: 'Storage Fee',
        advertising_cost: 'Advertising Cost',
        store_level_expense: 'Store Level Expense',
        amazon_expense: 'Amazon Expense',
      },
    },
    organic_sales: {
      title: 'Organic Sales',
      hint: 'Sales amount of the store in the selected period without direct advertising efforts',
    },
    advertising_sales: {
      title: 'Advertising Sales',
      hint: 'The sales impact of paid advertising',
    },
    advertising_orders: {
      title: 'Advertising Orders',
      hint: 'The orders impact of paid advertising',
    },
    advertising_cost: {
      title: 'Advertising Cost',
      hint: 'The incurred cost associated with advertising activities',
    },
    cogs_and_other_cost: {
      title: 'COGS & Other Cost',
      hint: 'COGS (Cost of Goods Sold) & Other Cost: Remaining costs that are not associated with Advertising Cost or COGS',
      fields: {
        cogs: 'COGS',
        other_cost: 'Other Cost',
      },
    },
    ctr: {
      title: 'CTR',
      hint: 'Click-Through Rate (Clicks / Impressions)',
    },
    cvr: {
      title: 'CVR',
      hint: 'Conversion Rate (Advertising Orders / Clicks)',
    },
    acos: {
      title: 'ACoS',
      hint: 'Advertising Cost of Sales (Ad Spend / Ad Sales) of the store for the selected period. You can switch to the ROAS (Return on Ad Spend) or PRAS (Profit Return on Ad Spend) views by selecting them from the list.',
    },
    roas: {
      title: 'ROAS',
      hint: 'Return on Ad Spend (Ad Sales / Ad Spend) of the store for the selected period. You can switch to the ACOS (Advertising Cost of Sales) or PRAS (Profit Return on Ad Spend) views by selecting them from the list.',
    },
    acop: {
      title: 'ACoP',
      hint: 'Advertising Cost of Profit (Ad Spend / Profit of Advertised Product) of the store in the selected period.',
    },
    tacop: {
      title: 'TACoP',
      hint: 'Total Advertising Cost of Profit (Ad Spend / Total Profit) of the store for the selected period. You can switch to the TACoS (Total Advertising Cost of Sales) or TPRAS (Total Profit Return on Ad Spend) views by selecting them from the list.',
    },
    tacos: {
      title: 'TACoS',
      hint: 'Total Advertising Cost of Sales (Ad Spend / Total Sales) of the store for the selected period. You can switch to the TACoP (Total Advertising Cost of Profit) or TPRAS (Total Profit Return on Ad Spend) views by selecting them from the list.',
    },
    ad_sales: {
      title: 'Ad Sales',
    },
    total_cost: {
      title: 'Total Cost',
    },
    other_cost: {
      title: 'Other Cost',
    },
    ad_spend: {
      title: 'Ad Spend',
    },
    total_orders: {
      title: 'Total Orders',
      hint: 'Sum of Advertising Orders and Organic Orders',
    },
    organic_orders: {
      title: 'Organic Orders',
      hint: 'Total Orders - Ad Orders',
    },
    ad_orders: {
      title: 'Ad Orders',
    },
    pras: {
      title: 'PRAS',
      hint: 'Profit Return on Ad Spend (Profit / Ad Spend) of the store for the selected period. You can switch to the ACOS (Advertising Cost of Sales) or ROAS (Return on Ad Spend) views by selecting them from the list.',
    },
    tpras: {
      title: 'TPRAS',
      hint: 'Total Profit Return on Ad Spend (Total Profit / Ad Spend) of the store for the selected period. You can switch to the TACoS (Total Advertising Cost of Sales) or TACoP (Total Advertising Cost of Profit) views by selecting them from the list.',
    },
    clicks: {
      title: 'Clicks',
      hint: 'The number of times users clicks on an ad',
    },
    impressions: {
      title: 'Impressions',
      hint: 'The number of times an ad is displayed to a user',
    },
    orders: {
      title: 'Ad Orders',
      hint: 'The orders impact of paid advertising',
    },
    spend: {
      title: 'Spend',
      hint: 'Ad Spend',
    },
    sales: {
      title: 'Sales',
      hint: 'Amount of ordered product sales (price of order items * number of units sold for the selected period)',
    },
  },
  sales_expenses: {
    title: 'Sales & Expenses',
    fields: {
      reimbursement: 'Reimbursement',
      total_sales: 'Total Sales',
      fba_sales: 'FBA Sales',
      fbm_sales: 'FBM Sales',
      cogs: 'COGS',
      total_refund: 'Total Refund',
      amazon_expense: 'Amazon Expense',
      total_profit: 'Total Profit',
      quantity: 'Quantity',
      avg_sales_order_item: 'Avg. Sales/Order Item',
      profit: 'Profit',
      qty_units: 'Quantity (Units)',
    },
  },
  marketplace_details: {
    title: 'Marketplace Details',
    fields: {
      profit: 'Profit',
      total_sales: 'Total Sales',
      orders: 'Orders',
      units: 'Units',
    },
  },
  sku_inventory_details: {
    title: 'SKU & Inventory Details',
    fields: {
      sku: 'SKUs',
      total_sales: 'Total Sales',
      unit: 'Unit',
    },
  },
  sku_status: {
    title: 'SKU Status',
    hint: 'Total & Pricing Enabled in stock SKUs are updated every 24 hours. Pricing enabled in stock SKUs are updated every hour.',
    labels: {
      total_skus: 'Total SKUs',
      pricing_enabled_skus: 'Pricing Enabled SKUs',
      pricing_enabled_instock_skus: 'Pricing Enabled In Stock SKUs',
    },
  },
  buy_box_ownership: {
    title: 'Buy Box Ownership',
    labels: {
      owner_skus: 'Buy Box Owner SKUs',
      chasing_skus: 'Buy Box Chasing SKUs',
      suppressed_skus: 'Suppressed Buy Box SKUs',
    },
  },
  pricing_activity: {
    title: 'Pricing Activity',
    labels: {
      pricing_decisions: 'Pricing Decisions',
      price_change_above_min: 'Price Change Above Min. Price',
      increased_sales_price: 'Increased Sales Price',
    },
  },
  sales_order_heatmap: {
    title: 'Sales Order Heatmap',
    top_five_states: 'Top 5 States',
    fields: {
      amount: 'Amount',
      quantity: 'Quantity',
    },
  },
  sku_info: {
    alert: (ctx) => `${ctx.named('costExistSkuCount')} out of
    ${ctx.named('totalSalesSku')}
    SKU's cost does not exist.`,
    please_update: 'please update',
    update: `your SKUs' cost info in order to see more accurate profit.`,
  },
};
