export default {
  setAcosRoasSelect({ commit }, payload) {
    commit('SET_ACOS_ROAS_SELECT', payload);
  },
  setTacosTacopTprasSelect({ commit }, payload) {
    commit('SET_TACOS_TACOP_TPRAS_SELECT', payload);
  },
  setAdvertisingSalesAdvertisingOrdersSelect({ commit }, payload) {
    commit('SET_ADVERTISING_SALES_ADVERTISING_ORDERS_SELECT', payload);
  },
  setOrganicSalesOrganicOrdersSelect({ commit }, payload) {
    commit('SET_ORGANIC_SALES_ORGANIC_ORDERS_SELECT', payload);
  },
  setTotalSalesTotalOrdersSelect({ commit }, payload) {
    commit('SET_TOTAL_SALES_TOTAL_ORDERS_SELECT', payload);
  },
};
