export default {
  setSelectedReportType({ commit }, payload) {
    commit('SET_SELECTED_REPORT_TYPE', payload);
  },
  setReportName({ commit }, payload) {
    commit('SET_REPORT_NAME', payload);
  },
  setStepCompleted({ commit }, payload) {
    commit('SET_STEP_COMPLETED', payload);
  },
  clearReportingSteps({ commit }) {
    commit('CLEAR_REPORTING_STEPS');
  },
  setSelectedStores({ commit }, payload) {
    commit('SET_SELECTED_STORES', payload);
  },
  setSelectedFrequency({ commit }, payload) {
    commit('SET_SELECTED_FREQUENCY', payload);
  },
  setSelectedDateRange({ commit }, payload) {
    commit('SET_SELECTED_DATE_RANGE', payload);
  },
  setSelectedInterval({ commit }, payload) {
    commit('SET_SELECTED_INTERVAL', payload);
  },
  setSelectedAverageOf({ commit }, payload) {
    commit('SET_SELECTED_AVERAGE_OF', payload);
  },
  setSelectedNegativeProfitSwitch({ commit }, payload) {
    commit('SET_SELECTED_NEGATIVE_PROFIT_SWITCH', payload);
  },
  setSelectedCreateOn({ commit }, payload) {
    commit('SET_SELECTED_CREATE_ON', payload);
  },
  setSelectedCompareStartDay({ commit }, payload) {
    commit('SET_SELECTED_COMPARE_START_DAY', payload);
  },
  setSelectedCompareEndDay({ commit }, payload) {
    commit('SET_SELECTED_COMPARE_END_DAY', payload);
  },
  setDateFormat({ commit }, payload) {
    commit('SET_DATE_FORMAT', payload);
  },
  setDatePickerType({ commit }, payload) {
    commit('SET_DATE_PICKER_TYPE', payload);
  },
  setEndDate({ commit }, payload) {
    commit('SET_END_DATE', payload);
  },
  setEmailList({ commit }, payload) {
    commit('SET_EMAIL_LIST', payload);
  },
  setEditReportData({ commit }, payload) {
    commit('SET_EDIT_REPORT_DATA', payload);
  },
  changeEditSelectedStores({ commit }, payload) {
    commit('CHANGE_EDIT_SELECTED_STORES', payload);
  },
  changeEditEndDate({ commit }, payload) {
    commit('CHANGE_EDIT_END_DATE', payload);
  },
  changeEditSelectedEmails({ commit }, payload) {
    commit('CHANGE_EDIT_SELECTED_EMAILS', payload);
  },
  changeEditNegativeProfitSwitch({ commit }, payload) {
    commit('CHANGE_EDIT_NEGATIVE_PROFIT_SWITCH', payload);
  },

  //REPORT HISTORY FILTERS AND SEARCH
  setReportHistorySearch({ commit }, payload) {
    commit('SET_REPORT_HISTORY_SEARCH', payload);
  },
  setReportHistoryFilters({ commit }, payload) {
    commit('SET_REPORT_HISTORY_FILTERS', payload);
  },

  //REPORT CONFIGURATION FILTERS AND SEARCH
  setReportConfigurationsSearch({ commit }, payload) {
    commit('SET_REPORT_CONFIGURATIONS_SEARCH', payload);
  },
  setReportConfigurationsFilters({ commit }, payload) {
    commit('SET_REPORT_CONFIGURATIONS_FILTERS', payload);
  },

  setReportHistoryApiRequestStatus({ commit }, payload) {
    commit('SET_REPORT_HISTORY_API_REQUEST_STATUS', payload);
  },
  setReportConfigurationsApiRequestStatus({ commit }, payload) {
    commit('SET_REPORT_CONFIGURATIONS_API_REQUEST_STATUS', payload);
  },
};
