export default {
  dateRange(state) {
    return state.dateRange;
  },
  dashboardDateRange(state) {
    return state.dashboardDateRange;
  },
  dashboardDateRangeType(state) {
    return state.dashboardDateRangeType;
  },
  getPeriodDates(state) {
    return state.dashboardData.periodDates;
  },
  getAdvertisingAvailableDateRange(state) {
    return state.advertisingAvailableDateRange;
  },
};
