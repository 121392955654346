export default {
  getSelectedMarket(state) {
    return state.selectedMarket;
  },
  getSelectedMarketType(state) {
    return state.selectedMarketType;
  },
  getWalmartInfo(state) {
    return state.walmartInfo;
  },
  getConnectedStore(state) {
    return state.connectedStore;
  },
  getUserInfo(state) {
    return state.userInfo;
  },
  getRegionInfo(state) {
    return state.selectedRegion;
  },
};
