export default {
  SET_DATERANGE(state, payload) {
    state.dateRange = payload;
  },

  SET_DASHBOARD_DATERANGE(state, payload) {
    state.dashboardDateRange = payload;
  },

  SET_DASHBOARD_DATERANGE_TYPE(state, payload) {
    state.dashboardDateRangeType = payload;
  },

  SET_PERIOD_DATES(state, payload) {
    state.dashboardData.periodDates = payload;
  },

  SET_DASHBOARD_AVAILABLE_DATE_RANGE(state, payload) {
    state.advertisingAvailableDateRange = payload;
  },
};
