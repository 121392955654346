import state from './moduleManageDimensionState';
import mutations from './moduleManageDimensionMutations';
import actions from './moduleManageDimensionActions';
import getters from './moduleManageDimensionGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
