export default {
  SET_POSTPONED(state, payload) {
    state.postponed = payload;
  },
  SET_POSTPONED_AT(state, payload) {
    state.postponedAt = payload;
  },
  SET_POSTPONED_FOR_MINUTES(state, payload) {
    state.postponedForMinutes = payload;
  },
  SET_POSTPONED_FOR_STORE(state, payload) {
    state.postponedForStore = payload;
  },
  SET_POSTPONED_FOR_MARKETPLACE(state, payload) {
    state.postponedForMarketplace = payload;
  },
};
