import localForage from 'localforage';
import store from '../store';
const updateLocalStorage = async (storeIndex, taskId, type, templateType) => {
  const storeInstance = await store();
  const userInfo =
    storeInstance.getters['user'] || (await localForage.getItem('userInfo'));
  if (!userInfo.user.store[storeIndex].parentAsinJobs) {
    userInfo.user.store[storeIndex].parentAsinJobs = {
      uploadUUID: null,
      downloadUUID: null,
      uploadTemplateType: null,
      downloadTemplateType: null,
    };
  }

  userInfo.user.store[storeIndex].parentAsinJobs[`${type}UUID`] = taskId;
  userInfo.user.store[storeIndex].parentAsinJobs[`${type}TemplateType`] =
    templateType;
  await storeInstance.dispatch('userInfo/loadInfo', userInfo);
  localStorage.setItem(
    'packageInformation',
    JSON.stringify(userInfo.user.packageInformation)
  );
};

export default {
  UPDATE_DOWNLOAD_TASK(
    state,
    {
      taskId = null,
      templateType = null,
      status = 'IDLE',
      message = null,
      url = null,
      storeIndex,
    } = {
      taskId: null,
      templateType: null,
      status: 'IDLE',
      message: null,
      url: null,
    }
  ) {
    state.download.taskId = taskId;
    state.download.templateType = templateType;
    state.download.status = status;
    state.download.message = message;
    state.download.url = url;
    updateLocalStorage(storeIndex, taskId, 'download', templateType);
  },
  UPDATE_UPLOAD_TASK(
    state,
    {
      taskId = null,
      templateType = null,
      status = 'IDLE',
      message = null,
      uploadSkuCount = null,
      errorSkuCount = null,
      successfulSkuCount = null,
      url = null,
      storeIndex,
    } = {
      taskId: null,
      templateType: null,
      status: 'IDLE',
      message: null,
      uploadSkuCount: null,
      errorSkuCount: null,
      url: null,
      successfulSkuCount: null,
    }
  ) {
    state.upload.taskId = taskId;
    state.download.templateType = templateType;
    state.upload.status = status;
    state.upload.message = message;
    state.upload.uploadSkuCount = uploadSkuCount;
    state.upload.errorSkuCount = errorSkuCount;
    state.upload.successfulSkuCount = successfulSkuCount;
    state.upload.url = url;
    updateLocalStorage(storeIndex, taskId, 'upload', templateType);
  },
};
