export default {
  setActiveStore({ commit }, payload) {
    commit('SET_ACTIVE_STORE', payload);
  },
  setSelectedDate({ commit }, payload) {
    commit('SET_SELECTED_DATE', payload);
  },
  setComponentOrder({ commit }, payload) {
    commit('SET_COMPONENT_ORDER', payload);
  },
  setConnectAccountRequested({ commit }, payload) {
    commit('SET_CONNECT_ACCOUNT_REQUESTED', payload);
  },
  setVendorInfoCardStatus({ commit }, payload) {
    commit('SET_VENDOR_INFO_CARD_STATUS', payload);
  },
  setVendorInfoRealTimeStatus({ commit }, payload) {
    commit('SET_VENDOR_INFO_REAL_TIME_STATUS', payload);
  },
  setHiddenSeries({ commit }, payload) {
    if (payload.isHidden) {
      commit('ADD_HIDDEN_SERIES', payload);
    } else {
      commit('REMOVE_HIDDEN_SERIES', payload);
    }
  },
};
