/*=========================================================================================
  File Name: moduleSkuPerformance.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './moduleSkuPerformanceState.js';
import mutations from './moduleSkuPerformanceMutations.js';
import actions from './moduleSkuPerformanceActions.js';
import getters from './moduleSkuPerformanceGetters.js';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
