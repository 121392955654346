export default {
  SET_SELECTED_MARKET(state, payload) {
    state.selectedMarket = payload;
  },

  CLEAR_SELECTED_MARKET(state) {
    state.selectedMarket = null;
  },
  SET_SELECTED_MARKET_TYPE(state, payload) {
    state.selectedMarketType = payload;
  },

  CLEAR_SELECTED_MARKET_TYPE(state) {
    state.selectedMarketType = null;
  },
  SET_WALMART_INFO(state, payload) {
    state.walmartInfo = payload;
  },

  CLEAR_WALMART_INFO(state) {
    state.walmartInfo = {
      walmartPartnerId: '',
      walmartClientId: '',
      walmartClientSecretId: '',
      marketplace: '',
    };
  },
  SET_CONNECTED_STORE(state, payload) {
    state.connectedStore = payload;
  },
  CLEAR_CONNECTED_STORE(state) {
    state.connectedStore = [];
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },

  CLEAR_USER_INFO(state) {
    state.userInfo = {
      displayName: '',
      country: '',
      phoneCountryCode: '',
      callingCode: '',
      telephoneNumber: '',
      phoneNumber: '',
      nationalNumber: '',
    };
  },
  SET_REGION_INFO(state, payload) {
    state.selectedRegion = payload;
  },

  CLEAR_REGION_INFO(state) {
    state.selectedRegion = null;
  },
};
