export default {
  getDownloadSimilarProductObject: (state, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    if (!state.similarProductDownloadTask[evaStoreId]) return null;
    return state.similarProductDownloadTask[evaStoreId];
  },
  getDownloadSimilarProductTemplateStatus: (state, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    if (!state.similarProductDownloadTask[evaStoreId]) return null;
    return state.similarProductDownloadTask[evaStoreId]?.status;
  },
  getDownloadSimilarProductTemplateFileLink: (state, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    if (!state.similarProductDownloadTask[evaStoreId]) return null;
    return state.similarProductDownloadTask[evaStoreId]?.downloadFileName;
  },
  getUploadSimilarProductObject: (state, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    if (!state.similarProductUploadTask[evaStoreId]) return {};
    return state.similarProductUploadTask[evaStoreId];
  },
  getUploadSimilarProductTemplateStatus: (state, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    if (!state.similarProductUploadTask[evaStoreId]) return null;
    return state.similarProductUploadTask[evaStoreId]?.status;
  },
  getUploadSimilarProductTemplateFileLink: (state, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    if (!state.similarProductUploadTask[evaStoreId]) return null;
    return state.similarProductUploadTask[evaStoreId]?.downloadFileName;
  },
};
