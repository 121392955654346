"use strict";
/**
 * @description List of pages that does not require seller store (array contains route names)
 * @type {string[]}
 * @example
 * ['VendorManagement']
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOP_N_TAGS = exports.VISIBLE_SKU_TAG_COUNT = exports.SKU_TAGS_LIGHT_BORDER_COLORS = exports.SKU_TAGS_LIGHT_BACKGROUND_COLORS = exports.SKU_TAGS_DARK_BORDER_COLORS = exports.SKU_TAGS_DARK_BACKGROUND_COLORS = exports.SKU_TAGS_COLORS = exports.REPRICER_OPTIONS = exports.SKU_STATUS_OPTIONS = exports.FULFILLMENT_OPTIONS = exports.FIXED_COLUMNS = exports.COLUMN_WIDTHS = exports.COLUMN_OPTIONS = void 0;
exports.COLUMN_OPTIONS = [
    {
        key: 'tagList',
        text: 'Tags',
        visible: true,
    },
    {
        key: 'pricing_status',
        text: 'Pricing Status',
        visible: true,
    },
    {
        key: 'total_sales',
        text: 'Total Sales',
        visible: true,
    },
    {
        key: 'organic_sales',
        text: 'Organic Sales',
        visible: true,
    },
    {
        key: 'adv_same_sku_sales',
        text: 'Ad SKU Sales',
        visible: true,
    },
    {
        key: 'attributed_adv_sales',
        text: 'Attributed Ad Sales',
        visible: true,
    },
    {
        key: 'adv_sales',
        text: 'Total Ad Sales',
        visible: false,
    },
    {
        key: 'adv_spend',
        text: 'Ad Spend',
        visible: true,
    },
    {
        key: 'margin',
        text: 'Margin',
        visible: true,
    },
    {
        key: 'roi',
        text: 'ROI',
        visible: false,
    },
    {
        key: 'profit',
        text: 'Profit',
        visible: true,
    },
    {
        key: 'product_cost',
        text: 'Product Cost',
        visible: false,
    },
    {
        key: 'fbm_shipping',
        text: 'FBM Shipping',
        visible: false,
    },
    {
        key: 'shipping_to_fba_cost',
        text: 'Shipping to FBA Cost',
        visible: false,
    },
    {
        key: 'fba_fee',
        text: 'FBA Fee',
        visible: false,
    },
    {
        key: 'referral_fee',
        text: 'Referral Fee',
        visible: false,
    },
    {
        key: 'tacos',
        text: 'TACoS',
        visible: false,
    },
    {
        key: 'acos',
        text: 'ACoS',
        visible: false,
    },
    {
        key: 'ctr',
        text: 'CTR',
        visible: false,
    },
    {
        key: 'cvr',
        text: 'CVR',
        visible: false,
    },
    {
        key: 'unit',
        text: 'Unit',
        visible: false,
    },
    {
        key: 'order',
        text: 'Order',
        visible: false,
    },
    {
        key: 'refund',
        text: 'Refund',
        visible: false,
    },
    {
        key: 'impressions',
        text: 'Impressions',
        visible: false,
    },
    {
        key: 'sessions',
        text: 'Sessions',
        visible: false,
    },
    {
        key: 'available_quantity',
        text: 'Available',
        visible: false,
    },
    {
        key: 'fc_transfer_quantity',
        text: 'FC Transfer',
        visible: false,
    },
    {
        key: 'inbound_quantity',
        text: 'Inbound',
        visible: false,
    },
    {
        key: 'brand',
        text: 'Brand',
        visible: false,
    },
];
exports.COLUMN_WIDTHS = [
    {
        key: 'checkbox',
        width: 45,
    },
    {
        key: 'image',
        width: 75,
    },
    {
        key: 'product_info',
        width: 320,
    },
    {
        key: 'tagList',
        width: 200,
    },
    {
        key: 'pricing_status',
        width: 100,
    },
    {
        key: 'total_sales',
        width: 150,
    },
    {
        key: 'organic_sales',
        width: 150,
    },
    {
        key: 'adv_same_sku_sales',
        width: 150,
    },
    {
        key: 'attributed_adv_sales',
        width: 175,
    },
    {
        key: 'adv_sales',
        width: 150,
    },
    {
        key: 'adv_spend',
        width: 150,
    },
    {
        key: 'margin',
        width: 150,
    },
    {
        key: 'roi',
        width: 150,
    },
    {
        key: 'profit',
        width: 150,
    },
    {
        key: 'product_cost',
        width: 150,
    },
    {
        key: 'fbm_shipping',
        width: 150,
    },
    {
        key: 'shipping_to_fba_cost',
        width: 175,
    },
    {
        key: 'fba_fee',
        width: 150,
    },
    {
        key: 'referral_fee',
        width: 150,
    },
    {
        key: 'tacos',
        width: 150,
    },
    {
        key: 'acos',
        width: 150,
    },
    {
        key: 'ctr',
        width: 150,
    },
    {
        key: 'cvr',
        width: 150,
    },
    {
        key: 'unit',
        width: 150,
    },
    {
        key: 'order',
        width: 150,
    },
    {
        key: 'refund',
        width: 150,
    },
    {
        key: 'impressions',
        width: 150,
    },
    {
        key: 'sessions',
        width: 150,
    },
    {
        key: 'available_quantity',
        width: 150,
    },
    {
        key: 'fc_transfer_quantity',
        width: 150,
    },
    {
        key: 'inbound_quantity',
        width: 150,
    },
    {
        key: 'brand',
        width: 150,
    },
    {
        key: 'details_button',
        width: 100,
    },
];
exports.FIXED_COLUMNS = [
    {
        key: 'checkbox',
        text: '',
        fixed: 'left',
    },
    {
        key: 'image',
        text: '',
        fixed: 'left',
    },
    {
        key: 'product_info',
        text: '',
        fixed: 'left',
    },
    {
        key: 'details_button',
        text: '',
        fixed: 'right',
    },
];
exports.FULFILLMENT_OPTIONS = {
    ALL: {
        KEY: 'All',
        VALUE: 'All'
    },
    AMAZON: {
        KEY: 'Amazon',
        VALUE: 'Amazon'
    },
    MERCHANT: {
        KEY: 'Merchant',
        VALUE: 'Merchant'
    }
};
exports.SKU_STATUS_OPTIONS = {
    ALL: {
        KEY: 'All',
        VALUE: 'All'
    },
    FC_TRANSFER: {
        KEY: 'Active-Backorder',
        VALUE: 'FC Transfer'
    },
    AVAILABLE: {
        KEY: 'Active-Available',
        VALUE: 'Available'
    },
    OUT_OF_STOCK: {
        KEY: 'Inactive-OutOfStock',
        VALUE: 'Out Of Stock'
    },
    CLOSED: {
        KEY: 'Inactive-Closed',
        VALUE: 'Closed'
    },
    BLOCKED: {
        KEY: 'Inactive-Blocked',
        VALUE: 'Blocked'
    },
    INBOUND: {
        KEY: 'Inbound',
        VALUE: 'Inbound'
    }
};
exports.REPRICER_OPTIONS = {
    ALL: {
        KEY: 'All',
        VALUE: 'All'
    },
    ENABLED: {
        KEY: 'yes',
        VALUE: 'Enabled'
    },
    DISABLED: {
        KEY: 'no',
        VALUE: 'Disabled'
    }
};
exports.SKU_TAGS_COLORS = {
    GRAY: '#C6C4C4',
    ANTRASIT: '#5C5F61',
    RED: '#E0726E',
    ORANGE: '#DF9277',
    YELLOW: '#E9BF78',
    GREEN: '#64AD86',
    BLUE: '#486EB7',
    PURPLE: '#9461BC',
};
exports.SKU_TAGS_DARK_BACKGROUND_COLORS = {
    GRAY: '#3B434A',
    ANTRASIT: '#21272C',
    RED: '#3E0F0F',
    ORANGE: '#4C2D09',
    YELLOW: '#2D2404',
    GREEN: '#073328',
    BLUE: '#0D2935',
    PURPLE: '#25082A',
};
exports.SKU_TAGS_DARK_BORDER_COLORS = {
    GRAY: '#758794',
    ANTRASIT: '#46545E',
    RED: '#631313',
    ORANGE: '#A2551D',
    YELLOW: '#564508',
    GREEN: '#0E6139',
    BLUE: '#21556C',
    PURPLE: '#581363'
};
exports.SKU_TAGS_LIGHT_BACKGROUND_COLORS = {
    GRAY: '#F8F8F8',
    ANTRASIT: '#E4E5E7',
    RED: '#F7DEDE',
    ORANGE: '#FFE1BD',
    YELLOW: '#FBECBA',
    GREEN: '#CEF1E8',
    BLUE: '#D1E8F2',
    PURPLE: '#F0DFF3',
};
exports.SKU_TAGS_LIGHT_BORDER_COLORS = {
    GRAY: '#E6E6E6',
    ANTRASIT: '#AFC2CF',
    RED: '#EDB1B1',
    ORANGE: '#C2AFA1',
    YELLOW: '#F1DA87',
    GREEN: '#9FCCB6',
    BLUE: '#96BCCD',
    PURPLE: '#D4BCD9'
};
exports.VISIBLE_SKU_TAG_COUNT = 3;
exports.TOP_N_TAGS = 5;
