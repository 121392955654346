import { reportTypes } from '@/views/components/reporting-dashboard/enumsAndConfigs.js';
export default {
  getSelectedReportType(state) {
    return state.selectedReportType;
  },
  getReportName(state) {
    return state.reportName;
  },
  getSelectedStores(state) {
    return state.selectedStores;
  },
  getSelectedFrequency(state) {
    return state.selectedFrequency;
  },
  getSelectedDateRange(state) {
    return state.selectedDateRange;
  },
  getSelectedInterval(state) {
    return state.selectedInterval;
  },
  getSelectedAverageOf(state) {
    return state.selectedAverageOf;
  },
  getSelectedNegativeProfitSwitch(state) {
    return state.selectedNegativeProfitSwitch;
  },
  getSelectedCreateOn(state) {
    return state.selectedCreateOn;
  },
  getSelectedCompareStartDay(state) {
    return state.selectedCompareStartDay;
  },
  getSelectedCompareEndDay(state) {
    return state.selectedCompareEndDay;
  },
  getDateFormat(state) {
    return state.dateFormat;
  },
  getDatePickerType(state) {
    return state.datePickerType;
  },
  getEndDate(state) {
    if (state.selectedInterval === 2) {
      return state.dailyEndDate;
    } else if (state.selectedInterval === 3) {
      return state.weeklyEndDate;
    } else return state.monthlyEndDate;
  },
  getEmailList(state) {
    return state.emailList;
  },

  getStepCompleted(_, getters) {
    return (val) => {
      return getters[`getStep${val}Completed`];
    };
  },
  getStep1Completed(state) {
    return !!state.selectedReportType;
  },
  getStep2Completed(state) {
    // Temel kontroller
    if (
      !state.reportName ||
      state.selectedStores.length === 0 ||
      !state.selectedFrequency
    ) {
      return false;
    }

    const reportConfig = reportTypes.find(
      (reportType) => reportType.id === state.selectedReportType.id
    ).configs;
    // Config kontrolleri
    switch (state.selectedFrequency) {
      case 1:
        if (reportConfig.dateRange && reportConfig?.reportPeriodDateRange) {
          return state.selectedDateRange.length > 0;
        } else {
          const date = new Date(state.selectedDateRange);
          return !isNaN(date.getTime());
        }
      case 2:
        switch (state.selectedInterval) {
          case 2:
            return !!state.dailyEndDate;
          case 3:
            if (
              reportConfig.compareStartDay.length > 0 &&
              reportConfig.compareEndDay.length > 0
            ) {
              return (
                !!state.weeklyEndDate &&
                !!state.selectedCreateOn &&
                !!state.selectedCompareStartDay &&
                !!state.selectedCompareEndDay
              );
            } else {
              return !!state.weeklyEndDate && !!state.selectedCreateOn;
            }
          case 4:
            return !!state.monthlyEndDate;
          default:
            return false;
        }
      default:
        return false;
    }
  },
  getStep3Completed(state) {
    return state.emailList.length > 0;
  },

  getEditReportData(state) {
    return state.editReportData;
  },

  //REPORT HISTORY FILTERS AND SEARCH
  getReportHistorySearch(state) {
    return state.reportHistory.search;
  },
  getReportHistoryFilters(state) {
    return state.reportHistory.filters;
  },

  //REPORT CONFIGURATION FILTERS AND SEARCH
  getReportConfigurationsSearch(state) {
    return state.reportConfigurations.search;
  },
  getReportConfigurationsFilters(state) {
    return state.reportConfigurations.filters;
  },

  getReportHistoryApiRequestStatus(state) {
    return state.reportHistoryApiRequestStatus;
  },
  getReportConfigurationsApiRequestStatus(state) {
    return state.reportConfigurationsApiRequestStatus;
  },

  getReportPeriodDateInfo(state) {
    return {
      reportPeriodDateRange: state.reportPeriodDateRange,
      reportPeriodDateType: state.reportPeriodDateType,
      reportPeriodDateFormat: state.reportPeriodDateFormat,
    };
  },
};
