export default {
  changeDateRange({ commit }, payload) {
    commit('SET_DATERANGE', payload);
  },

  setDashboardDateRange({ commit }, payload) {
    commit('SET_DASHBOARD_DATERANGE', payload);
  },

  setDashboardDateRangeType({ commit }, payload) {
    commit('SET_DASHBOARD_DATERANGE_TYPE', payload);
  },

  setPeriodDates({ commit }, payload) {
    commit('SET_PERIOD_DATES', payload);
  },
  setDashboardAvailableDateRange({ commit }, payload) {
    commit('SET_DASHBOARD_AVAILABLE_DATE_RANGE', payload);
  },

  changeTrendReportDateRange({ commit }, payload) {
    commit('SET_TRENDREPORT_DATERANGE', payload);
  },
};
