export default {
  selectedMarket: null,
  selectedMarketType: null,
  selectedRegion: null,
  walmartInfo: {
    walmartPartnerId: '',
    walmartClientId: '',
    walmartClientSecretId: '',
    marketplace: '',
  },
  connectedStore: [],
  userInfo: {
    displayName: '',
    country: '',
    phoneCountryCode: '',
    callingCode: '',
    telephoneNumber: '',
    phoneNumber: '',
    nationalNumber: '',
  },
};
