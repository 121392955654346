import state from './moduleParentAsinTemplateState';
import mutations from './moduleParentAsinTemplateMutations';
import actions from './moduleParentAsinTemplateActions';
import getters from './moduleParentAsinTemplateGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
