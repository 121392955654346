export default {
  packagesAndOrderFormPopupStatus: false,
  reminderBannerNotInterestClicked: false,
  platformStepInfo: {
    currentStep: 1,
    totalStep: 4,
    backStepStatus: false,
    stepTitle: 'Start Your Monthly Plan',
    stepDescription:
      'Level up your experience, enjoy enhanced features with our upgraded plan options',
    isSeoCheckboxSelected: false,
    selectedAdditionalPackages: [],
  },
  vendorStepInfo: {
    currentStep: 1,
    totalStep: 3,
    backStepStatus: false,
    stepTitle: 'Start Your Monthly Plan',
    stepDescription:
      'Level up your experience, enjoy enhanced features with our upgraded plan options',
  },
  fullServiceStepInfo: {
    currentStep: 1,
    totalStep: 2,
    backStepStatus: false,
    stepTitle: 'Upgrade your plan',
    stepDescription:
      'Level up your experience, enjoy enhanced features with our upgraded plan options',
  },
  agencyStepInfo: {
    currentStep: 1,
    totalStep: 2,
    backStepStatus: false,
    stepTitle: 'Choose plan',
    stepDescription:
      'Level up your experience, enjoy enhanced features with our upgraded plan options',
  },
  currentStep: 1,
  totalStep: 4,
  stepType: 'Platform', //Platform, FullService, Agency, Vendor
  backStepStatus: false,
  selectableServices: [
    {
      id: 1,
      name: 'Platform',
      isActive: true,
    },
    {
      id: 2,
      name: 'Full Service',
      icon: 'star-rate',
      isActive: false,
    },
  ],
  storeList: [],
  selectedStoreList: [],
  kickoffDate: null,
  orderForm: {
    name: '',
    surname: '',
    email: '',
    callingCode: '',
    countryCode: 'US',
    phoneNumber: '',
    companyName: '',
    billingAddress: '',
    isCheckedTermsAndConditions: false,
  },
  communicationForm: {
    name: '',
    surname: '',
    email: '',
    callingCode: '',
    countryCode: 'US',
    phoneNumber: '',
    companyName: '',
    explanation: '',
  },
  stripePayment: {
    isSuccessPayment: false,
    paymentAmount: null,
    payLoadingView: false,
    payLoading: false,
  },
  calculatedData: {
    isLoading: false,
    calculatedPackageAmount: null,
    proratedAmount: null,
    processingFee: null,
    feeRatio: null,
    cancelPaymentAmount: null,
    cancelProccesingFee: null,
  },
  packageContent: '',
  pricingContent: '',
  reminderBannerStatus: false,
  reminderBannerTitle: '',
  reminderBannerRadioTitle: '',
  reminderBannerRadioText: '',
  acceptOfferRadio: true,
  activeBanner: 'platform',
  openFromHeader: false,

  // Cancellation
  cancellationPopupStatus: false,
  cancellationStep: 1,
  cancellationBackStepStatus: false,
  cancellationReasonType: null,
  cancellationExplanation: '',
  iAggreeCheckbox: false,
  emailToSendStatus: false,

  // Cancellation && Downgrading && Offer Banner
  offerHeader: '',
  offerFirstRadioText: '',
  offerFirstRadioDescription: '',
  offerFirstCheckboxText: '',

  offerSecondRadioText: '',
  offerSecondCheckboxText: '',

  isOfferRadioAccepted: true,
  isOfferCheckboxAccepted: true,

  lastSubEndDate: null,
  nextSubStartDate: null,
  cancelPaymentAmount: null,
};
