export default {
  SET_DOWNLOAD(state, { evaStoreId, download, willReset = false }) {
    if (willReset) {
      state.download = {
        ...state.download,
        [evaStoreId]: null,
      };
      delete state.download[evaStoreId];
    } else {
      state.download = {
        ...state.download,
        [evaStoreId]: {
          ...state.download[evaStoreId],
          ...download,
        },
      };
    }
    localStorage.setItem(
      `download-manage-dimension`,
      JSON.stringify(state.download)
    );
  },
  SET_UPLOAD(state, { evaStoreId, upload, willReset = false }) {
    if (willReset) {
      state.upload = {
        ...state.upload,
        [evaStoreId]: null,
      };
      delete state.upload[evaStoreId];
    } else {
      state.upload = {
        ...state.upload,
        [evaStoreId]: {
          ...state.upload[evaStoreId],
          ...upload,
        },
      };
    }
    localStorage.setItem(
      `upload-manage-dimension`,
      JSON.stringify(state.upload)
    );
  },
};
