export default {
  //invoice
  addInvoicePdf({ commit }, payload) {
    commit('ADD_INVOICE_PDF', payload);
  },
  updateInvoicePdf({ commit }, payload) {
    commit('UPDATE_INVOICE_PDF', payload);
  },
  removeInvoicePdf({ commit }, payload) {
    commit('REMOVE_INVOICE_PDF', payload);
  },
  setInvoicePdfArray({ commit }, payload) {
    commit('SET_INVOICE_PDF_ARRAY', payload);
  },

  //receipt
  addReceiptPdf({ commit }, payload) {
    commit('ADD_RECEIPT_PDF', payload);
  },
  updateReceiptPdf({ commit }, payload) {
    commit('UPDATE_RECEIPT_PDF', payload);
  },
  removeReceiptPdf({ commit }, payload) {
    commit('REMOVE_RECEIPT_PDF', payload);
  },
  setReceiptPdfArray({ commit }, payload) {
    commit('SET_RECEIPT_PDF_ARRAY', payload);
  },
};
