import moment from 'moment';
import { notAssigned } from '@/helper/index.js';
export default {
  activeStore: notAssigned(localStorage.getItem('activeVendorStore'))
    ? null
    : JSON.parse(localStorage.getItem('activeVendorStore')),
  selectedDate: 30,
  lastDataAvailableDate: moment().format('YYYY-MM-DD'),
  componentOrder: JSON.parse(localStorage.getItem('vendorComponentOrder')) || [
    1, 2, 3, 4,
  ],
  isConnectAccountRequested: false,
  vendorInfoCardStatus: localStorage.getItem('vendorInfoCardStatus') || false,
  vendorInfoRealTimeStatus:
    localStorage.getItem('vendorInfoRealTimeStatus') || false,
  hiddenSeries: {
    salesProfit: [],
    salesTraffic: [],
    salesInventory: [],
    realTimeSales: [],
    shippedRevenue: [],
    orderRevenue: [],
    manufacturingSourcing: [],
  },
};
