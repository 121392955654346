/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '../../http/axios/index.js';

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  },
  SET_DESIRED_ROUTE(state, route) {
    state.desiredRoute = route;
  },
  SET_AUTHORIZED_PAGE_HISTORY(state, route) {
    if (route === '/no-permissions') return;
    state.lastAuthorizedPage = route;
  },
};
