import api from '@/api';

export default {
  setSimilarProductDownloadTask({ commit }, payload) {
    commit('SET_SIMILAR_PRODUCT_DOWNLOAD_TASK', payload);
  },
  setSimilarProductUploadTask({ commit }, payload) {
    commit('SET_SIMILAR_PRODUCT_UPLOAD_TASK', payload);
  },
  clearSimilarProductDownloadAndUpload({ commit }, payload) {
    commit('CLEAR_SIMILAR_PRODUCT_DOWNLOAD_AND_UPLOAD_TASK', payload);
  },

  checkDownloadTaskStatus({ commit, getters, rootGetters, dispatch }, payload) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    if (!evaStoreId || !payload.templateType || !payload.taskId) return;

    const urlDetail = 'repricer/template-task-status';
    const data = {
      templateType: payload.templateType,
      taskId: payload.taskId,
    };
    api.bearer
      .post(data, urlDetail)
      .then((response) => {
        const { ApiStatus, Data } = response.data;

        if (!ApiStatus)
          throw new Error('Something went wrong, please try again.');

        commit('SET_SIMILAR_PRODUCT_DOWNLOAD_TASK', {
          [evaStoreId]: { ...Data, templateType: payload.templateType },
        });

        const status = Data.status;
        /*
          0: --> Template received.
          1: --> In Progress.
          2: --> Done.
          3: --> Error.
          4: --> Task re-queued.
          */
        switch (status) {
          case 0:
          case 1:
          case 4:
            setTimeout(() => {
              dispatch('checkDownloadTaskStatus', payload);
            }, 5000);
            return;
          case 2:
            commit('SET_SIMILAR_PRODUCT_DOWNLOAD_TASK', {
              [evaStoreId]: {
                ...Data,
                templateType: payload.templateType,
              },
            });
            return;
          case 3:
          default:
            throw new Error(Data.errorMessage);
        }
      })
      .catch(({ errorMessage }) => {
        const errorData = {
          status: 3,
          errorMessage: errorMessage,
          templateType: payload.templateType,
        };
        commit('SET_SIMILAR_PRODUCT_DOWNLOAD_TASK', {
          [evaStoreId]: errorData,
        });
      });
  },

  checkUploadTaskStatus({ commit, getters, rootGetters, dispatch }, payload) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    if (!evaStoreId || !payload.templateType || !payload.taskId) return;

    const currentUploadData = getters.getUploadSimilarProductObject;
    const urlDetail = 'repricer/template-task-status';
    const data = {
      templateType: payload.templateType,
      taskId: payload.taskId,
    };
    api.bearer
      .post(data, urlDetail)
      .then((response) => {
        const { ApiStatus, Data } = response.data;

        if (!ApiStatus)
          throw new Error('Something went wrong, please try again.');

        commit('SET_SIMILAR_PRODUCT_UPLOAD_TASK', {
          [evaStoreId]: {
            ...currentUploadData,
            ...Data,
            deactiveUpload: true,
            processingPercent:
              currentUploadData?.processingPercent < 97
                ? (currentUploadData?.processingPercent || 0) +
                  Math.floor(Math.random() * 3) //0 or 1 or 2
                : currentUploadData.processingPercent,
          },
        });

        const status = Data.status;
        /*
          0: --> Template received.
          1: --> In Progress.
          2: --> Done.
          3: --> Error.
          4: --> Task re-queued.
          */
        switch (status) {
          case 0:
          case 1:
          case 4:
            setTimeout(() => {
              dispatch('checkUploadTaskStatus', payload);
            }, 5000);
            return;
          case 2:
            commit('SET_SIMILAR_PRODUCT_UPLOAD_TASK', {
              [evaStoreId]: {
                ...currentUploadData,
                ...Data,
                deactiveUpload: false,
                processingPercent: 100,
              },
            });
            return;
          case 3:
          default:
            throw new Error(Data.errorMessage);
        }
      })
      .catch(({ errorMessage }) => {
        const errorData = {
          status: 3,
          errorMessage: errorMessage,
          templateType: payload.templateType,
          deactiveUpload: false,
        };
        commit('SET_SIMILAR_PRODUCT_UPLOAD_TASK', {
          [evaStoreId]: errorData,
        });
      });
  },
};
