import state from './moduleFiltersState.js';
import mutations from './moduleFiltersMutations.js';
import actions from './moduleFiltersActions.js';
import getters from './moduleFiltersGetters.js';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
