export default {
  setSelectedMarket({ commit }, payload) {
    commit('SET_SELECTED_MARKET', payload);
  },
  clearSelectedMarket({ commit }) {
    commit('CLEAR_SELECTED_MARKET');
  },
  setSelectedMarketType({ commit }, payload) {
    commit('SET_SELECTED_MARKET_TYPE', payload);
  },
  clearSelectedMarketType({ commit }) {
    commit('CLEAR_SELECTED_MARKET_TYPE');
  },
  setWalmartInfo({ commit }, payload) {
    commit('SET_WALMART_INFO', payload);
  },
  clearWalmartInfo({ commit }) {
    commit('CLEAR_WALMART_INFO');
  },
  setConnectedStore({ commit }, payload) {
    commit('SET_CONNECTED_STORE', payload);
  },
  clearConnectedStore({ commit }) {
    commit('CLEAR_CONNECTED_STORE');
  },
  setUserInfo({ commit }, payload) {
    commit('SET_USER_INFO', payload);
  },
  clearUserInfo({ commit }) {
    commit('CLEAR_USER_INFO');
  },
  setRegionInfo({ commit }, payload) {
    commit('SET_REGION_INFO', payload);
  },
  clearRegionInfo({ commit }) {
    commit('CLEAR_REGION_INFO');
  },
  clearAllInformation({ commit }) {
    commit('CLEAR_CONNECTED_STORE');
    commit('CLEAR_WALMART_INFO');
    commit('CLEAR_SELECTED_MARKET');
    commit('CLEAR_SELECTED_MARKET_TYPE');
    commit('CLEAR_USER_INFO');
    commit('CLEAR_REGION_INFO');
    localStorage.removeItem('linkAccountSelectMarket');
    localStorage.removeItem('linkAccountSelectMarketType');
    localStorage.removeItem('linkAccountSelectRegion');
    localStorage.removeItem('linkAccountUserInfo');
    localStorage.removeItem('vendorCentralPathes');
    localStorage.removeItem('sellerCentralConsent');
    localStorage.removeItem('sellerCentralPathes');
    localStorage.removeItem('fromNoPermission');
    localStorage.removeItem('renew_token');
  },
};
