import state from './moduleRenewTokenState.js';
import actions from './moduleRenewTokenActions.js';
import mutations from './moduleRenewTokenMutations.js';
import getters from './moduleRenewTokenGetters.js';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
