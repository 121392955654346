const initialDownloadState = {
  taskId: null,
  templateType: 2,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};
const initialUploadState = {
  taskId: null,
  templateType: 2,
  status: 'IDLE',
  message: null,
  uploadSkuCount: null,
  errorSkuCount: null,
  successfulSkuCount: null,
  deleteSkuCount: null,
  url: null,
  uploadProgress: 0,
  processProgress: 0,
};
export default {
  download: ({ download }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return download[evaStoreId] ?? initialDownloadState;
  },
  upload: ({ upload }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return upload[evaStoreId] ?? initialUploadState;
  },
};
