import moment from 'moment';
export default {
  getSelectedDate: (state) => state.selectedDate,
  getLastDataAvailableDate: () => moment().format('YYYY-MM-DD'),
  getSelectedDateRequest: (state) => {
    const isArr = Array.isArray(state.selectedDate);
    let startDate = moment(state.selectedDate[0]).format('MM/DD/YYYY');
    let endDate = moment(state.selectedDate[1]).format('MM/DD/YYYY');
    return {
      day: isArr ? null : state.selectedDate,
      customDateData: {
        startDate: isArr ? startDate : null,
        endDate: isArr ? endDate : null,
      },
    };
  },
  getComponentOrder: (state) => state.componentOrder,
  isConnectAccountRequested: (state) => state.isConnectAccountRequested,
  activeStore: (state) => {
    return state.activeStore;
  },
  isHidden: (state) => (field, component) => {
    return state?.hiddenSeries[component].includes(field);
  },
  vendorInfoRealTimeStatus: (state) => {
    return state.vendorInfoRealTimeStatus;
  },
  vendorInfoCardStatus: (state) => {
    return state.vendorInfoCardStatus;
  },
};
