export default {
  reportHistory: {
    search: '',
    filters: {
      frequency: 'all',
      status: 'all',
      reportTypes: 'all',
    },
  },
  reportConfigurations: {
    search: '',
    filters: {
      reportTypes: 'all',
    },
  },
  selectedReportType: null,
  reportName: '',
  selectedStores: [],
  selectedFrequency: '',
  selectedDateRange: [],
  selectedInterval: '',
  selectedAverageOf: '',
  selectedNegativeProfitSwitch: false,
  selectedCreateOn: '',
  selectedCompareStartDay: '', // only best seller rank report
  selectedCompareEndDay: '', // only best seller rank report
  dateFormat: 'YYYY-MM-DD',
  datePickerType: 'date',
  dailyEndDate: '',
  weeklyEndDate: '',
  monthlyEndDate: '',
  emailList: [],

  editReportData: {},

  step1Completed: false,
  step2Completed: false,
  step3Completed: false,

  reportHistoryApiRequestStatus: false,
  reportConfigurationsApiRequestStatus: false,

  reportPeriodDateRange: true,
  reportPeriodDateType: 'date',
  reportPeriodDateFormat: 'YYYY-MM-DD',
};
