export default {
  getSkuPerformanceFilterList: (state) =>
    state.skuPerformanceFilters.filterList,
  getSkuPerformanceActiveFilterList: (state) => {
    // Recursive function to find 'checked' children in nested structure
    const findCheckedChildren = (children) => {
      let checkedChildren = [];
      children.forEach((child) => {
        if (child.selected === 'checked') {
          checkedChildren.push({ name: child.name, value: child.value });
        }
        if (child.children && child.children.length > 0) {
          checkedChildren = checkedChildren.concat(
            findCheckedChildren(child.children)
          );
        }
      });
      return checkedChildren;
    };

    return state.skuPerformanceFilters.filterList
      .map((filter) => {
        let selectedOption = null;

        if (filter.type === 'single') {
          // Find 'selected' (true) option for 'single' type
          const selectedChild = filter.children.find(
            (child) => child?.selected
          );
          selectedOption = selectedChild
            ? [{ name: selectedChild.name, value: selectedChild.value }]
            : null;
        } else if (filter.type === 'multiple' && filter.hasNested) {
          // Use recursive function to find 'checked' options for 'multiple' type, including nested ones
          const selectedChildren = findCheckedChildren(filter.children);
          if (selectedChildren.length > 0) {
            selectedOption = selectedChildren;
          }
        } else if (filter.type === 'multiple' && !filter.hasNested) {
          const selectedChildren = filter.children
            .filter((child) => child?.selected)
            ?.reverse();
          selectedOption =
            selectedChildren.length > 0
              ? selectedChildren.map((item) => {
                  return { name: item.name, value: item.value };
                })
              : null;
        }

        // Return the filter with 'selectedOption', including any modifications
        return {
          name: filter.name,
          value: filter.value,
          children: filter.children,
          selectedOption,
          type: filter.type,
          hasNested: filter.hasNested,
          hasFilterSearch: filter.filterSearch !== undefined,
        };
      })
      .filter((filter) => filter.selectedOption); // Filter out any filters without a 'selectedOption'
  },
  getSkuPerformanceFilterChildrenActive: (state) => {
    // Tüm aktif filtrelerin value değerlerini toplamak için bir dizi tanımlıyoruz.
    let activeFilters = [];

    // Rekürsif bir fonksiyon tanımlayarak, bu fonksiyon içerisinde iç içe yapıyı kontrol ederiz.
    const findActiveFilters = (filters) => {
      filters.forEach((filter) => {
        // Eğer bu seviyede aktif bir filtre bulunursa, diziye ekleyin.
        if (filter.childrenActive) {
          activeFilters.push(filter.value);
        }
        // Eğer alt seviyelerde filtreler varsa, bu fonksiyonu rekürsif olarak çağır.
        if (filter.children && filter.children.length > 0) {
          findActiveFilters(filter.children);
        }
      });
    };

    // Filtre listesini rekürsif fonksiyona göndererek, aktif filtre aramasını başlat.
    findActiveFilters(state.skuPerformanceFilters.filterList);

    // Aktif filtrelerin value değerlerini içeren diziyi döndür.
    return activeFilters;
  },
  getSkuPerformanceFilterSearchValue: (state) => {
    //filterList'de filterValue'ya sahip olan filter'in searchValue'sunu doner
    return (filterValue) => {
      const filter = state.skuPerformanceFilters.filterList.find(
        (filter) => filter.value === filterValue
      );
      return filter ? filter.filterSearch : '';
    };
  },
  getOpportunityFilterList: (state) => state.opportunityFilters.filterList,
  getOpportunityActiveFilterList: (state) => {
    // Recursive function to find 'checked' children in nested structure
    const findCheckedChildren = (children) => {
      let checkedChildren = [];
      children.forEach((child) => {
        if (child.selected === 'checked') {
          checkedChildren.push({ name: child.name, value: child.value });
        }
        if (child.children && child.children.length > 0) {
          checkedChildren = checkedChildren.concat(
            findCheckedChildren(child.children)
          );
        }
      });
      return checkedChildren;
    };

    return state.opportunityFilters.filterList
      .map((filter) => {
        let selectedOption = null;

        if (filter.type === 'single') {
          // Find 'selected' (true) option for 'single' type
          const selectedChild = filter.children.find(
            (child) => child?.selected
          );
          selectedOption = selectedChild
            ? [{ name: selectedChild.name, value: selectedChild.value }]
            : null;
        } else if (filter.type === 'multiple' && filter.hasNested) {
          // Use recursive function to find 'checked' options for 'multiple' type, including nested ones
          const selectedChildren = findCheckedChildren(filter.children);
          if (selectedChildren.length > 0) {
            selectedOption = selectedChildren;
          }
        } else if (filter.type === 'multiple' && !filter.hasNested) {
          const selectedChildren = filter.children
            .filter((child) => child?.selected)
            ?.reverse();

          const isIncludesAllOption = selectedChildren.some(
            (item) => item.value === 'all'
          );

          if (isIncludesAllOption) {
            selectedOption = selectedChildren.filter(
              (item) => item.value === 'all'
            );
          } else {
            selectedOption =
              selectedChildren.length > 0
                ? selectedChildren.map((item) => {
                    return { name: item.name, value: item.value };
                  })
                : null;
          }
        }

        // Return the filter with 'selectedOption', including any modifications
        return {
          name: filter.name,
          value: filter.value,
          children: filter.children,
          selectedOption,
          type: filter.type,
          hasNested: filter.hasNested,
          hasFilterSearch: filter.filterSearch !== undefined,
        };
      })
      .filter((filter) => filter.selectedOption); // Filter out any filters without a 'selectedOption'
  },
  getOpportunityFilterChildrenActive: (state) => {
    // Tüm aktif filtrelerin value değerlerini toplamak için bir dizi tanımlıyoruz.
    let activeFilters = [];

    // Rekürsif bir fonksiyon tanımlayarak, bu fonksiyon içerisinde iç içe yapıyı kontrol ederiz.
    const findActiveFilters = (filters) => {
      filters.forEach((filter) => {
        // Eğer bu seviyede aktif bir filtre bulunursa, diziye ekleyin.
        if (filter.childrenActive) {
          activeFilters.push(filter.value);
        }
        // Eğer alt seviyelerde filtreler varsa, bu fonksiyonu rekürsif olarak çağır.
        if (filter.children && filter.children.length > 0) {
          findActiveFilters(filter.children);
        }
      });
    };

    // Filtre listesini rekürsif fonksiyona göndererek, aktif filtre aramasını başlat.
    findActiveFilters(state.opportunityFilters.filterList);

    // Aktif filtrelerin value değerlerini içeren diziyi döndür.
    return activeFilters;
  },
  getOpportunityFilterSearchValue: (state) => {
    //filterList'de filterValue'ya sahip olan filter'in searchValue'sunu doner
    return (filterValue) => {
      const filter = state.opportunityFilters.filterList.find(
        (filter) => filter.value === filterValue
      );
      return filter ? filter.filterSearch : '';
    };
  },
  getOpportunitySearchKeyword: (state) => {
    return state.opportunityFilters.searchKeyword;
  },
};
