import state from './moduleExecutiveDashboardState';
import mutations from './moduleExecutiveDashboardMutations';
import actions from './moduleExecutiveDashboardActions';
import getters from './moduleExecutiveDashboardGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
