export default {
  download: {
    taskId: null,
    templateType: null,
    status: 'IDLE',
    message: null,
    url: null,
  },
  upload: {
    taskId: null,
    templateType: null,
    status: 'IDLE',
    message: null,
    uploadSkuCount: null,
    errorSkuCount: null,
    successfulSkuCount: null,
    url: null,
  },
};
