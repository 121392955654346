import moment from 'moment';

const initialDownloadState = {
  taskId: null,
  reportType: 8,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};

const initialComparisonDownloadState = {
  taskId: null,
  reportType: 10,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};

//product cost
const initialProductCostDownloadState = {
  taskId: null,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};
const initialProductCostUploadState = {
  taskId: null,
  status: 'IDLE',
  message: null,
  uploadSkuCount: null,
  errorSkuCount: null,
  successfulSkuCount: null,
  url: null,
  uploadProgress: 0,
  processProgress: 0,
};

export default {
  getSelectedDate: (state) => state.selectedDate,
  getLastDataAvailableDate: (state) => state.lastDataAvailableDate,
  getSelectedDateRequest: (state) => {
    const isArr = Array.isArray(state.selectedDate);
    let startDate = moment(state.selectedDate[0]).format('MM/DD/YYYY');
    let endDate = moment(state.selectedDate[1]).format('MM/DD/YYYY');
    return {
      requestStatus: isArr ? 1 : 0,
      day: isArr ? null : state.selectedDate,
      customDateData: {
        startDate: isArr ? startDate : null,
        endDate: isArr ? endDate : null,
      },
    };
  },
  getListSelectedDateRequest: (state) => {
    let startDate = moment(state.selectedDate[0]).format('MM/DD/YYYY');
    let endDate = moment(state.selectedDate[1]).format('MM/DD/YYYY');
    return {
      customDateRange: {
        customStartDate: startDate,
        customEndDate: endDate,
      },
    };
  },
  getPerformanceComponentOrder: (state) => state.performanceComponentOrder,
  getPerformanceComponentVisibility: (state) => (component) => {
    return state.performanceComponentVisibility[component] ?? false;
  },
  getPricingActionsComponentOrder: (state) =>
    state.pricingActionsComponentOrder,
  getPricingActionsComponentVisibility: (state) => (component) => {
    return state.pricingActionsComponentVisibility[component] ?? false;
  },
  isHidden: (state) => (field, component) => {
    return state.hiddenSeries[component].includes(field);
  },
  getColumnOptions: (state) => state.columnOptions,
  getSkuPerformanceListDate: (state) => state.skuPerformanceListDate,
  getSkuImage: (state) => (sku) => {
    return state.skuImages.find((image) => image.sku === sku)?.imageUrl;
  },
  getSalesDetails: (state) => state.salesDetails,

  //export
  download: ({ download }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return download[evaStoreId] ?? initialDownloadState;
  },
  //---------

  //export comparison
  downloadComparison: ({ downloadComparison }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return downloadComparison[evaStoreId] ?? initialComparisonDownloadState;
  },
  //---------

  // product cost export and upload
  downloadProductCost: ({ productCostDownload }, __, ___, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return productCostDownload[evaStoreId] ?? initialProductCostDownloadState;
  },

  uploadProductCost: ({ productCostUpload }, __, ___, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return productCostUpload[evaStoreId] ?? initialProductCostUploadState;
  },
};
