export default {
  download: {
    shopify: JSON.parse(localStorage.getItem('download-shopify')) ?? {},
    walmart: JSON.parse(localStorage.getItem('download-walmart')) ?? {},
    shipstation: JSON.parse(localStorage.getItem('download-shipstation')) ?? {},
  },
  upload: {
    shopify: JSON.parse(localStorage.getItem('upload-shopify')) ?? {},
    walmart: JSON.parse(localStorage.getItem('upload-walmart')) ?? {},
    shipstation: JSON.parse(localStorage.getItem('upload-shipstation')) ?? {},
  },
  marketplaces: {
    shopify: {
      stores: [],
      lastFetchedAt: null,
      updatingStore: null,
    },
    walmart: {
      stores: [],
      lastFetchedAt: null,
      updatingStore: null,
    },
    shipstation: {
      stores: [],
      lastFetchedAt: null,
      updatingStore: null,
    },
  },
};
