import moment from 'moment';
export default {
  getSelectedDate: (state) => state.selectedDate,
  getLastDataAvailableDate: () => moment().format('YYYY-MM-DD'),
  getSelectedDateRequest: (state) => {
    const isArr = Array.isArray(state.selectedDate);
    let startDate = moment(state.selectedDate[0]).format('MM/DD/YYYY');
    let endDate = moment(state.selectedDate[1]).format('MM/DD/YYYY');
    return {
      day: isArr ? null : state.selectedDate,
      customDateData: {
        startDate: isArr ? startDate : null,
        endDate: isArr ? endDate : null,
      },
    };
  },
  getComponentOrder: (state) => state.componentOrder,
  isConnectAccountRequested: (state) => state.isConnectAccountRequested,
  activeStore: (state) => {
    return state.activeStore;
  },
  isHidden: (state) => (field, component) => {
    return state?.hiddenSeries[component].includes(field);
  },
  walmartInfoRealTimeStatus: (state) => {
    return state.walmartInfoRealTimeStatus;
  },
  walmartInfoCardStatus: (state) => {
    return state.walmartInfoCardStatus;
  },
  getAdvertisingCampaign: (state) => {
    return state.advertisingCampaign;
  },
  getAdvertisingAdGroup: (state) => {
    return state.advertisingAdGroup;
  },
  getAdvertisingAdItemList: (state) => {
    return state.advertisingAdItemList;
  },
  getAdvertisingKeywords: (state) => {
    return state.advertisingKeywords;
  },
  activeTab: (state) => {
    return state.activeTab;
  },
  selectedCampaign: (state) => {
    return state.selectedCampaign;
  },
  selectedAdGroup: (state) => {
    return state.selectedAdGroup;
  },
  getAdvertisingSBProfiles: (state) => {
    return state.advertisingSBProfiles;
  },
  getAdvertisingKeywordList: (state) => {
    return state.advertisingKeywordList;
  },
  getAnalyticsChartData(state) {
    return state.analyticsChartData;
  },
};
