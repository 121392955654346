/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js';
import axios from '../../axios';

import router from '@/router';
import screenPermissionList from '@/json/screenPermissionList';
import { getCookie } from '@/helper/index.js';
import { adminDashboardOnlyAccountPermissions } from '@/constants/pages.ts';

export default {
  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
    };
    dispatch('login', newPayload);
  },
  async login({ state }, payload) {
    // If user is already logged in notify and exit
    if (await state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation();

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      });

      return false;
    }
  },

  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        const newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit('UPDATE_USER_INFO', newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || '/');
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      });
  },

  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then(async (response) => {
          let responseData = response.data.Data;

          let apiStatus = response.data.ApiStatus;
          let apiStatusCode = response.data.ApiStatusCode;
          let ApiStatusMessage = response.data.ApiStatusMessage;

          if (apiStatus === false) {
            if (apiStatusCode == 404) {
              reject({
                message: 'Email is not registered. Start Eva Free now.',
              });
            } else if (apiStatusCode == 401) {
              reject({
                message: 'Your password is invalid. Please try again.',
              });
            } else if (apiStatusCode == 403) {
              reject({
                message:
                  'Email is not verified. Please check your mailbox to verify.',
              });
            } else {
              reject(ApiStatusMessage);
            }
          } else {
            if (responseData.AccessToken) {
              commit('SET_BEARER', responseData.AccessToken);
              const baseURL = process.env.VUE_APP_SERVICE_URL;
              try {
                const userInfoResponse = await axios.post(
                  `${baseURL}user/user-information`,
                  {
                    email: payload.userDetails.email,
                  }
                );
                const {
                  ApiStatus,
                  ApiStatusMessage,
                  Data: userInfoResponseData,
                } = userInfoResponse.data;
                if (!ApiStatus) {
                  reject(ApiStatusMessage);
                }
                if (userInfoResponseData.user) {
                  localStorage.setItem(
                    'email',
                    userInfoResponseData.user.email
                  );
                  let selectedStoreElement = null;
                  var storeList = userInfoResponseData.user.store.map(
                    (item) => ({
                      ...item,
                      isVendor: false,
                      isWalmart: false,
                      isSellerStore: true,
                    })
                  );
                  const vendorList = userInfoResponseData.user.vendor.map(
                    (item) => ({
                      ...item,
                      isVendor: true,
                      isWalmart: false,
                      marketplaceName: item.marketplace,
                      storeName:
                        item.storeName +
                        ' - ' +
                        item.marketplaceCode +
                        ' - ' +
                        'Vendor',
                      screenPermissionList: null,
                    })
                  );
                  const walmartList =
                    userInfoResponseData.user.walmartStore.map((item) => ({
                      ...item,
                      isVendor: false,
                      isWalmart: true,
                      marketplaceName: item.marketplace,
                      storeName:
                        item.storeName +
                        ' - ' +
                        item.marketplaceCode +
                        ' - ' +
                        'Walmart',
                      screenPermissionList: null,
                    }));

                  storeList = storeList.concat(vendorList);
                  storeList = storeList.concat(walmartList);
                  userInfoResponseData.user.store = storeList;

                  // Select default store from cookie
                  if (userInfoResponseData.user.store.length > 0) {
                    userInfoResponseData.user.store.sort(function (a, b) {
                      if (a.storeName < b.storeName) {
                        return -1;
                      }
                      if (a.storeName > b.storeName) {
                        return 1;
                      }
                      return 0;
                    });
                    const lastSelectedStoreSellerId =
                      getCookie('lastStoreSellerId');
                    const lastSelectedStoreMarketplaceCode = getCookie(
                      'lastStoreMarketplaceCode'
                    );
                    const lastAccountEmail = getCookie('lastAccountEmail');
                    if (
                      lastSelectedStoreSellerId &&
                      lastSelectedStoreMarketplaceCode &&
                      lastAccountEmail &&
                      lastAccountEmail == userInfoResponseData.user.email
                    ) {
                      selectedStoreElement =
                        userInfoResponseData.user.store.find(
                          (element) =>
                            element.storeId == lastSelectedStoreSellerId &&
                            element.marketplaceCode ==
                              lastSelectedStoreMarketplaceCode
                        );
                      !selectedStoreElement &&
                        (selectedStoreElement =
                          userInfoResponseData.user.store[0]);
                    } else {
                      selectedStoreElement = userInfoResponseData.user.store[0];
                    }

                    let defaultStore = selectedStoreElement.storeId;
                    let defaultStoreName = selectedStoreElement.storeName;
                    let defaultMarketplace =
                      selectedStoreElement.marketplaceName;
                    let defaultRegion = selectedStoreElement.region;
                    let defaultMarketplaceCode =
                      selectedStoreElement.marketplaceCode;

                    localStorage.setItem('marketplace', defaultMarketplace);
                    localStorage.setItem('region', defaultRegion);
                    localStorage.setItem(
                      'marketplaceCode',
                      defaultMarketplaceCode
                    );
                    localStorage.setItem('store', defaultStore);
                    localStorage.setItem('storeName', defaultStoreName);
                    selectedStoreElement.selectedStoreIndex = parseInt(0);
                  }

                  let firstScreenPathArrays = [];
                  for (let i in screenPermissionList) {
                    if (userInfoResponseData.user.store.length > 0) {
                      for (let j in selectedStoreElement.screenPermissionList) {
                        if (
                          selectedStoreElement.screenPermissionList[
                            j
                          ].PermissionCode.includes(
                            screenPermissionList[i].permissionName
                          )
                        ) {
                          firstScreenPathArrays.push(
                            screenPermissionList[i].path
                          );
                        }
                      }
                    }
                  }
                  let firstScreenPath =
                    firstScreenPathArrays.length > 0
                      ? firstScreenPathArrays[0]
                      : '/';
                  const userPermissionList =
                    userInfoResponseData.user.userPermissionList;
                  let hasPermission = userPermissionList.find((item) =>
                    adminDashboardOnlyAccountPermissions.includes(
                      item.PermissionCode
                    )
                  );
                  let vendorOnlyStore =
                    userInfoResponseData.user.store.length === 0 &&
                    userInfoResponseData.user.vendor.length > 0;

                  if (vendorOnlyStore) {
                    firstScreenPath = '/vendor-management';
                  }
                  if (hasPermission) {
                    firstScreenPath = '/admin-dashboard';
                  }
                  router.push(router.currentRoute.query.to || firstScreenPath);
                  await commit('UPDATE_USER_INFO', userInfoResponseData, {
                    root: true,
                  });
                  commit('SET_SELECTED_STORE', selectedStoreElement, {
                    root: true,
                  });

                  localStorage.setItem('accessToken', responseData.AccessToken);
                  localStorage.setItem('token', responseData.AccessToken);
                  resolve(response);
                } else {
                  reject({ message: 'Wrong Email or Password' });
                }
              } catch (error) {
                reject({ message: 'Something went wrong, please try again' });
              }
            }
          }
        })
        .catch(() => {
          reject({ message: 'Something went wrong, please try again' });
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    const { displayName, email, password, confirmPassword } =
      payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      jwt
        .registerUser(displayName, email, password)
        .then((response) => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/');

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken);
          commit('UPDATE_USER_INFO', response.data.userData, { root: true });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        resolve(response);
      });
    });
  },
  setDesiredRoute({ commit }, payload) {
    commit('SET_DESIRED_ROUTE', payload);
  },
  setAuthorizedPageHistory({ commit }, payload) {
    commit('SET_AUTHORIZED_PAGE_HISTORY', payload);
  },
};
