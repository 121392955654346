import state from './modulePdfTemplateState.js';
import mutations from './modulePdfTemplateMutations.js';
import actions from './modulePdfTemplateActions.js';
import getters from './modulePdfTemplateGetters.js';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
