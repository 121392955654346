import state from './moduleLinkAccountState.js';
import mutations from './moduleLinkAccountMutations.js';
import actions from './moduleLinkAccountActions.js';
import getters from './moduleLinkAccountGetters.js';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
