/*=========================================================================================
  File Name: moduleAdminPanel.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './moduleAdminPanelState.js';
import mutations from './moduleAdminPanelMutations.js';
import actions from './moduleAdminPanelActions.js';
import getters from './moduleAdminPanelGetters.js';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
