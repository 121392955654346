var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"convstooltip",staticClass:"con-vs-tooltip",on:{"mouseleave":_vm.mouseleavex,"mouseenter":_vm.mouseenterx,"mouseup":_vm.destroy}},[_c('transition',{attrs:{"name":"tooltip-fade"}},[(_vm.$slots.customContent)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],ref:"vstooltip",staticClass:"vs-tooltip",class:[
          `vs-tooltip-${_vm.positionx || _vm.position}`,
          { 'after-none': _vm.noneAfter },
          'dark:bg-[#aeb6c0] bg-[#171a1d] dark:text-[#202020] text-[#e0e0e0]',
          _vm.customClass,
        ],style:(_vm.style)},[_vm._t("customContent")],2)]):_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],ref:"vstooltip",staticClass:"vs-tooltip",class:[
          `vs-tooltip-${_vm.positionx || _vm.position}`,
          { 'after-none': _vm.noneAfter },
          'dark:bg-[#aeb6c0] bg-[#171a1d] dark:text-[#202020] text-[#e0e0e0]',
        ],style:(_vm.style)},[(_vm.title)?_c('h4',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),_c('br'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.text2),expression:"text2"}]},[_vm._v(_vm._s(_vm.text2))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.text3),expression:"text3"}]},[_vm._v(_vm._s(_vm.text3))])])])]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }