import storeX from '../store/store';
import api from '@/api';

// TemplateType => 0: Request Create Pdf, 1: Pdf Created, 2: Upload Complete, 3: Error

export const getPdfExportStatus = async (templateType, templateId) => {
  const data = {
    templateId,
    templateType,
  };
  const urlDetail = 'accounting/export-file-task-status';
  const pdfType = templateType === 0 ? 'Invoice' : 'Receipt';
  let storeInstance = await storeX();
  let pdfObject;
  try {
    let response = await api.bearer.post(data, urlDetail);
    const { ApiStatus, ApiStatusMessage, Data } = response.data;
    if (!ApiStatus) throw new Error(ApiStatusMessage);
    pdfObject = {
      templateType: templateType,
      templateId: templateId,
      link: Data.downloadFileName,
      message: Data.downloadFileName
        ? `${pdfType} PDF is ready.`
        : `${pdfType} PDF is preparing to download.`,
      status: Data.status,
      requestTime: Data.requestTime,
      responseTime: Data.responseTime,
    };
    storeInstance.dispatch(`pdfTemplate/update${pdfType}Pdf`, pdfObject);
  } catch (error) {
    pdfObject = {
      templateType: templateType,
      templateId: templateId,
      link: null,
      message: error.ApiStatusMessage,
      status: 3,
      requestTime: null,
      responseTime: null,
    };
    storeInstance.dispatch(`pdfTemplate/update${pdfType}Pdf`, pdfObject);
  }
  return pdfObject;
};

const changetimezone = (operationTime) => {
  var options = {
    hourCycle: 'h23',
    timeZone: 'America/Los_Angeles',
  };
  var gmtTime = new Date(operationTime);
  var pstTime = gmtTime.toLocaleString('en-US', options);
  return pstTime;
};

export const getTrialTime = async () => {
  let storeInstance = await storeX();
  const selectedStore = storeInstance.getters.selectedStore;
  if (selectedStore.isVendor) return;
  if (selectedStore.isWalmart) return;

  const data = {};
  const urlDetail = 'user/get-trial-time/';
  try {
    let response = await api.bearer.post(data, urlDetail);
    if (!response) throw new Error('No response');
    const { ApiStatus, ApiStatusMessage, Data } = response.data;
    if (!ApiStatus) throw new Error(ApiStatusMessage);
    let newJson = Data;
    var trialObj = Data;

    let now = new Date();
    let isoDate = new Date(now).toISOString();
    isoDate = changetimezone(isoDate);
    const date1 = new Date(isoDate);
    let systemDate = newJson.trialEndTime;
    systemDate = changetimezone(systemDate);
    const date2 = new Date(systemDate);
    const diffTime = Math.abs(date2 - date1);
    let trialMinutes;
    if (date1 > date2) {
      trialMinutes = 0;
    } else {
      const diffMinutes = Math.ceil(diffTime * 0.001 * 0.0166666667);
      trialMinutes = diffMinutes;
    }

    let orjHours = parseFloat(newJson.trialHour);

    if (newJson.trialDay === null || parseFloat(newJson.trialDay) <= 0) {
      trialObj.trialDay = 0;
    } else {
      let day = parseFloat(newJson.trialHour) / 24;
      let dayMod = parseFloat(newJson.trialHour);
      dayMod %= 24;
      let hour = parseFloat(newJson.trialHour) % 24;
      if (newJson.trialHour > 120 && dayMod !== 0) {
        trialObj.trialDay = parseInt(day) + 1;
      } else if (newJson.trialHour > 120 && hour === 0) {
        trialObj.trialDay = parseInt(day) - 1;
      } else {
        trialObj.trialDay = parseInt(day);
      }
    }

    if (orjHours === null || orjHours < 0) {
      trialObj.trialHour = 0;
    } else {
      let hour = parseFloat(newJson.trialHour) % 24;
      if (hour === 0 && newJson.trialHour !== 0) {
        trialObj.trialHour = 24;
      } else if (hour < 0) {
        trialObj.trialHour = 0;
      } else {
        trialObj.trialHour = parseInt(hour);
      }
    }

    trialObj.trialMinutes = trialMinutes;
    trialObj.startReimbursement = false;
    trialObj.confirmOrderForm = false;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const store = selectedStore.storeId || urlParams.get('store');
    const marketplace =
      selectedStore.marketplace || urlParams.get('marketplace');
    const newReimbursementPackageType =
      storeInstance.getters['packagesAndOrderForm/getReimbursementPackage']
        ?.packageType || null;
    let storePricingStatus = selectedStore.subscriptionPackageType ?? 0;
    trialObj.pricingStatus = storePricingStatus;
    localStorage.setItem('trial', JSON.stringify(trialObj));
    storeInstance.dispatch('updateTrialInfo', trialObj);

    let reimbursementPackageTrialEndDate;
    storePricingStatus = selectedStore.subscriptionPackageType ?? 0;
    reimbursementPackageTrialEndDate =
      selectedStore.reimbursementPackageTrialEndDate;
    if (
      reimbursementPackageTrialEndDate &&
      (storePricingStatus === 7 ||
        storePricingStatus === newReimbursementPackageType)
    ) {
      trialObj.trialEndTime = reimbursementPackageTrialEndDate;
      let now1 = new Date();
      let isoDate1 = new Date(now1).toISOString();
      isoDate1 = changetimezone(isoDate1);
      const date3 = new Date(isoDate1);
      let systemDate1 = reimbursementPackageTrialEndDate;
      systemDate1 = changetimezone(systemDate1);
      const date4 = new Date(systemDate1);
      const diffTime1 = Math.abs(date3 - date4);
      let trialMinutes1;
      if (date3 > date4) {
        trialMinutes1 = 0;
      } else {
        const diffMinutes1 = Math.ceil(diffTime1 * 0.001 * 0.0166666667);
        trialMinutes1 = diffMinutes1;
      }

      trialObj.trialMinutes = trialMinutes1;

      let reimbursementTrialHour = parseFloat(trialMinutes1 / 60);
      let reimbursementTrialDay = parseFloat(trialMinutes1 / 1440);

      if (
        reimbursementTrialDay === null ||
        parseFloat(reimbursementTrialDay) <= 0
      ) {
        trialObj.trialDay = 0;
      } else {
        let day = parseFloat(reimbursementTrialHour) / 24;
        let dayMod = parseFloat(reimbursementTrialHour);
        dayMod %= 24;
        let hour = parseFloat(reimbursementTrialHour) % 24;
        if (reimbursementTrialHour > 120 && dayMod !== 0) {
          trialObj.trialDay = parseInt(day) + 1;
        } else if (reimbursementTrialHour < 120 && hour === 0) {
          trialObj.trialDay = parseInt(day) - 1;
        } else {
          trialObj.trialDay = parseInt(day);
        }
      }

      if (reimbursementTrialHour === null || reimbursementTrialHour < 0) {
        trialObj.trialHour = 0;
      } else {
        let hour = parseFloat(reimbursementTrialHour) % 24;
        if (hour === 0 && reimbursementTrialHour !== 0) {
          trialObj.trialHour = 24;
        } else if (hour < 0) {
          trialObj.trialHour = 0;
        } else {
          trialObj.trialHour = parseInt(hour);
        }
      }

      localStorage.setItem('trial', JSON.stringify(trialObj));
      storeInstance.dispatch('updateTrialInfo', trialObj);
    }
    return trialObj;
  } catch (error) {
    console.error(error);
    return null;
  } finally {
  }
};
