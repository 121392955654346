export default {
  setShipstationInfo({ commit }, payload) {
    commit('SET_SHIPSTATION_INFO', payload);
  },
  clearShipstationInfo({ commit }) {
    commit('CLEAR_SHIPSTATION_INFO');
  },
  setPairingWarehouses({ commit }, payload) {
    commit('SET_PAIRING_WAREHOUSES', payload);
  },
  clearPairingWarehouses({ commit }) {
    commit('CLEAR_PAIRING_WAREHOUSES');
  },
  setPairingStores({ commit }, payload) {
    commit('SET_PAIRING_STORES', payload);
  },
  clearPairingStores({ commit }) {
    commit('CLEAR_PAIRING_STORES');
  },
  clearAllInformation({ commit }) {
    commit('CLEAR_SHIPSTATION_INFO');
    commit('CLEAR_PAIRING_WAREHOUSES');
    commit('CLEAR_PAIRING_STORES');
  },
};
