// Recursive function to set selection status for children
const setChildrenSelectedStatus = (children, isSelected) => {
  children.forEach((child) => {
    child.selected = isSelected;
    if (child.children && child.children.length) {
      setChildrenSelectedStatus(child.children, isSelected);
    }
  });
};

// Determines the selection status of the parent based on its children
const updateParentSelection = (children) => {
  if (children.every((child) => child.selected === 'checked')) {
    return 'checked';
  } else if (
    children.some(
      (child) =>
        child.selected === 'checked' || child.selected === 'partial-checked'
    )
  ) {
    return 'partial-checked';
  } else {
    return 'unchecked';
  }
};

// Finds and updates the option by value, then updates parent selection accordingly
const findAndUpdateOptionByValue = (options, value, updateFn) => {
  options.forEach((option) => {
    if (option.value === value) {
      updateFn(option);
    } else if (option.children && option.children.length) {
      findAndUpdateOptionByValue(option.children, value, updateFn);
      // Update parent's selected status based on children
      option.selected = updateParentSelection(option.children);
    }
  });
};

// Updates the selected status of an option and its children
const updateSelectedStatus = (option) => {
  // If the option is partial-checked, set it and all children to unchecked
  if (option.selected === 'partial-checked') {
    option.selected = 'unchecked';
    if (option.children && option.children.length) {
      setChildrenSelectedStatus(option.children, 'unchecked');
    }
  } else {
    // Toggle between checked and unchecked for non-partial states
    option.selected = option.selected === 'checked' ? 'unchecked' : 'checked';
    if (option.children && option.children.length) {
      setChildrenSelectedStatus(option.children, option.selected);
    }
  }
};

const updateChildrenOptions = (filterGroup, selectedOption) => {
  const foundOption = filterGroup.children.find(
    (item) => item.value === selectedOption
  );
  if (foundOption) {
    foundOption.selected = !foundOption.selected;
  }
};

const applyNestedUpdate = (filterGroup, selectedOption, updateFunction) => {
  if (filterGroup.hasNested) {
    findAndUpdateOptionByValue([filterGroup], selectedOption, updateFunction);
  } else {
    updateChildrenOptions(filterGroup, selectedOption);
  }
};

export default {
  SET_SKU_PERFORMANCE_FILTER: (
    state,
    { filterValue, selectedOption, type }
  ) => {
    state.skuPerformanceFilters.filterList =
      state.skuPerformanceFilters.filterList.map((filter) => {
        if (filter.value === filterValue) {
          if (type === 'single') {
            filter.children = filter.children.map((option) => {
              option.selected = option.value === selectedOption;
              return option;
            });
          }
        }
        return filter;
      });
  },
  SET_SKU_PERFORMANCE_MULTIPLE_FILTER: (
    state,
    { filterValue, selectedOption }
  ) => {
    const targetFilterGroup = state.skuPerformanceFilters.filterList.find(
      (filter) => filter.value === filterValue
    );

    if (!targetFilterGroup) return;

    if (targetFilterGroup.children && targetFilterGroup.children.length) {
      applyNestedUpdate(
        targetFilterGroup,
        selectedOption,
        updateSelectedStatus
      );
    } else {
      updateSelectedStatus(targetFilterGroup);
    }
  },
  SET_SKU_PERFORMANCE_FILTER_CHILDREN_ACTIVE: (state, { filterValue }) => {
    const setActiveStates = (filters, targetValue) => {
      let found = false;
      filters.forEach((filter) => {
        if (filter.value === targetValue) {
          filter.childrenActive = !filter.childrenActive;
          found = true;
        } else {
          filter.childrenActive = false;
        }
        if (filter.children && filter.children.length > 0) {
          const childFound = setActiveStates(filter.children, targetValue);
          if (childFound) {
            filter.childrenActive = true; // Ebeveyni aktif yap
            found = true;
          }
        }
      });
      return found;
    };

    // İlk seviyedeki filterList üzerinden işlemi başlat
    setActiveStates(state.skuPerformanceFilters.filterList, filterValue);
  },
  RESET_SKU_PERFORMANCE_FILTER_CHILDREN_ACTIVE: (state) => {
    //filterList'deki tum filter'larin childrenActive degerini false yapar
    state.skuPerformanceFilters.filterList =
      state.skuPerformanceFilters.filterList.map((filter) => {
        filter.childrenActive = false;
        return filter;
      });
  },
  CLEAR_SKU_PERFORMANCE_ACTIVE_FILTERS: (state, { filterValue }) => {
    // Define a recursive function to update all children's selected status to 'unchecked'
    const clearChildrenSelectedStatus = (children) => {
      return children.map((child) => {
        if (child.children && child.children.length > 0) {
          // Recursively clear children if they exist
          child.children = clearChildrenSelectedStatus(child.children);
        }
        child.selected = 'unchecked'; // Set the selected status to 'unchecked'
        return child;
      });
    };
    //filterList'de filterValue'ya sahip olan filter'in children'larindaki selected degerlerini false yapar
    state.skuPerformanceFilters.filterList =
      state.skuPerformanceFilters.filterList.map((filter) => {
        if (filter.value === filterValue) {
          if (filter.type === 'multiple' && filter.hasNested) {
            filter.children = clearChildrenSelectedStatus(filter.children);
          } else {
            filter.children = filter.children.map((option) => {
              option.selected = false;
              return option;
            });
          }
        }
        return filter;
      });
  },
  SET_SKU_PERFORMANCE_FILTER_ADD_CHILDREN: (
    state,
    { filterValue, children }
  ) => {
    //filterList'de filterValue'ya sahip olan filter'in children'larina esitler
    state.skuPerformanceFilters.filterList =
      state.skuPerformanceFilters.filterList.map((filter) => {
        if (filter.value === filterValue) {
          filter.children = children;
        }
        return filter;
      });
  },
  SET_SKU_PERFORMANCE_FILTER_SEARCH_VALUE: (
    state,
    { filterValue, filterSearch }
  ) => {
    //filterList'de filterValue'ya sahip olan filter'in filterSearch degerini searchValue yapar
    state.skuPerformanceFilters.filterList =
      state.skuPerformanceFilters.filterList.map((filter) => {
        if (filter.value === filterValue) {
          filter.filterSearch = filterSearch;
        }
        return filter;
      });
  },

  SET_OPPORTUNITY_FILTER: (state, { filterValue, selectedOption, type }) => {
    state.opportunityFilters.filterList =
      state.opportunityFilters.filterList.map((filter) => {
        if (filter.value === filterValue) {
          if (type === 'single') {
            filter.children = filter.children.map((option) => {
              option.selected = option.value === selectedOption;
              return option;
            });
          }
        }
        return filter;
      });
  },
  SET_OPPORTUNITY_MULTIPLE_FILTER: (state, { filterValue, selectedOption }) => {
    const targetFilterGroup = state.opportunityFilters.filterList.find(
      (filter) => filter.value === filterValue
    );

    if (!targetFilterGroup) return;

    if (targetFilterGroup.children && targetFilterGroup.children.length) {
      applyNestedUpdate(
        targetFilterGroup,
        selectedOption,
        updateSelectedStatus
      );
    } else {
      updateSelectedStatus(targetFilterGroup);
    }
  },
  SET_OPPORTUNITY_FILTER_CHILDREN_ACTIVE: (state, { filterValue }) => {
    const setActiveStates = (filters, targetValue) => {
      let found = false;
      filters.forEach((filter) => {
        if (filter.value === targetValue) {
          filter.childrenActive = !filter.childrenActive;
          found = true;
        } else {
          filter.childrenActive = false;
        }
        if (filter.children && filter.children.length > 0) {
          const childFound = setActiveStates(filter.children, targetValue);
          if (childFound) {
            filter.childrenActive = true; // Ebeveyni aktif yap
            found = true;
          }
        }
      });
      return found;
    };

    // İlk seviyedeki filterList üzerinden işlemi başlat
    setActiveStates(state.opportunityFilters.filterList, filterValue);
  },
  RESET_OPPORTUNITY_FILTER_CHILDREN_ACTIVE: (state) => {
    //filterList'deki tum filter'larin childrenActive degerini false yapar
    state.opportunityFilters.filterList =
      state.opportunityFilters.filterList.map((filter) => {
        filter.childrenActive = false;
        return filter;
      });
  },
  CLEAR_OPPORTUNITY_ACTIVE_FILTERS: (state, { filterValue }) => {
    // Define a recursive function to update all children's selected status to 'unchecked'
    const clearChildrenSelectedStatus = (children) => {
      return children.map((child) => {
        if (child.children && child.children.length > 0) {
          // Recursively clear children if they exist
          child.children = clearChildrenSelectedStatus(child.children);
        }
        child.selected = 'unchecked'; // Set the selected status to 'unchecked'
        return child;
      });
    };
    //filterList'de filterValue'ya sahip olan filter'in children'larindaki selected degerlerini false yapar
    state.opportunityFilters.filterList =
      state.opportunityFilters.filterList.map((filter) => {
        if (filter.value === filterValue) {
          if (filter.type === 'multiple' && filter.hasNested) {
            filter.children = clearChildrenSelectedStatus(filter.children);
          } else {
            filter.children = filter.children.map((option) => {
              option.selected = false;
              return option;
            });
          }
        }
        return filter;
      });
  },
  SET_OPPORTUNITY_FILTER_ADD_CHILDREN: (state, { filterValue, children }) => {
    //filterList'de filterValue'ya sahip olan filter'in children'larina esitler
    state.opportunityFilters.filterList =
      state.opportunityFilters.filterList.map((filter) => {
        if (filter.value === filterValue) {
          filter.children = children;
        }
        return filter;
      });
  },
  SET_OPPORTUNITY_FILTER_SEARCH_VALUE: (
    state,
    { filterValue, filterSearch }
  ) => {
    //filterList'de filterValue'ya sahip olan filter'in filterSearch degerini searchValue yapar
    state.opportunityFilters.filterList =
      state.opportunityFilters.filterList.map((filter) => {
        if (filter.value === filterValue) {
          filter.filterSearch = filterSearch;
        }
        return filter;
      });
  },
  SET_TEMP_FILTERS_TO_FILTERS(state, payload) {
    state.opportunityFilters = structuredClone(payload);
  },
  SET_SEARCH_KEYWORD(state, payload) {
    state.opportunityFilters.searchKeyword = payload;
  },
};
