import state from './moduleAdvertisingState';
import mutations from './moduleAdvertisingMutations';
import actions from './moduleAdvertisingActions';
import getters from './moduleAdvertisingGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
