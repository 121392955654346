const dateRange = {
  startDate: null,
  endDate: null,
};

const dashboardDateRange = null;

const dashboardDateRangeType = null;

const advertisingAvailableDateRange = null;

const trendReportdateRange = {
  startDate: null,
  endDate: null,
};

export default {
  dateRange,
  dashboardDateRange,
  dashboardDateRangeType,
  trendReportdateRange,
  advertisingAvailableDateRange,
};
