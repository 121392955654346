"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    install(Vue) {
        Vue.prototype.lang = function (...args) {
            if (args[0].includes(' '))
                args[0] = args[0].replace(' ', '');
            const isLanguagePreview = this.$store.state.isLanguagePreview;
            return isLanguagePreview ? args[0] : this.$t(...args);
        };
    },
};
