export default {
  setOpportunityFilter({ commit }, { filterValue, selectedOption, type }) {
    commit('SET_OPPORTUNITY_FILTER', { filterValue, selectedOption, type });
  },
  setOpportunityMultipleFilter({ commit }, { filterValue, selectedOption }) {
    commit('SET_OPPORTUNITY_MULTIPLE_FILTER', {
      filterValue,
      selectedOption,
    });
  },
  setOpportunityFilterChildrenActive({ commit }, { filterValue }) {
    commit('SET_OPPORTUNITY_FILTER_CHILDREN_ACTIVE', { filterValue });
  },
  resetOpportunityFilterChildrenActive({ commit }) {
    commit('RESET_OPPORTUNITY_FILTER_CHILDREN_ACTIVE');
  },
  clearOpportunityActiveFilters({ commit }, { filterValue }) {
    commit('CLEAR_OPPORTUNITY_ACTIVE_FILTERS', { filterValue });
  },
  setOpportunityFilterAddChildren({ commit }, { filterValue, children }) {
    commit('SET_OPPORTUNITY_FILTER_ADD_CHILDREN', {
      filterValue,
      children,
    });
  },
  setOpportunityFilterSearchValue({ commit }, { filterValue, filterSearch }) {
    commit('SET_OPPORTUNITY_FILTER_SEARCH_VALUE', {
      filterValue,
      filterSearch,
    });
  },

  setFiltersToTempFilters({ commit }, payload) {
    commit('SET_FILTERS_TO_TEMP_FILTERS', payload);
  },
  setSearchKeyword({ commit }, payload) {
    commit('SET_SEARCH_KEYWORD', payload);
  },
};
