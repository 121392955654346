const initialDownloadState = {
  taskId: null,
  templateType: 1,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};
export default {
  download:
    ({ download }, _, __, rootGetters) =>
    (templateType) => {
      const vendorId = rootGetters.getVendorId;
      return download[templateType][vendorId] ?? initialDownloadState;
    },
};
