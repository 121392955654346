import general from './general';
import connectAccount from './connectAccount';
import maintenance from './maintenance';
import auth from './auth';
import dashboard from './dashboard';
import navmenu from './navmenu';
import operationsCenter from './operationsCenter';
import aggregatedDashboard from './aggregatedDashboard';
import settings from './settings';
import searchProduct from './searchProduct';
import integrations from './integrations';
import replenishment from './replenishment';
import advertising from './advertising';
import parentManagement from './parentManagement';
import inventoryHealthReport from './inventoryHealthReport';
import returnManagement from './returnManagement';
import reimbursement from './reimbursement';
import repricer from './repricer';
import receiveOrders from './receiveOrders';
export default {
  general,
  connectAccount,
  maintenance,
  auth,
  dashboard,
  navmenu,
  operationsCenter,
  aggregatedDashboard,
  settings,
  searchProduct,
  integrations,
  replenishment,
  advertising,
  parentManagement,
  inventoryHealthReport,
  returnManagement,
  reimbursement,
  repricer,
  receiveOrders,
};
