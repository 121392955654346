export default {
  SET_SELECTED_DATE(state, selectedDate) {
    localStorage.setItem(
      'skuPerformanceSelectedDate',
      JSON.stringify([selectedDate[0].valueOf(), selectedDate[1].valueOf()])
    );
    state.selectedDate = selectedDate;
  },
  SET_SELECTED_DATE_OPTION(state, selectedDateOption) {
    localStorage.setItem(
      'skuPerformanceSelectedDateOption',
      JSON.stringify(selectedDateOption)
    );
    state.selectedDateOption = selectedDateOption;
  },
  SET_LAST_DATA_AVAILABLE_DATE(state, lastDataAvailableDate) {
    state.lastDataAvailableDate = lastDataAvailableDate;
  },
  SET_PERFORMANCE_COMPONENT_ORDER(state, componentOrder) {
    state.performanceComponentOrder = componentOrder;
    localStorage.setItem(
      'performanceComponentOrder',
      JSON.stringify(componentOrder)
    );
  },
  SET_PERFORMANCE_COMPONENT_VISIBILITY(state, { component, isVisible }) {
    state.performanceComponentVisibility[component] = isVisible;
    localStorage.setItem(
      'performanceComponentVisibility',
      JSON.stringify(state.performanceComponentVisibility)
    );
  },
  SET_PRICING_ACTIONS_COMPONENT_ORDER(state, pricingActionsComponentOrder) {
    state.pricingActionsComponentOrder = pricingActionsComponentOrder;
    localStorage.setItem(
      'pricingActionsComponentOrder',
      JSON.stringify(pricingActionsComponentOrder)
    );
  },
  SET_PRICING_ACTIONS_COMPONENT_VISIBILITY(state, { component, isVisible }) {
    state.pricingActionsComponentVisibility[component] = isVisible;
    localStorage.setItem(
      'pricingActionsComponentVisibility',
      JSON.stringify(state.pricingActionsComponentVisibility)
    );
  },
  ADD_HIDDEN_SERIES(state, { component, series }) {
    state.hiddenSeries[component].push(series);
    localStorage.setItem('hiddenSeries', JSON.stringify(state.hiddenSeries));
  },
  REMOVE_HIDDEN_SERIES(state, { component, series }) {
    state.hiddenSeries[component] = state.hiddenSeries[component].filter(
      (s) => s !== series
    );
    localStorage.setItem('hiddenSeries', JSON.stringify(state.hiddenSeries));
  },
  SET_COLUMN_OPTIONS(state, columnOptions) {
    state.columnOptions = columnOptions;
    const visibleKeys = columnOptions
      .filter((item) => item.visible)
      .map((item) => item.key);

    localStorage.setItem(
      'skuPerformanceVisibleColumnKeys',
      JSON.stringify(visibleKeys)
    );
  },
  SET_SKU_PERFORMANCE_LIST_DATE(state, skuPerformanceListDate) {
    state.skuPerformanceListDate = skuPerformanceListDate;
  },
  SET_SKU_IMAGES(state, skuImages) {
    state.skuImages = skuImages;
  },
  SET_SALES_DETAILS(state, salesDetails) {
    state.salesDetails = salesDetails;
  },

  //export
  SET_DOWNLOAD(state, { evaStoreId, download, willReset = false }) {
    if (willReset) {
      state.download = {
        ...state.download,
        [evaStoreId]: null,
      };
      delete state.download[evaStoreId];
    } else {
      state.download = {
        ...state.download,
        [evaStoreId]: {
          ...state.download[evaStoreId],
          ...download,
        },
      };
    }
    localStorage.setItem(
      `download-sku-performance`,
      JSON.stringify(state.download)
    );
  },
  //-------------
  //export comparison
  SET_COMPARISON_DOWNLOAD(
    state,
    { evaStoreId, downloadComparison, willReset = false }
  ) {
    if (willReset) {
      state.downloadComparison = {
        ...state.downloadComparison,
        [evaStoreId]: null,
      };
      delete state.downloadComparison[evaStoreId];
    } else {
      state.downloadComparison = {
        ...state.downloadComparison,
        [evaStoreId]: {
          ...state.downloadComparison[evaStoreId],
          ...downloadComparison,
        },
      };
    }
    localStorage.setItem(
      `download-comparison-sku-performance`,
      JSON.stringify(state.downloadComparison)
    );
  },
  //-------------

  //export and upload product cost
  SET_PRODUCT_COST_DOWNLOAD(
    state,
    { evaStoreId, download, willReset = false }
  ) {
    if (willReset) {
      state.productCostDownload = {
        ...state.productCostDownload,
        [evaStoreId]: null,
      };
      delete state.productCostDownload[evaStoreId];
    } else {
      state.productCostDownload = {
        ...state.productCostDownload,
        [evaStoreId]: {
          ...state.productCostDownload[evaStoreId],
          ...download,
        },
      };
    }
    localStorage.setItem(
      'download-product-cost',
      JSON.stringify(state.productCostDownload)
    );
  },
  SET_PRODUCT_COST_UPLOAD(state, { evaStoreId, upload, willReset = false }) {
    if (willReset) {
      state.productCostUpload = {
        ...state.productCostUpload,
        [evaStoreId]: null,
      };
      delete state.productCostUpload[evaStoreId];
    } else {
      state.productCostUpload = {
        ...state.productCostUpload,
        [evaStoreId]: {
          ...state.productCostUpload[evaStoreId],
          ...upload,
        },
      };
    }
    localStorage.setItem(
      'upload-product-cost',
      JSON.stringify(state.productCostUpload)
    );
  },
};
