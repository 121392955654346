/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import { fetchVersionInfo } from '../../src/helper';

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateTrialInfo({ commit }, payload) {
    commit('UPDATE_TRIAL_INFO', payload);
  },

  updateFundInfo({ commit }, payload) {
    commit('UPDATE_FUND_INFO', payload);
  },

  updateLoanInfo({ commit }, payload) {
    commit('UPDATE_LOAN_INFO', payload);
  },

  async updateUserInfo({ commit }, payload) {
    await commit('UPDATE_USER_INFO', payload);
  },
  updateDownloadInfo({ commit }, payload) {
    commit('UPDATE_DOWNLOAD_INFO', payload);
  },
  updateUploadInfo({ commit }, payload) {
    commit('UPDATE_UPLOAD_INFO', payload);
  },
  updateExportInfo({ commit }, payload) {
    commit('UPDATE_EXPORT_INFO', payload);
  },
  updateExportReplenishmentInfo({ commit }, payload) {
    commit('UPDATE_EXPORT_REPLENISHMENT_INFO', payload);
  },
  updateUserRole({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole);

    // Make API call to server for changing role

    // Change userInfo in localStorage and store
    dispatch('updateUserInfo', { userRole: payload.userRole });
  },
  updateDisplayBanner({ commit }, payload) {
    commit('UPDATE_DISPLAY_BANNER', payload);
  },
  updateSelectedStore({ commit }, value) {
    commit('SET_SELECTED_STORE', value);
  },
  async checkVersionInfo({ commit, dispatch }, payload) {
    const { tokenVersion, cssVersion } = await fetchVersionInfo();
    let localCssVersion = localStorage.getItem('appCssVersion');
    let localTokenVersion = localStorage.getItem('appTokenVersion');

    if (localTokenVersion === null) {
      await commit('UPDATE_VERSIONS', { tokenVersion });
      localTokenVersion = localStorage.getItem('appTokenVersion');
    }

    if (localCssVersion === null) {
      await commit('UPDATE_VERSIONS', { cssVersion });
      localCssVersion = localStorage.getItem('appCssVersion');
    }

    if (localTokenVersion !== tokenVersion && localCssVersion !== cssVersion) {
      await commit('UPDATE_VERSIONS', { tokenVersion, cssVersion });
      payload.handler(true, true);
    } else if (localCssVersion !== cssVersion) {
      await commit('UPDATE_VERSIONS', { cssVersion });
      payload.handler(false, true);
    } else if (localTokenVersion !== tokenVersion) {
      commit('UPDATE_VERSIONS', { tokenVersion });
      payload.handler(true, false);
    }

    setTimeout(() => {
      dispatch('checkVersionInfo', payload);
    }, 30000);
  },
  updateUserAndStoreInfo({ commit }, payload) {
    commit('UPDATE_USER_AND_STORE_INFO', {
      ...payload,
      willReload: payload.willReload,
    });
  },
  updateUpgrageYourPlan({ commit }, payload) {
    commit('UPDATE_UPGRADE_YOUR_PLAN', payload);
  },
  clearAppActiveUser({ commit }) {
    commit('CLEAR_APP_ACTIVE_USER');
  },
  clearAppActiveStoreTrial({ commit }) {
    commit('CLEAR_APP_ACTIVE_STORE_TRIAL');
  },
  updateMarketplaceTimeZone({ commit }, payload) {
    commit('UPDATE_MARKETPLACE_TIME_ZONE', payload);
  },
  setSuccessfullyMarketplacesList({ commit }, payload) {
    commit('SET_SUCCESSFULLY_MARKETPLACES_LIST', payload);
  },
  setSelectedAccountLevelExport({ commit }, payload) {
    commit('SET_SELECTED_ACCOUNT_LEVEL_EXPORT', payload);
  },
  setIsLanguagePreview({ commit }, payload) {
    localStorage.setItem('isLanguagePreview', payload);
    commit('SET_IS_LANGUAGE_PREVIEW', payload);
  },
  setPreviousRoute({ commit }, payload) {
    commit('SET_PREVIOUS_ROUTE', payload);
  },
  setConnectAccountRequested({ commit }, payload) {
    commit('SET_CONNECT_ACCOUNT_REQUESTED', payload);
  },
  setIsAdvertisingPrompt({ commit }, payload) {
    commit('SET_ADVERTISING_PROMPT', payload);
  },
  setIsShowFreeTrialBanner({ commit }, payload) {
    commit('SET_IS_SHOW_FREE_TRIAL_BANNER', payload);
  },
  setRepricerUsable({ commit }, payload) {
    commit('SET_REPRICER_USABLE', payload);
  },
  setDataStatusObject({ commit }, payload) {
    commit('SET_DATA_STATUS_OBJECT', payload);
  },
  setDataStatusAllDataDownloaded({ commit }, payload) {
    commit('SET_DATA_STATUS_ALL_DATA_DOWNLOADED', payload);
  },
  setSettingsDefaultTabId({ commit }, payload) {
    commit('SET_SETTINGS_DEFAULT_TAB_ID', payload);
  },
};

export default actions;
