/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Meta from 'vue-meta';
import App from './App.vue';
import EvaUI from '@evaguru/evaui';
import '@evaguru/evaui/dist/evaui.css';

Vue.use(EvaUI);

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax);
Vue.use(Meta);
Vue.use(EvaUI);

// axios
import axios from './axios.js';
Vue.prototype.$http = axios;

// API Calls
import './http/requests';

// Theme Configurations
import '../themeConfig.js';

// ACL
import acl from './acl/acl';

// Globally Registered Components
import './globalComponents.js';

// Styles: SCSS
import './assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from './router';

// Vuex Store
import store from './store/store';

// i18n
import i18n from './i18n/i18n';

// Vuexy Admin Filters
import './filters/filters';

// Clipboard
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// Tour
import VueTour from 'vue-tour';
Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');

// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

import VueCurrencyInput from 'vue-currency-input';
Vue.use(VueCurrencyInput);

// Google Maps
/*import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'YOUR_KEY',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})*/

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// Feather font icon
require('./assets/css/iconfont.css');

// Feather font icon
require('./assets/css/fonts.css');

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import TranslateProvider from './plugins/translate.ts';
Vue.use(TranslateProvider);

Vue.config.productionTip = false;

(async () => {
  let s = await store();
  let a = await acl();
  return new Vue({
    router,
    store: s,
    i18n,
    acl: a,
    render: (h) => h(App),
  }).$mount('#app');
})();
