import state from './modulePackagesAndOrderFormState';
import mutations from './modulePackagesAndOrderFormMutations';
import actions from './modulePackagesAndOrderFormActions';
import getters from './modulePackagesAndOrderFormGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
