import api from '@/api';
const initialDownloadState = {
  taskId: null,
  reportType: 8,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};

const initialComparisonDownloadState = {
  taskId: null,
  reportType: 10,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};

//product cost
const initialProductCostDownloadState = {
  taskId: null,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};
const initialProductCostUploadState = {
  taskId: null,
  status: 'IDLE',
  message: null,
  uploadSkuCount: null,
  errorSkuCount: null,
  successfulSkuCount: null,
  url: null,
  uploadProgress: 0,
  processProgress: 0,
};

export default {
  setSelectedDate({ commit }, payload) {
    commit('SET_SELECTED_DATE', payload);
  },
  setSelectedDateOption({ commit }, payload) {
    commit('SET_SELECTED_DATE_OPTION', payload);
  },
  setLastDataAvailableDate({ commit }, payload) {
    commit('SET_LAST_DATA_AVAILABLE_DATE', payload);
  },
  setPerformanceComponentOrder({ commit }, payload) {
    commit('SET_PERFORMANCE_COMPONENT_ORDER', payload);
  },
  setPerformanceComponentVisibility({ commit }, payload) {
    commit('SET_PERFORMANCE_COMPONENT_VISIBILITY', payload);
  },
  setPricingActionsComponentOrder({ commit }, payload) {
    commit('SET_PRICING_ACTIONS_COMPONENT_ORDER', payload);
  },
  setPricingActionsComponentVisibility({ commit }, payload) {
    commit('SET_PRICING_ACTIONS_COMPONENT_VISIBILITY', payload);
  },
  setHiddenSeries({ commit }, payload) {
    if (payload.isHidden) {
      commit('ADD_HIDDEN_SERIES', payload);
    } else {
      commit('REMOVE_HIDDEN_SERIES', payload);
    }
  },
  setColumnOptions({ commit }, payload) {
    commit('SET_COLUMN_OPTIONS', payload);
  },
  setSkuPerformanceListDate({ commit }, payload) {
    commit('SET_SKU_PERFORMANCE_LIST_DATE', payload);
  },
  setSkuImages({ commit }, payload) {
    commit('SET_SKU_IMAGES', payload);
  },
  setSalesDetails({ commit }, payload) {
    commit('SET_SALES_DETAILS', payload);
  },
  //export
  checkDownloadStatus({ dispatch, getters }) {
    const download = getters.download;

    if (!download?.taskId || ['ERROR', 'SUCCESS'].includes(download.status))
      return;
    api.bearer
      .post(
        {
          'query.pageSize': 25,
          'query.page': 1,
          'query.sorting.key': 'updated_at',
          'query.sorting.type': 'desc',
          'query.filter.equal.status': [
            {
              value: 'all',
            },
          ],
          'query.filter.equal.reportType': [
            {
              value: 'all',
            },
          ],
          'query.filter.equal.frequency': [
            {
              value: 'all',
            },
          ],
          'query.filter.equal.uuid': [
            {
              value: download.taskId,
            },
          ],
          'query.filter.contains.keyword': '',
        },
        'report/report-history-list'
      )
      .then((response) => {
        if (!response) throw new Error('No response');
        const { ApiStatus, ApiStatusMessage, Data } = response.data;
        const { taskStatus, uploadFileUrl } = Data[0];
        if (!ApiStatus) throw new Error(ApiStatusMessage);
        switch (taskStatus) {
          case 'In Progress':
          case 'Requested':
            setTimeout(() => {
              dispatch('checkDownloadStatus');
            }, 15000);
            return;
          case 'Done':
            dispatch('setDownload', {
              download: {
                url: uploadFileUrl,
                status: 'SUCCESS',
              },
            });
            return;
          case 'Failed':
          default:
            throw new Error('Export is failed. Please try again.');
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch('clearDownload');
      });
  },
  clearDownload({ dispatch }) {
    dispatch('setDownload', {
      willReset: true,
    });
  },
  setDownload({ commit, rootGetters }, { download, willReset = false }) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_DOWNLOAD', { evaStoreId, download, willReset });
  },
  async downloadTemplate({ dispatch }, payload) {
    try {
      dispatch('setDownload', {
        download: {
          ...initialDownloadState,
          status: 'PENDING',
        },
      });
      const response = await api.bearer.post(
        payload,
        'report/create-report-configuration'
      );
      const { ApiStatus, ApiStatusMessage, Data } = response.data;
      if (!ApiStatus) throw new Error(ApiStatusMessage);
      dispatch('setDownload', {
        download: {
          taskId: Data.requestReportTaskIdList[0],
          requestTime: new Date(),
        },
      });
      dispatch('checkDownloadStatus');
    } catch (error) {
      console.log(error);
      dispatch('clearDownload');
    }
  },
  //-----------
  //export comparison
  checkComparisonDownloadStatus({ dispatch, getters }) {
    const downloadComparison = getters.downloadComparison;

    if (
      !downloadComparison?.taskId ||
      ['ERROR', 'SUCCESS'].includes(downloadComparison.status)
    )
      return;
    api.bearer
      .post(
        {
          'query.pageSize': 25,
          'query.page': 1,
          'query.sorting.key': 'updated_at',
          'query.sorting.type': 'desc',
          'query.filter.equal.status': [
            {
              value: 'all',
            },
          ],
          'query.filter.equal.reportType': [
            {
              value: 'all',
            },
          ],
          'query.filter.equal.frequency': [
            {
              value: 'all',
            },
          ],
          'query.filter.equal.uuid': [
            {
              value: downloadComparison.taskId,
            },
          ],
          'query.filter.contains.keyword': '',
        },
        'report/report-history-list'
      )
      .then((response) => {
        if (!response) throw new Error('No response');
        const { ApiStatus, ApiStatusMessage, Data } = response.data;
        const { taskStatus, uploadFileUrl } = Data[0];
        if (!ApiStatus) throw new Error(ApiStatusMessage);
        switch (taskStatus) {
          case 'In Progress':
          case 'Requested':
            setTimeout(() => {
              dispatch('checkComparisonDownloadStatus');
            }, 15000);
            return;
          case 'Done':
            dispatch('setComparisonDownload', {
              downloadComparison: {
                url: uploadFileUrl,
                status: 'SUCCESS',
              },
            });
            return;
          case 'Failed':
          default:
            throw new Error('Comparison Export is failed. Please try again.');
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch('clearComparisonDownload');
      });
  },
  clearComparisonDownload({ dispatch }) {
    dispatch('setComparisonDownload', {
      willReset: true,
    });
  },
  setComparisonDownload(
    { commit, rootGetters },
    { downloadComparison, willReset = false }
  ) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_COMPARISON_DOWNLOAD', {
      evaStoreId,
      downloadComparison,
      willReset,
    });
  },
  async downloadComparisonTemplate({ dispatch }, payload) {
    try {
      dispatch('setComparisonDownload', {
        downloadComparison: {
          ...initialComparisonDownloadState,
          status: 'PENDING',
        },
      });
      const response = await api.bearer.post(
        payload,
        'report/create-report-configuration'
      );
      const { ApiStatus, ApiStatusMessage, Data } = response.data;
      if (!ApiStatus) throw new Error(ApiStatusMessage);
      dispatch('setComparisonDownload', {
        downloadComparison: {
          taskId: Data.requestReportTaskIdList[0],
          requestTime: new Date(),
        },
      });
      dispatch('checkComparisonDownloadStatus');
    } catch (error) {
      console.log(error);
      dispatch('clearComparisonDownload');
    }
  },
  //-----------

  //product cost export and upload
  checkProductCostDownloadStatus({ dispatch, getters }) {
    let download = getters.downloadProductCost;
    if (!download?.taskId || ['ERROR', 'SUCCESS'].includes(download.status))
      return;
    api.bearer
      .post(
        { taskId: download.taskId },
        'repricer/product-cost-list-template-task-status'
      )
      .then((response) => {
        const {
          data: {
            ApiStatusCode,
            ApiStatusMessage,
            Data: { status, errorMessage, downloadFileName },
          },
        } = response;
        if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
        switch (status) {
          case 0:
          case 1:
          case 4:
            setTimeout(() => {
              dispatch('checkProductCostDownloadStatus');
            }, 15000);
            return;
          case 2:
            dispatch('setProductCostDownload', {
              download: {
                url: downloadFileName,
                status: 'SUCCESS',
              },
            });
            return;
          case 3:
            dispatch('setProductCostDownload', {
              download: {
                status: 'ERROR',
                message: errorMessage,
              },
            });
            return;
          default:
            throw new Error(errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch('clearProductCostDownload');
      });
  },
  clearProductCostDownload({ dispatch }) {
    dispatch('setProductCostDownload', {
      willReset: true,
    });
  },
  checkProductCostUploadStatus({ dispatch, getters }) {
    let upload = getters.uploadProductCost;
    if (!upload?.taskId || ['ERROR', 'SUCCESS'].includes(upload.status)) return;
    api.bearer
      .post(
        { taskId: upload.taskId },
        'repricer/product-cost-list-template-task-status'
      )
      .then((response) => {
        const {
          data: {
            ApiStatusMessage,
            ApiStatusCode,
            Data: {
              uploadSkuCount,
              errorSkuCount,
              successfulSkuCount,
              errorFileName,
              status,
              errorMessage,
            },
          },
        } = response;
        if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
        switch (status) {
          case 0:
          case 1:
          case 4:
            dispatch('setProductCostUpload', {
              upload: {
                processProgress:
                  upload.processProgress < 80
                    ? upload.processProgress + 10
                    : 90,
              },
            });
            setTimeout(() => {
              dispatch('checkProductCostUploadStatus');
            }, 15000);
            break;
          case 2:
            dispatch('setProductCostUpload', {
              upload: {
                processProgress: 100,
                status: 'SUCCESS',
                message: ApiStatusMessage,
                uploadSkuCount,
                errorSkuCount,
                successfulSkuCount,
                url: errorFileName,
              },
            });
            break;
          case 3:
            dispatch('setProductCostUpload', {
              upload: {
                processProgress: 100,
                status: 'ERROR',
                message: errorMessage,
              },
            });
            break;
          default:
            throw new Error('Invalid status returned from upload task');
        }
      })
      .catch((error) => {
        dispatch('setProductCostUpload', {
          upload: {
            status: 'ERROR',
            message: error.message,
          },
        });
      });
  },
  clearUploadProductCostTask({ dispatch }) {
    dispatch('setProductCostUpload', {
      willReset: true,
    });
  },
  setProductCostDownload(
    { commit, rootGetters },
    { download, willReset = false }
  ) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_PRODUCT_COST_DOWNLOAD', { evaStoreId, download, willReset });
  },
  setProductCostUpload({ commit, rootGetters }, { upload, willReset = false }) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_PRODUCT_COST_UPLOAD', { evaStoreId, upload, willReset });
  },
  async downloadProductCostTemplate({ dispatch }) {
    try {
      dispatch('setProductCostDownload', {
        download: {
          ...initialProductCostDownloadState,
          status: 'PENDING',
        },
      });
      const response = await api.bearer.post(
        {},
        'repricer/download-product-cost-list'
      );
      const {
        ApiStatusCode,
        ApiStatusMessage,
        Data: { UUID },
      } = response.data;
      if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
      dispatch('setProductCostDownload', {
        download: {
          taskId: UUID,
          requestTime: new Date(),
        },
      });
      dispatch('checkProductCostDownloadStatus');
    } catch (error) {
      console.log(error);
      dispatch('clearProductCostDownload');
    }
  },
  async uploadProductCostTemplate({ dispatch }, { file }) {
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.open(
      'POST',
      `${process.env.VUE_APP_SERVICE_URL}repricer/upload-product-cost-list`,
      true
    );
    request.setRequestHeader(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With'
    );
    request.setRequestHeader('Access-Control-Allow-Origin', '*');
    request.setRequestHeader(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );
    dispatch('setProductCostUpload', {
      upload: {
        ...initialProductCostUploadState,
        status: 'PENDING',
        message: 'Yout file is being uploaded. Please wait...',
      },
    });
    request.onreadystatechange = async () => {
      if (request.readyState === 4 && request.status === 200) {
        let { ApiStatus, ApiStatusCode, ApiStatusMessage, Data } = JSON.parse(
          request.response
        );
        if (!ApiStatus) {
          dispatch('setProductCostUpload', {
            upload: {
              status: 'ERROR',
              message: ApiStatusMessage,
              uploadProgress: 0,
              processProgress: null,
            },
          });
        } else if (ApiStatusCode !== 200) {
          dispatch('setProductCostUpload', {
            upload: {
              status: 'ERROR',
              message: 'Something went wrong. Please try again later.',
              uploadProgress: 0,
              processProgress: null,
            },
          });
        } else {
          dispatch('setProductCostUpload', {
            upload: {
              taskId: Data.UUID,
              uploadProgress: 100,
              processProgress: 0,
              message: 'Upload successful. Processing file.',
            },
          });
          dispatch('checkProductCostUploadStatus');
        }
      }
    };
    formData.append('marketplace', localStorage.getItem('marketplace'));
    formData.append('sellerId', localStorage.getItem('store'));
    formData.append('updateFile', file);
    request.send(formData);
    request.onprogress = (e) => {
      if (e.lengthComputable) {
        const percent = Math.round((e.loaded / e.total) * 100);
        dispatch('setProductCostUpload', {
          upload: {
            uploadProgress: percent < 100 ? percent : 99,
          },
        });
      }
    };
  },
};
