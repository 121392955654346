export default {
  SET_DOWNLOAD(state, { templateType, vendorId, download, willReset = false }) {
    if (willReset) {
      state.download[templateType] = {
        ...state.download[templateType],
        [vendorId]: null,
      };
      delete state.download[templateType][vendorId];
    } else {
      state.download[templateType] = {
        ...state.download[templateType],
        [vendorId]: {
          ...state.download[templateType][vendorId],
          ...download,
        },
      };
    }
    localStorage.setItem(
      `download-manage-vendor-${templateType}`,
      JSON.stringify(state.download[templateType])
    );
  },
};
