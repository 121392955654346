import state from './moduleDailyTaskState.js';
import mutations from './moduleDailyTaskMutations.js';
import actions from './moduleDailyTaskActions.js';
import getters from './moduleDailyTaskGetters.js';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
