/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {
  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: (state) => {
    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl';
    else if (state.windowWidth >= 992) return 'lg';
    else if (state.windowWidth >= 768) return 'md';
    else if (state.windowWidth >= 576) return 'sm';
    else return 'xs';
  },

  scrollbarTag: (state) => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar';
  },

  showBanner: (state) => {
    return !state.isBannerShown;
  },

  advertisingPrompt: (state) => {
    return state.isAdvertisingPrompt;
  },

  storeList: ({ AppActiveUser }) => {
    if (!AppActiveUser.user) return null;

    return AppActiveUser.user.store;
  },

  selectedStoreIndex: (_state, getters) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const marketplaceName =
      urlParams.get('marketplace') || localStorage.getItem('marketplace');
    const storeId = urlParams.get('store') || localStorage.getItem('store');
    if (!getters.storeList) return null;

    if (!marketplaceName || !storeId) return 0;

    const storeIndex = getters.storeList.findIndex(
      (store) =>
        store.storeId === storeId && store.marketplaceName === marketplaceName
    );

    return storeIndex >= 0 ? storeIndex : 0;
  },

  selectedStore(state, getters) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const marketplaceName =
      urlParams.get('marketplace') || localStorage.getItem('marketplace');
    const storeId = urlParams.get('store') || localStorage.getItem('store');
    if (!state.AppActiveUser.user || !state.AppActiveUser.user.store)
      return null;

    if (!marketplaceName || !storeId) return state.AppActiveUser.user.store[0];

    const storeIndex = getters.selectedStoreIndex;
    if (!state.AppActiveUser.user.store[storeIndex])
      return state.AppActiveUser.user.store[0];

    return state.AppActiveUser.user.store[storeIndex];
  },
  getVersionInfo(state) {
    return state.version;
  },
  getUserInfo(state) {
    return {
      user: state.AppActiveUser.user,
      isLinkAccount: state.AppActiveUser.isLinkAccount,
    };
  },
  getMarketplaceTimeZone(state) {
    return state.marketplaceTimeZone;
  },
  getEvaStoreId(state) {
    return state.selectedStore?.evaStoreId;
  },
  previousRoute(state) {
    return state.previousRoute;
  },
  isConnectAccountRequested: (state) => state.isConnectAccountRequested,
  getVendorId(state) {
    return state.vendor?.activeStore?.vendorId;
  },
  getWalmartPartnerId(state) {
    return state.walmart?.activeStore?.partnerId;
  },
  getIsShowFreeTrialBanner(state) {
    return state.isShowFreeTrialBanner;
  },
  getRepricerUsable(state) {
    return state.repricerUsable;
  },
  getDataStatusObject(state) {
    return state.dataStatusObject;
  },
  getIsAllDataDownloaded(state) {
    return state.dataStatusAllDataDownloaded;
  },
  getSettingsDefaultTabId(state) {
    return state.settingsDefaultTabId;
  },
  getDataStatusAllDataDownloaded(state) {
    return state.dataStatusAllDataDownloaded;
  },
};

export default getters;
