import state from './moduleIntegrationsState';
import mutations from './moduleIntegrationsMutations';
import actions from './moduleIntegrationsActions';
import getters from './moduleIntegrationsGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
