import { reportTypes } from '@/views/components/reporting-dashboard/enumsAndConfigs.js';
import moment from 'moment';
export default {
  SET_SELECTED_REPORT_TYPE(state, payload) {
    state.selectedReportType = payload;
    const selectedReportType = reportTypes.find(
      (item) => item.id === payload.id
    );
    if (selectedReportType.configs.dateRange) {
      state.reportPeriodDateType =
        selectedReportType.configs.reportPeriodDateType;
      state.reportPeriodDateFormat =
        selectedReportType.configs.reportPeriodDateFormat;
      state.reportPeriodDateRange =
        selectedReportType.configs.reportPeriodDateRange;
    }
  },
  SET_REPORT_NAME(state, payload) {
    state.reportName = payload;
  },
  SET_STEP_COMPLETED(state, payload) {
    state[`step${payload.currentStep}Completed`] = payload.isCompleted;
  },
  CLEAR_REPORTING_STEPS(state) {
    state.selectedReportType = null;
    state.reportName = '';
    state.selectedStores = [];
    state.selectedFrequency = '';
    state.selectedDateRange = [];
    state.selectedInterval = '';
    state.selectedAverageOf = '';
    state.selectedNegativeProfitSwitch = false;
    state.selectedCreateOn = '';
    state.selectedCompareStartDay = '';
    state.selectedCompareEndDay = '';
    state.selectedCompareStartDay = '';
    state.selectedCompareEndDay = '';
    state.emailList = [];
    state.dailyEndDate = '';
    state.weeklyEndDate = '';
    state.monthlyEndDate = '';
    state.step1Completed = false;
    state.step2Completed = false;
    state.step3Completed = false;
  },
  SET_SELECTED_STORES(state, payload) {
    state.selectedStores = payload;
  },
  SET_SELECTED_FREQUENCY(state, payload) {
    state.selectedFrequency = payload;
  },
  SET_SELECTED_DATE_RANGE(state, payload) {
    state.selectedDateRange = payload;
  },
  SET_SELECTED_INTERVAL(state, payload) {
    state.selectedInterval = payload;
  },
  SET_SELECTED_AVERAGE_OF(state, payload) {
    state.selectedAverageOf = payload;
  },
  SET_SELECTED_NEGATIVE_PROFIT_SWITCH(state, payload) {
    state.selectedNegativeProfitSwitch = payload;
  },
  SET_SELECTED_CREATE_ON(state, payload) {
    state.selectedCreateOn = payload;
  },
  SET_SELECTED_COMPARE_START_DAY(state, payload) {
    state.selectedCompareStartDay = payload;
  },
  SET_SELECTED_COMPARE_END_DAY(state, payload) {
    state.selectedCompareEndDay = payload;
  },
  SET_DATE_FORMAT(state, payload) {
    state.dateFormat = payload;
  },
  SET_DATE_PICKER_TYPE(state, payload) {
    state.datePickerType = payload;
  },
  SET_END_DATE(state, payload) {
    if (state.selectedInterval === 2) {
      state.dailyEndDate = payload;
    } else if (state.selectedInterval === 3) {
      state.weeklyEndDate = payload;
    } else {
      state.monthlyEndDate = payload;
    }
  },
  SET_EMAIL_LIST(state, payload) {
    state.emailList = payload;
  },

  SET_EDIT_REPORT_DATA(state, payload) {
    state.editReportData = payload;
    if (
      state.editReportData.config.frequency === 2 ||
      state.editReportData.config.frequency === 3
    ) {
      state.editReportData.config.dateFormat = 'YYYY-MM-DD';
      state.editReportData.config.datePickerType = 'date';
    } else {
      state.editReportData = {
        ...payload,
        config: {
          ...payload.config,
          reportCreationEndDate: moment(
            payload.config.reportCreationEndDate,
            'YYYY-MM-DD'
          ).format('MM-YYYY'),
        },
      };
      state.editReportData.config.dateFormat = 'MM-YYYY';
      state.editReportData.config.datePickerType = 'month';
    }
  },
  CHANGE_EDIT_SELECTED_STORES(state, payload) {
    state.editReportData.selectedStores = payload;
  },
  CHANGE_EDIT_END_DATE(state, payload) {
    state.editReportData.config.reportCreationEndDate = payload;
    if (
      state.editReportData.config.frequency === 2 ||
      state.editReportData.config.frequency === 3
    ) {
      state.editReportData.config.dateFormat = 'YYYY-MM-DD';
      state.editReportData.config.datePickerType = 'date';
    } else {
      state.editReportData.config.dateFormat = 'MM-YYYY';
      state.editReportData.config.datePickerType = 'month';
    }
  },
  CHANGE_EDIT_SELECTED_EMAILS(state, payload) {
    state.editReportData.config.emailList = payload;
  },
  CHANGE_EDIT_NEGATIVE_PROFIT_SWITCH(state, payload) {
    state.editReportData.config.onlyIncludeNegativeProfitData = payload;
  },

  //REPORT HISTORY FILTERS AND SEARCH
  SET_REPORT_HISTORY_SEARCH(state, payload) {
    state.reportHistory.search = payload;
  },
  SET_REPORT_HISTORY_FILTERS(state, payload) {
    state.reportHistory.filters.frequency =
      payload?.frequency || state.reportHistory.filters.frequency;
    state.reportHistory.filters.status =
      payload?.status || state.reportHistory.filters.status;

    state.reportHistory.filters.reportTypes =
      payload?.reportTypes || state.reportHistory.filters.reportTypes;
  },

  //REPORT CONFIGURATION FILTERS AND SEARCH
  SET_REPORT_CONFIGURATIONS_SEARCH(state, payload) {
    state.reportConfigurations.search = payload;
  },
  SET_REPORT_CONFIGURATIONS_FILTERS(state, payload) {
    state.reportConfigurations.filters.reportTypes =
      payload?.reportTypes || state.reportConfigurations.filters.reportTypes;
  },

  SET_REPORT_HISTORY_API_REQUEST_STATUS(state, payload) {
    state.reportHistoryApiRequestStatus = payload;
  },
  SET_REPORT_CONFIGURATIONS_API_REQUEST_STATUS(state, payload) {
    state.reportConfigurationsApiRequestStatus = payload;
  },
};
