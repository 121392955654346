var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.activeRef)?_c('div',{staticClass:"w-screen h-screen fixed flex justify-center items-center z-[99999] left-0 top-0",on:{"click":_setup.handleClickOutside}},[_c('div',{ref:"outsideRef",staticClass:"fixed w-screen h-screen bg-black transition ease-in-out delay-150 opacity-20 dark:opacity-40"}),_c('div',{staticClass:"z-[99999999] transition-all",style:([
      _vm.animationType === 'fade'
        ? { opacity: _setup.animatedValue }
        : { transform: `scale(${_setup.animatedValue})` },
      { transitionDuration: `${_vm.animationDuration}ms !important` },
    ])},[(_setup.defaultSlot)?[_vm._t("default"),(_setup.footer)?_vm._t("footer"):_vm._e()]:[_c('div',{staticClass:"flex flex-col px-8 py-6 gap-4 rounded overflow-y-auto",class:_setup.theme === 'dark' ? 'bg-[#23272b]' : 'bg-[#fff]',style:({
          minWidth: _setup.props.minWidth,
          maxWidth: _setup.props.maxWidth,
          minHeight: _setup.props.minHeight,
          maxHeight: _setup.props.maxHeight,
        })},[_c('div',{staticClass:"gap-2 items-center",class:[
            _setup.EvaluateTypes.getDisplayFormat(_setup.props.titlesFormat),
            _setup.EvaluateTypes.getWidth(_setup.props.confirmButtonWidth),
            _vm.headerAlign === 'right'
              ? 'justify-end'
              : _vm.headerAlign === 'center'
              ? 'justify-center'
              : 'justify-start',
          ]},[_c('feather-icon',{class:[
              _setup.EvaluateTypes.getIconClass(_setup.props.icon),
              _setup.EvaluateTypes.getDisplayFormat(_setup.props.titlesFormat),
              _setup.EvaluateTypes.getWidth(_setup.props.confirmButtonWidth),
              _setup.EvaluateTypes.getIconSize(_setup.props.iconSize),
            ],attrs:{"icon":_setup.EvaluateTypes.getIcon(_setup.props.icon),"svgClasses":[_setup.EvaluateTypes.getIconSize(_setup.props.iconSize)]}}),_c('span',{staticClass:"text-2xl font-semibold",class:[
              _setup.EvaluateTypes.getDisplayFormat(_setup.props.titlesFormat),
              _setup.EvaluateTypes.getWidth(_setup.props.confirmButtonWidth),
              _setup.theme === 'dark' ? 'text-[#c8d1db]' : 'text-gray-800',
            ]},[_vm._v(_vm._s(_setup.props.title))])],1),_c('div',[_c('p',{staticClass:"text-lg font-semibold",class:_setup.theme === 'dark' ? 'text-[#e0e0e0]' : 'text-gray-600'},[(_setup.messageSlot)?[_vm._t("message")]:[_vm._v(" "+_vm._s(_setup.props.message)+" ")]],2)]),(_setup.props.hasCancelButton || _setup.props.hasConfirmButton)?_c('div',{staticClass:"flex border-t border-gray-200 pt-4 gap-2",class:_vm.footerAlign === 'right'
              ? 'justify-end'
              : _vm.footerAlign === 'center'
              ? 'justify-center'
              : 'justify-start'},[(_setup.props.hasCancelButton)?_c('EvaButton',{staticClass:"h-[32px]",attrs:{"type":"default"},on:{"click":_setup.cancel}},[_vm._v(" "+_vm._s(_setup.props.cancelButtonText)+" ")]):_vm._e(),(_setup.props.hasConfirmButton)?_c('EvaButton',{staticClass:"flex items-center justify-center gap-x-2 h-[32px]",attrs:{"type":"primary"},on:{"click":_setup.confirm}},[(_vm.confirmButtonBeforeIcon)?_c('feather-icon',{staticClass:"w-4 h-4",class:_setup.EvaluateTypes.getColorClass(_setup.props.confirmButtonIconColor),attrs:{"icon":_setup.EvaluateTypes.getIcon(_setup.props.confirmButtonBeforeIcon)}}):_vm._e(),_c('p',[_vm._v(_vm._s(_setup.props.confirmButtonText))]),(_vm.confirmButtonAfterIcon)?_c('feather-icon',{staticClass:"w-4 h-4",class:_setup.EvaluateTypes.getColorClass(_setup.props.confirmButtonIconColor),attrs:{"icon":_setup.EvaluateTypes.getIcon(_setup.props.confirmButtonAfterIcon)}}):_vm._e()],1):_vm._e()],1):_vm._e()])]],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }