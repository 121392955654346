export default {
  SET_ACTIVE_STORE(state, activeStore) {
    state.activeStore = activeStore;
    localStorage.setItem(
      'activeWalmartStore',
      activeStore ? JSON.stringify(activeStore) : null
    );
  },
  SET_SELECTED_DATE(state, selectedDate) {
    state.selectedDate = selectedDate;
  },
  SET_CONNECT_ACCOUNT_REQUESTED(state, isConnectAccountRequested) {
    state.isConnectAccountRequested = isConnectAccountRequested;
  },
  SET_COMPONENT_ORDER(state, componentOrder) {
    state.componentOrder = componentOrder;
    localStorage.setItem(
      'walmartComponentOrder',
      JSON.stringify(componentOrder)
    );
  },
  ADD_HIDDEN_SERIES(state, { component, series }) {
    state.hiddenSeries[component].push(series);
  },
  REMOVE_HIDDEN_SERIES(state, { component, series }) {
    state.hiddenSeries[component] = state.hiddenSeries[component].filter(
      (s) => s !== series
    );
  },
  SET_WALMART_INFO_CARD_STATUS(state, payload) {
    state.walmartInfoCardStatus = payload;
  },
  SET_WALMART_INFO_REAL_TIME_STATUS(state, payload) {
    state.walmartInfoRealTimeStatus = payload;
  },
  SET_ADVERTISING_CAMPAIGN(state, payload) {
    state.advertisingCampaign = payload;
  },
  SET_ADVERTISING_AD_GROUP(state, payload) {
    state.advertisingAdGroup = payload;
  },
  SET_ADVERTISING_AD_ITEM_LIST(state, payload) {
    state.advertisingAdItemList = payload;
  },
  SET_ADVERTISING_KEYWORDS(state, payload) {
    state.advertisingKeywords = payload;
  },
  SET_ACTIVE_TAB(state, payload) {
    state.activeTab = payload;
  },
  SET_SELECT_CAMPAIGN(state, payload) {
    state.selectedCampaign = payload;
  },
  SET_SELECT_AD_GROUP(state, payload) {
    state.selectedAdGroup = payload;
  },
  SET_ADVERTISING_SB_PROFILES(state, payload) {
    state.advertisingSBProfiles = payload;
  },
  SET_ADVERTISING_KEYWORD_LIST(state, payload) {
    state.advertisingKeywordList = payload;
  },
  SET_ANALYTICS_CHART_DATA(state, payload) {
    state.analyticsChartData = payload;
  },
};
