export default {
  SET_PACKAGES_AND_ORDER_FORM_POPUP_STATUS(state, payload) {
    state.packagesAndOrderFormPopupStatus = payload;
  },
  SET_REMINDER_BANNER_NOT_INTEREST_CLICKED(state, payload) {
    state.reminderBannerNotInterestClicked = payload;
  },
  SET_STORE_LIST(state, payload) {
    state.storeList = payload;
  },
  SET_CALCULATED_DATA(state, payload) {
    state.calculatedData = {
      ...state.calculatedData,
      ...payload,
    };
  },
  SET_STRIPE_PAYMENT(state, payload) {
    state.stripePayment = {
      ...state.stripePayment,
      ...payload,
    };
  },
  SET_INITIAL_STRIPE_PAYMENT(state) {
    state.stripePayment = {
      isSuccessPayment: false,
      paymentAmount: null,
      payLoadingView: false,
      payLoading: false,
    };
  },
  INCREASE_CURRENT_STEP(state, { stepType, stepNo }) {
    state[`${stepType}StepInfo`].currentStep += 1;
  },
  DECREASE_CURRENT_STEP(state, { stepType }) {
    state[`${stepType}StepInfo`].currentStep -= 1;
  },
  SET_BACK_STEP_STATUS(state, { stepType, status }) {
    state[`${stepType}StepInfo`].backStepStatus = status;
  },
  SET_STEP_TITLE(state, { stepType, title }) {
    state[`${stepType}StepInfo`].stepTitle = title;
  },
  SET_STEP_DESCRIPTION(state, { stepType, description }) {
    state[`${stepType}StepInfo`].stepDescription = description;
  },
  SET_CURRENT_STEP_AND_TOTAL_STEP(state, { stepType, currentStep, totalStep }) {
    state[`${stepType}StepInfo`].currentStep = currentStep;
    state[`${stepType}StepInfo`].totalStep = totalStep;
  },
  SET_SELECTABLE_SERVICES(state, payload) {
    state.selectableServices.forEach((service) => {
      if (service.id === payload) {
        service.isActive = true;
      } else {
        service.isActive = false;
      }
    });

    if (payload === 1) {
      state.stepType = 'Platform';
      state.totalStep = 4;
    } else if (payload === 2) {
      state.stepType = 'FullService';
      state.totalStep = 2;
    }
  },
  SET_SELECTED_STORES(state, payload) {
    state.storeList = [...state.storeList];
    if (!payload) {
      state.selectedStoreList = [];
      return;
    }
    const index = state.selectedStoreList.findIndex(
      (store) =>
        store.sellerId === payload.sellerId &&
        store.marketplaceId === payload.marketplaceId
    );
    if (index !== -1) {
      // Eğer öğe bulunursa, diziden çıkarılır
      state.selectedStoreList.splice(index, 1);
    } else {
      // Eğer öğe bulunamazsa, diziye eklenir
      state.selectedStoreList.push(payload);
    }
  },
  SET_SELECT_ALL_STORES(state, payload) {
    if (payload && payload.length > 0) {
      if (payload.length === state.selectedStoreList.length) {
        state.selectedStoreList = [];
        return;
      }
      state.selectedStoreList = payload;
    } else {
      state.selectedStoreList = [];
    }
  },
  SET_KICKOFF_DATE(state, payload) {
    state.kickoffDate = payload;
  },
  SET_ORDER_FORM_INPUTS(state, { key, value }) {
    state.orderForm[key] = value;
  },
  SET_COMMUNICATION_FORM_INPUTS(state, { key, value }) {
    state.communicationForm[key] = value;
  },

  SET_STEP_INFO(
    state,
    {
      stepType,
      currentStep,
      totalStep,
      backStepStatus,
      stepTitle,
      stepDescription,
    }
  ) {
    state[`${stepType}StepInfo`] = {
      currentStep,
      totalStep,
      backStepStatus,
      stepTitle,
      stepDescription,
    };
  },
  SET_TOTAL_STEP(state, { stepType, totalStep }) {
    state[`${stepType}StepInfo`].totalStep = totalStep;
  },
  SET_PACKAGE_CONTENT(state, payload) {
    state.packageContent = payload;
  },
  SET_PRICING_CONTENT(state, payload) {
    state.pricingContent = payload;
  },
  SET_ACCEPT_OFFER_RADIO(state, payload) {
    state.acceptOfferRadio = payload;
  },
  SET_ACTIVE_BANNER(state, payload) {
    state.activeBanner = payload;
  },
  SET_OPEN_FROM_HEADER(state, payload) {
    state.openFromHeader = payload;
  },

  // Cancellation
  SET_CANCELLATION_POPUP_STATUS(state, payload) {
    state.cancellationPopupStatus = payload;
  },
  SET_CANCELLATION_STEP(state, payload) {
    state.cancellationStep = payload;
  },
  SET_CANCELLATION_BACK_STEP_STATUS(state, payload) {
    state.cancellationBackStepStatus = payload;
  },
  SET_CANCELLATION_REASON_TYPE(state, payload) {
    state.cancellationReasonType = payload;
  },
  SET_CANCELLATION_EXPLANATION(state, payload) {
    state.cancellationExplanation = payload;
  },
  SET_EMAIL_TO_SEND_STATUS(state, payload) {
    state.emailToSendStatus = payload;
  },

  //Banner
  SET_IS_OFFER_RADIO_ACCEPTED(state, payload) {
    state.isOfferRadioAccepted = payload;
  },
  SET_IS_OFFER_CHECKBOX_ACCEPTED(state, payload) {
    state.isOfferCheckboxAccepted = payload;
  },
  SET_I_AGGREE_CHECKBOX(state, payload) {
    state.iAggreeCheckbox = payload;
  },
  SET_LAST_SUB_END_DATE(state, payload) {
    state.lastSubEndDate = payload;
  },
  SET_NEXT_SUB_START_DATE(state, payload) {
    state.nextSubStartDate = payload;
  },
  SET_CANCEL_PAYMENT_AMOUNT(state, payload) {
    state.cancelPaymentAmount = payload;
  },
  SET_SEO_CHECKBOX(state, payload) {
    state.platformStepInfo.isSeoCheckboxSelected = payload;
  },
};
