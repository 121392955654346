import EventEmitter from 'events';
import localForage from 'localforage';
// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const localStorageKey = 'loggedIn';

const tokenExpiryKey = 'tokenExpiry';
const loginEvent = 'loginEvent';

class AuthService extends EventEmitter {
  idToken = null;
  profile = null;
  tokenExpiry = null;

  async logOut() {
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem('displayBanner');
    localStorage.removeItem(tokenExpiryKey);
    localStorage.removeItem('store');
    localStorage.removeItem('storeName');
    localStorage.removeItem('marketplace');
    localStorage.removeItem('region');
    localStorage.removeItem('marketplaceCode');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('storeInfo');
    localStorage.removeItem('trial');
    localStorage.removeItem('isLoginFormConfirm');
    localStorage.removeItem('activeVendorStore');
    await localForage.removeItem('userInfo');

    this.idToken = null;
    this.tokenExpiry = null;
    this.profile = null;

    this.emit(loginEvent, { loggedIn: false });
  }

  isAuthenticated() {
    return (
      new Date(Date.now()) < new Date(localStorage.getItem(tokenExpiryKey)) &&
      localStorage.getItem(localStorageKey) === 'true'
    );
  }
}

export default new AuthService();
