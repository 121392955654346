import { COLUMN_OPTIONS } from '@/constants/skuPerformance.ts';

const getColumnOptions = () => {
  if (localStorage.getItem('skuPerformanceVisibleColumnKeys')) {
    let visibleKeys = JSON.parse(
      localStorage.getItem('skuPerformanceVisibleColumnKeys')
    );
    let newColumnOptions = COLUMN_OPTIONS.map((item) => {
      if (visibleKeys.includes(item.key)) {
        return {
          ...item,
          visible: true,
        };
      } else {
        return {
          ...item,
          visible: false,
        };
      }
    });
    return newColumnOptions;
  }
  return COLUMN_OPTIONS;
};

export default {
  selectedDate: [],
  selectedDateOption: null,
  lastDataAvailableDate: null,
  performanceComponentOrder: JSON.parse(
    localStorage.getItem('performanceComponentOrder')
  ) || [1, 2, 3, 4],
  performanceComponentVisibility: JSON.parse(
    localStorage.getItem('performanceComponentVisibility')
  ) || {
    profitAnalytics: true,
  },
  pricingActionsComponentOrder: JSON.parse(
    localStorage.getItem('pricingActionsComponentOrder')
  ) || [1, 2],
  pricingActionsComponentVisibility: JSON.parse(
    localStorage.getItem('pricingActionsComponentVisibility')
  ) || {
    skuPricingAndBuyBox: true,
  },
  hiddenSeries: {
    profitAnalytics:
      JSON.parse(localStorage.getItem('hiddenSeries'))?.profitAnalytics || [],
    keyPerformance: JSON.parse(localStorage.getItem('hiddenSeries'))
      ?.keyPerformance || ['roi', 'acos', 'tacos', 'roas', 'adCvr', 'totalCvr'],
    trafficSales: JSON.parse(localStorage.getItem('hiddenSeries'))
      ?.trafficSales || ['totalSessions', 'totalPageViews'],
    inventoryReimbursementsRefund:
      JSON.parse(localStorage.getItem('hiddenSeries'))
        ?.inventoryReimbursementsRefund || [],
  },
  columnOptions: getColumnOptions(),
  skuPerformanceListDate: null,
  skuImages: [],
  salesDetails: {},
  //export
  download: JSON.parse(localStorage.getItem('download-sku-performance')) ?? {},
  //---------
  //export comparison
  downloadComparison:
    JSON.parse(localStorage.getItem('download-comparison-sku-performance')) ??
    {},
  //---------
  //product cost export and upload
  productCostDownload:
    JSON.parse(localStorage.getItem('download-product-cost')) ?? {},
  productCostUpload:
    JSON.parse(localStorage.getItem('upload-product-cost')) ?? {},
};
