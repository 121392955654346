export default {
  postponed:
    JSON.parse(localStorage.getItem('renewTokenPostpone'))?.postponed || false,
  postponedAt:
    JSON.parse(localStorage.getItem('renewTokenPostpone'))?.postponedAt || null,
  postponedForMinutes:
    JSON.parse(localStorage.getItem('renewTokenPostpone'))
      ?.postponedForMinutes || null,
  postponedForStore:
    JSON.parse(localStorage.getItem('renewTokenPostpone'))?.postponedForStore ||
    null,
  postponedForMarketplace:
    JSON.parse(localStorage.getItem('renewTokenPostpone'))
      ?.postponedForMarketplace || null,
};
