export default {
  //Invoice
  ADD_INVOICE_PDF(state, payload) {
    state.invoicePdfList.push(payload);
  },
  UPDATE_INVOICE_PDF(state, payload) {
    const foundInvoiceIndex = state.invoicePdfList.findIndex(
      (element) => element.templateId === payload.templateId
    );
    state.invoicePdfList[foundInvoiceIndex] = payload;
    state.invoicePdfList = [...state.invoicePdfList];
  },
  REMOVE_INVOICE_PDF(state, payload) {
    const foundInvoiceIndex = state.invoicePdfList.findIndex(
      (element) => element.templateId === payload.templateId
    );
    state.invoicePdfList.splice(foundInvoiceIndex, 1);
    state.invoicePdfList = [...state.invoicePdfList];
  },
  SET_INVOICE_PDF_ARRAY(state, payload) {
    if (payload.length) {
      state.invoicePdfList.push(...payload);
    } else {
      state.invoicePdfList = [];
    }
  },

  //Receipt
  ADD_RECEIPT_PDF(state, payload) {
    state.receiptPdfList.push(payload);
  },
  UPDATE_RECEIPT_PDF(state, payload) {
    const foundInvoiceIndex = state.receiptPdfList.findIndex(
      (element) => element.templateId === payload.templateId
    );
    state.receiptPdfList[foundInvoiceIndex] = payload;
    state.receiptPdfList = [...state.receiptPdfList];
  },
  REMOVE_RECEIPT_PDF(state, payload) {
    const foundInvoiceIndex = state.receiptPdfList.findIndex(
      (element) => element.templateId === payload.templateId
    );
    state.receiptPdfList.splice(foundInvoiceIndex, 1);
    state.receiptPdfList = [...state.receiptPdfList];
  },
  SET_RECEIPT_PDF_ARRAY(state, payload) {
    state.receiptPdfList = payload;
  },
};
