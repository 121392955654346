import api from '@/api';
const initialDownloadState = {
  taskId: null,
  templateType: 0,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};
const initialUploadState = {
  taskId: null,
  templateType: 0,
  status: 'IDLE',
  message: null,
  uploadSkuCount: null,
  errorSkuCount: null,
  successfulSkuCount: null,
  deleteSkuCount: null,
  url: null,
  uploadProgress: 0,
  processProgress: 0,
};
export default {
  checkDownloadStatus({ dispatch, getters }) {
    const download = getters.download;

    if (!download?.taskId || ['ERROR', 'SUCCESS'].includes(download.status))
      return;
    api.bearer
      .post(
        { taskId: download.taskId, templateType: download.templateType },
        `reimbursement/template-task-status`
      )
      .then((response) => {
        const {
          data: {
            ApiStatusCode,
            ApiStatusMessage,
            Data: { status, errorMessage, downloadFileName },
          },
        } = response;
        if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
        switch (status) {
          case 0:
          case 1:
          case 4:
            setTimeout(() => {
              dispatch('checkDownloadStatus');
            }, 15000);
            return;
          case 2:
            dispatch('setDownload', {
              download: {
                url: downloadFileName,
                status: 'SUCCESS',
              },
            });
            return;
          case 3:
          default:
            throw new Error(errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch('clearDownload');
      });
  },
  clearDownload({ dispatch }) {
    dispatch('setDownload', {
      willReset: true,
    });
  },
  checkUploadStatus({ dispatch, getters }) {
    let upload = getters.upload;
    if (!upload?.taskId || ['ERROR', 'SUCCESS'].includes(upload.status)) return;
    api.bearer
      .post(
        { taskId: upload.taskId, templateType: upload.templateType },
        `reimbursement/template-task-status`
      )
      .then((response) => {
        const {
          data: {
            ApiStatusMessage,
            ApiStatusCode,
            Data: {
              uploadSkuCount,
              errorSkuCount,
              successfulSkuCount,
              errorFileName,
              status,
              errorMessage,
              deleteSkuCount,
            },
          },
        } = response;
        if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
        switch (status) {
          case 0:
          case 1:
          case 4:
            dispatch('setUpload', {
              upload: {
                processProgress:
                  upload.processProgress < 80
                    ? upload.processProgress + 10
                    : 90,
              },
            });
            setTimeout(() => {
              dispatch('checkUploadStatus');
            }, 15000);
            break;
          case 2:
            dispatch('setUpload', {
              upload: {
                processProgress: 100,
                status: 'SUCCESS',
                message: ApiStatusMessage,
                uploadSkuCount,
                errorSkuCount,
                deleteSkuCount,
                successfulSkuCount,
                url: errorFileName,
              },
            });
            break;
          case 3:
            dispatch('setUpload', {
              upload: {
                processProgress: 100,
                status: 'ERROR',
                message: errorMessage,
              },
            });
            break;
          default:
            throw new Error('Invalid status returned from upload task.');
        }
      })
      .catch((error) => {
        dispatch('setUpload', {
          upload: {
            status: 'ERROR',
            message: error.message,
          },
        });
      });
  },
  clearUploadTask({ dispatch }) {
    dispatch('setUpload', {
      willReset: true,
    });
  },
  setDownload({ commit, rootGetters }, { download, willReset = false }) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_DOWNLOAD', { evaStoreId, download, willReset });
  },
  setUpload({ commit, rootGetters }, { upload, willReset = false }) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_UPLOAD', { evaStoreId, upload, willReset });
  },
  async downloadTemplate({ dispatch }) {
    try {
      dispatch('setDownload', {
        download: {
          ...initialDownloadState,
          status: 'PENDING',
        },
      });
      const response = await api.bearer.post(
        {
          'query.pageSize': 75,
          'query.page': 1,
          'query.filter.contains.keyword': '',
        },
        `reimbursement/download-product-dimension-list`
      );
      const {
        ApiStatus,
        ApiStatusMessage,
        Data: { UUID },
      } = response.data;
      if (!ApiStatus) throw new Error(ApiStatusMessage);
      dispatch('setDownload', {
        download: {
          taskId: UUID,
          requestTime: new Date(),
        },
      });
      dispatch('checkDownloadStatus');
    } catch (error) {
      console.log(error);
      dispatch('clearDownload');
    }
  },
  async uploadTemplate({ dispatch }, { file }) {
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.open(
      'POST',
      `${process.env.VUE_APP_SERVICE_URL}reimbursement/upload-product-dimension-list`,
      true
    );
    request.setRequestHeader(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With'
    );
    request.setRequestHeader('Access-Control-Allow-Origin', '*');
    request.setRequestHeader(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );
    dispatch('setUpload', {
      upload: {
        ...initialUploadState,
        status: 'PENDING',
        message: 'Your file is being uploaded. Please wait...',
      },
    });
    request.onreadystatechange = async () => {
      if (request.readyState === 4 && request.status === 200) {
        let { ApiStatus, ApiStatusMessage, Data } = JSON.parse(
          request.response
        );
        if (!ApiStatus) {
          dispatch('setUpload', {
            upload: {
              status: 'ERROR',
              message: ApiStatusMessage,
              uploadProgress: 0,
              processProgress: null,
            },
          });
        } else {
          dispatch('setUpload', {
            upload: {
              taskId: Data.UUID,
              uploadProgress: 100,
              processProgress: 0,
              message: 'Upload successful. Processing file.',
            },
          });
          dispatch('checkUploadStatus');
        }
      }
    };
    formData.append('marketplace', localStorage.getItem('marketplace'));
    formData.append('sellerId', localStorage.getItem('store'));
    formData.append('templateType', '0');
    formData.append('file', file);
    request.send(formData);
    request.onprogress = (e) => {
      if (e.lengthComputable) {
        const percent = Math.round((e.loaded / e.total) * 100);
        dispatch('setUpload', {
          upload: {
            uploadProgress: percent < 100 ? percent : 99,
          },
        });
      }
    };
  },
};
