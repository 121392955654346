export default {
  SET_ACOS_ROAS_SELECT(state, payload) {
    state.acosRoasSelect = payload;
  },
  SET_TACOS_TACOP_TPRAS_SELECT(state, payload) {
    state.tacosTacopTprasSelect = payload;
  },
  SET_ADVERTISING_SALES_ADVERTISING_ORDERS_SELECT(state, payload) {
    state.advertisingSalesAdvertisingOrdersSelect = payload;
  },
  SET_ORGANIC_SALES_ORGANIC_ORDERS_SELECT(state, payload) {
    state.organicSalesOrganicOrdersSelect = payload;
  },
  SET_TOTAL_SALES_TOTAL_ORDERS_SELECT(state, payload) {
    state.totalSalesTotalOrdersSelect = payload;
  },
};
