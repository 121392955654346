import moment from 'moment';
export default {
  postpone({ commit }, payload) {
    commit('SET_POSTPONED', true);
    commit('SET_POSTPONED_AT', moment().toString());
    commit('SET_POSTPONED_FOR_MINUTES', payload.minutes);
    commit('SET_POSTPONED_FOR_STORE', payload.store);
    commit('SET_POSTPONED_FOR_MARKETPLACE', payload.marketplace);
    localStorage.setItem(
      'renewTokenPostpone',
      JSON.stringify({
        postponed: true,
        postponedAt: moment().toString(),
        postponedForMinutes: payload.minutes,
        postponedForStore: payload.store,
        postponedForMarketplace: payload.marketplace,
      })
    );
  },
  resetPostponed({ commit }) {
    commit('SET_POSTPONED', false);
    commit('SET_POSTPONED_AT', null);
    commit('SET_POSTPONED_FOR_MINUTES', null);
    commit('SET_POSTPONED_FOR_STORE', null);
    commit('SET_POSTPONED_FOR_MARKETPLACE', null);
    localStorage.removeItem('renewTokenPostpone');
  },
};
