/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import auth from '@/auth/authService';
import localForage from 'localforage';

export default {
  isUserLoggedIn: async () => {
    let isAuthenticated = false;
    if (auth.isAuthenticated()) isAuthenticated = true;
    else isAuthenticated = false;
    let userInfo = await localForage.getItem('userInfo');
    return userInfo && isAuthenticated;
  },
  desiredRoute: null,
  lastAuthorizedPage: null,
};
