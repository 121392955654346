export default {
  removal_order_id: 'Removal Order ID',
  search_for: 'Search for Removal Order ID/SKU/FNSKU/ASIN',
  order_type: '@:general.terms.order @:general.terms.type',
  disposal: 'Disposal',
  return: 'Return',
  liquidations: 'Liquidations',
  grade_and_relist: 'Grade and Relist',
  order_status: '@:general.terms.order @:general.terms.status',
  cancelled: 'Cancelled',
  completed: 'Completed',
  pending: 'Pending',
  delivered: 'Delivered',
  received: 'Received',
  last_day: 'Last @:general.date.day (@:general.date.yesterday)',
  requested: 'Requested',
  field_qty: (ctx) => `${ctx.named('field')} Qty`,
  detail: 'Detail',
  item_condition: 'Item Condition',
  ordered: 'Ordered',
  shipped: 'Shipped',
  disposed: 'Disposed',
  sellable: 'Sellable',
  damaged: 'Damaged',
  refurbished: 'Refurbished',
  unsellable: 'Unsellable',
  please_wait: '@:general.terms.please_wait Your data will be ready shortly.',
};
