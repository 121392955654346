const initialDownloadState = {
  taskId: null,
  templateType: 2,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};
const initialUploadState = {
  taskId: null,
  templateType: 2,
  status: 'IDLE',
  message: null,
  uploadSkuCount: null,
  errorSkuCount: null,
  successfulSkuCount: null,
  deleteSkuCount: null,
  url: null,
  uploadProgress: 0,
  processProgress: 0,
};
export default {
  shopifyDownload: ({ download: { shopify } }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return shopify[evaStoreId] ?? initialDownloadState;
  },
  walmartDownload: ({ download: { walmart } }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return walmart[evaStoreId] ?? initialDownloadState;
  },
  download: (state, getters) => (marketplace) => {
    if (!['shopify', 'walmart', 'shipstation'].includes(marketplace))
      throw new Error(
        'Invalid marketplace provided to download getter. Valid marketplaces are: ["shopify", "walmart"]'
      );
    return getters[`${marketplace}Download`];
  },
  shopifyUpload: ({ upload: { shopify } }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return shopify[evaStoreId] ?? initialUploadState;
  },
  walmartUpload: ({ upload: { walmart } }, _, __, rootGetters) => {
    const evaStoreId = rootGetters.getEvaStoreId;
    return walmart[evaStoreId] ?? initialUploadState;
  },
  upload: (state, getters) => (marketplace) => {
    if (!['shopify', 'walmart', 'shipstation'].includes(marketplace))
      throw new Error(
        'Invalid marketplace provided to upload getter. Valid marketplaces are: ["shopify", "walmart"]'
      );
    return getters[`${marketplace}Upload`];
  },
  shopifyUpdatingStore: ({ marketplaces: { shopify } }) => {
    return shopify?.updatingStore ?? null;
  },
  walmartUpdatingStore: ({ marketplaces: { walmart } }) => {
    return walmart?.updatingStore ?? null;
  },
  shipstationUpdatingStore: ({ marketplaces: { shipstation } }) => {
    return shipstation?.updatingStore ?? null;
  },
  shopifyStores: ({ marketplaces: { shopify } }) => {
    return shopify?.stores ?? [];
  },
  walmartStores: ({ marketplaces: { walmart } }) => {
    return walmart?.stores ?? [];
  },
  shipstationStores: ({ marketplaces: { shipstation } }) => {
    return shipstation?.stores ?? [];
  },
  stores: (state, getters) => (marketplace) => {
    if (!['shopify', 'walmart', 'shipstation'].includes(marketplace))
      throw new Error(
        'Invalid marketplace provided to stores getter. Valid marketplaces are: ["shopify", "walmart"]'
      );
    return getters[`${marketplace}Stores`];
  },
  storesNotDeleted: (state, getters) => (marketplace) => {
    if (!['shopify', 'walmart', 'shipstation'].includes(marketplace))
      throw new Error(
        'Invalid marketplace provided to stores getter. Valid marketplaces are: ["shopify", "walmart"]'
      );
    return getters[`${marketplace}Stores`].filter(
      (connection) => connection.connectionStatus !== 2
    );
  },
  updatingStore: (state, getters) => (marketplace) => {
    if (!['shopify', 'walmart', 'shipstation'].includes(marketplace))
      throw new Error(
        'Invalid marketplace provided to updatingStore getter. Valid marketplaces are: ["shopify", "walmart"]'
      );
    return getters[`${marketplace}UpdatingStore`];
  },
};
