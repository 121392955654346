"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cancellationReasonTypes = exports.reminderBanner = void 0;
exports.reminderBanner = {
    reimbursement: {
        title: '<p class="font-bold underline text-[14px]">Exclusive Offer for reimbursement-only customers!</p>',
        radioTitle: '<p>Add Profit Analytics for <span class="font-bold">$25/month</span> (Essential Plan) and get FBA Reimbursement fees of <span class="font-bold underline">11% commission rate</span></p>',
        radioText: '<p class="italic">Why? Because the FBA Reimbursement fee is 15% when purchased standalone. Start growing your business profitably today.</p>',
    },
    platform: {
        title: '<p class="font-bold underline text-[14px]">Exclusive Offer for platform-only customers!</p>',
        radioTitle: '<p>Add "FBA Reimbursement" for a reduced fee of <span class="font-bold underline">11% commission rate</span></p>',
        radioText: '<p class="italic">Eva has the lowest recovery fees, a 98% success rate, and finds 20% more elligible claims than competitors. Start reinvesting those dollars today.</p>',
    },
};
exports.cancellationReasonTypes = {
    FINANCIAL: {
        key: 1,
        label: 'Financial Issues',
    },
    NO_NEED: {
        key: 2,
        label: 'No product will be used',
    },
    ACCOUNT_SUSPENSION: {
        key: 3,
        label: 'Account Suspension',
    },
    DISSATISFACTION: {
        key: 4,
        label: 'Dissatisfaction',
    },
    COMPETITION: {
        key: 5,
        label: 'Competition',
    },
    ACQUIOSITION: {
        key: 7,
        label: 'Acquisition',
    },
    OUT_OF_BUSINNESS: {
        key: 8,
        label: 'Out of Business',
    },
    OTHER: {
        key: 6,
        label: 'Other',
    },
};
