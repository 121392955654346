export default {
  SET_SHIPSTATION_INFO(state, payload) {
    state.shipstationInfo = payload;
  },

  CLEAR_SHIPSTATION_INFO(state) {
    state.shipstationInfo = {
      storeCode: '',
      apiKey: '',
      apiSecret: '',
    };
  },
  SET_SELECTED_STORES(state, payload) {
    state.selectedStores = payload;
  },

  CLEAR_PAIRING_WAREHOUSES(state) {
    state.pairingWarehouses = [];
  },
  SET_PAIRING_WAREHOUSES(state, payload) {
    state.pairingWarehouses = payload;
  },

  CLEAR_PAIRING_STORES(state) {
    state.pairingStores = [];
  },
};
