export default {
  setActiveStore({ commit }, payload) {
    commit('SET_ACTIVE_STORE', payload);
  },
  setSelectedDate({ commit }, payload) {
    commit('SET_SELECTED_DATE', payload);
  },
  setComponentOrder({ commit }, payload) {
    commit('SET_COMPONENT_ORDER', payload);
  },
  setConnectAccountRequested({ commit }, payload) {
    commit('SET_CONNECT_ACCOUNT_REQUESTED', payload);
  },
  setWalmartInfoCardStatus({ commit }, payload) {
    commit('SET_WALMART_INFO_CARD_STATUS', payload);
  },
  setWalmartInfoRealTimeStatus({ commit }, payload) {
    commit('SET_WALMART_INFO_REAL_TIME_STATUS', payload);
  },
  setHiddenSeries({ commit }, payload) {
    if (payload.isHidden) {
      commit('ADD_HIDDEN_SERIES', payload);
    } else {
      commit('REMOVE_HIDDEN_SERIES', payload);
    }
  },
  setAdvertisingCampaign({ commit }, payload) {
    commit('SET_ADVERTISING_CAMPAIGN', payload);
  },
  setAdvertisingAdGroup({ commit }, payload) {
    commit('SET_ADVERTISING_AD_GROUP', payload);
  },
  setAdvertisingAdItemList({ commit }, payload) {
    commit('SET_ADVERTISING_AD_ITEM_LIST', payload);
  },
  setAdvertisingKeywords({ commit }, payload) {
    commit('SET_ADVERTISING_KEYWORDS', payload);
  },
  changeActiveTab({ commit }, payload) {
    commit('SET_ACTIVE_TAB', payload);
  },
  selectedCampaign({ commit }, payload) {
    commit('SET_SELECT_CAMPAIGN', payload);
  },
  selectedAdGroup({ commit }, payload) {
    commit('SET_SELECT_AD_GROUP', payload);
  },
  setAdvertisingSBProfiles({ commit }, payload) {
    commit('SET_ADVERTISING_SB_PROFILES', payload);
  },
  setAdvertisingKeywordList({ commit }, payload) {
    commit('SET_ADVERTISING_KEYWORD_LIST', payload);
  },
  setAnalyticsChartData({ commit }, payload) {
    commit('SET_ANALYTICS_CHART_DATA', payload);
  },
};
