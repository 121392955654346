import Vue from 'vue';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import router from '@/router';
import localForage from 'localforage';
Vue.use(AclInstaller);

const getRole = async () => {
  let initialRole = 'admin';
  const userInfo = await localForage.getItem('userInfo');
  if (userInfo && userInfo.userRole) initialRole = userInfo.userRole;
  return initialRole;
};

export default async () =>
  new AclCreate({
    initial: await getRole(),
    notfound: '/pages/not-authorized',
    router,
    acceptLocalRules: true,
    globalRules: {
      admin: new AclRule('admin').generate(),
      editor: new AclRule('editor').or('admin').generate(),
    },
  });
