import moment from 'moment';
import { notAssigned } from '@/helper/index.js';
export default {
  activeStore: notAssigned(localStorage.getItem('activeWalmartStore'))
    ? null
    : JSON.parse(localStorage.getItem('activeWalmartStore')),
  selectedDate: 30,
  lastDataAvailableDate: moment().format('YYYY-MM-DD'),
  componentOrder: JSON.parse(localStorage.getItem('walmartComponentOrder')) || [
    1, 2, 3, 4,
  ],
  isConnectAccountRequested: false,
  walmartInfoCardStatus: localStorage.getItem('walmartInfoCardStatus') || false,
  walmartInfoRealTimeStatus:
    localStorage.getItem('walmartInfoRealTimeStatus') || false,
  hiddenSeries: {
    salesProfit: [],
    salesTraffic: [],
    salesInventory: [],
    realTimeSales: [],
    shippedRevenue: [],
    orderRevenue: [],
    manufacturingSourcing: [],
  },
  advertisingCampaign: [],
  advertisingAdGroup: [],
  advertisingSBProfiles: [],
  advertisingAdItemList: [],
  advertisingKeywords: [],
  advertisingKeywordList: [],
  activeTab: 'campaign',
  selectedCampaign: null,
  selectedAdGroup: null,
  analyticsChartData: null,
};
