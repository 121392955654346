export default {
  downloadTemplate: ({ download: { status, message, url } }) => {
    return {
      status,
      message,
      url,
    };
  },
  uploadTemplate: ({
    upload: {
      status,
      message,
      uploadSkuCount,
      errorSkuCount,
      successfulSkuCount,
      url,
    },
  }) => {
    return {
      status,
      message,
      uploadSkuCount,
      errorSkuCount,
      successfulSkuCount,
      url,
    };
  },
};
