export default {
  SET_SIMILAR_PRODUCT_DOWNLOAD_TASK(state, payload) {
    state.similarProductDownloadTask = {
      ...state.similarProductDownloadTask,
      ...payload,
    };
    localStorage.setItem(
      'similarProductDownloadTask',
      JSON.stringify(state.similarProductDownloadTask)
    );
  },
  SET_SIMILAR_PRODUCT_UPLOAD_TASK(state, payload) {
    state.similarProductUploadTask = {
      ...state.similarProductUploadTask,
      ...payload,
    };
    localStorage.setItem(
      'similarProductUploadTask',
      JSON.stringify(state.similarProductUploadTask)
    );
  },
  CLEAR_SIMILAR_PRODUCT_DOWNLOAD_AND_UPLOAD_TASK(state) {
    state.similarProductDownloadTask = {};
    state.similarProductUploadTask = {};
    localStorage.removeItem('similarProductDownloadTask');
    localStorage.removeItem('similarProductUploadTask');
  },
};
