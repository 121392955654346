import api from '@/api';
const initialDownloadState = {
  taskId: null,
  templateType: 2,
  requestTime: null,
  status: 'IDLE',
  message: null,
  url: null,
};
const initialUploadState = {
  taskId: null,
  templateType: 2,
  status: 'IDLE',
  message: null,
  uploadSkuCount: null,
  errorSkuCount: null,
  successfulSkuCount: null,
  deleteSkuCount: null,
  url: null,
  uploadProgress: 0,
  processProgress: 0,
};
export default {
  checkDownloadStatus({ dispatch, getters }, { marketplace }) {
    if (!['shopify', 'walmart'].includes(marketplace))
      throw new Error(
        'Invalid marketplace provided to checkDownloadStatus action. Valid marketplaces are: ["shopify", "walmart"]'
      );
    const download = getters.download(marketplace);

    if (!download?.taskId || ['ERROR', 'SUCCESS'].includes(download.status))
      return;
    api.bearer
      .post(
        { taskId: download.taskId, templateType: download.templateType },
        `${marketplace}-repricer/template-task-status`
      )
      .then((response) => {
        const {
          data: {
            ApiStatusCode,
            ApiStatusMessage,
            Data: { status, errorMessage, downloadFileName },
          },
        } = response;
        if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
        switch (status) {
          case 0:
          case 1:
          case 4:
            setTimeout(() => {
              dispatch('checkDownloadStatus', {
                marketplace,
              });
            }, 15000);
            return;
          case 2:
            dispatch('setDownload', {
              marketplace,
              download: {
                url: downloadFileName,
                status: 'SUCCESS',
              },
            });
            return;
          case 3:
          default:
            throw new Error(errorMessage);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch('clearDownload', {
          marketplace,
        });
      });
  },
  clearDownload({ dispatch }, { marketplace }) {
    dispatch('setDownload', {
      marketplace,
      willReset: true,
    });
  },
  checkUploadStatus({ dispatch, getters }, { marketplace }) {
    if (!['shopify', 'walmart'].includes(marketplace))
      throw new Error(
        'Invalid marketplace provided to checkUploadStatus action. Valid marketplaces are: ["shopify", "walmart"]'
      );
    let upload = getters.upload(marketplace);
    if (!upload?.taskId || ['ERROR', 'SUCCESS'].includes(upload.status)) return;
    api.bearer
      .post(
        { taskId: upload.taskId, templateType: upload.templateType },
        `${marketplace}-repricer/template-task-status`
      )
      .then((response) => {
        const {
          data: {
            ApiStatusMessage,
            ApiStatusCode,
            Data: {
              uploadSkuCount,
              errorSkuCount,
              successfulSkuCount,
              errorFileName,
              status,
              errorMessage,
              deleteSkuCount,
            },
          },
        } = response;
        if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
        switch (status) {
          case 0:
          case 1:
          case 4:
            dispatch('setUpload', {
              marketplace,
              upload: {
                processProgress:
                  upload.processProgress < 80
                    ? upload.processProgress + 10
                    : 90,
              },
            });
            setTimeout(() => {
              dispatch('checkUploadStatus', {
                marketplace,
              });
            }, 15000);
            break;
          case 2:
            dispatch('setUpload', {
              marketplace,
              upload: {
                processProgress: 100,
                status: 'SUCCESS',
                message: ApiStatusMessage,
                uploadSkuCount,
                errorSkuCount,
                deleteSkuCount,
                successfulSkuCount,
                url: errorFileName,
              },
            });
            break;
          case 3:
            dispatch('setUpload', {
              marketplace,
              upload: {
                processProgress: 100,
                status: 'ERROR',
                message: errorMessage,
              },
            });
            break;
          default:
            throw new Error('Invalid status returned from upload task');
        }
      })
      .catch((error) => {
        dispatch('setUpload', {
          marketplace,
          upload: {
            status: 'ERROR',
            message: error.message,
          },
        });
      });
  },
  clearUploadTask({ dispatch }, { marketplace }) {
    dispatch('setUpload', {
      marketplace,
      willReset: true,
    });
  },
  setDownload(
    { commit, rootGetters },
    { marketplace, download, willReset = false }
  ) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_DOWNLOAD', { marketplace, evaStoreId, download, willReset });
  },
  setUpload(
    { commit, rootGetters },
    { marketplace, upload, willReset = false }
  ) {
    const evaStoreId = rootGetters.selectedStore?.evaStoreId;
    commit('SET_UPLOAD', { marketplace, evaStoreId, upload, willReset });
  },
  async downloadMarketplaceTemplate({ dispatch }, { marketplace }) {
    if (!['walmart', 'shopify'].includes(marketplace)) return;
    try {
      dispatch('setDownload', {
        marketplace,
        download: {
          ...initialDownloadState,
          status: 'PENDING',
        },
      });
      const response = await api.bearer.post(
        {},
        `${marketplace}-repricer/download-sku-list`
      );
      const {
        ApiStatusCode,
        ApiStatusMessage,
        Data: { UUID },
      } = response.data;
      if (ApiStatusCode !== 200) throw new Error(ApiStatusMessage);
      dispatch('setDownload', {
        marketplace,
        download: {
          taskId: UUID,
          requestTime: new Date(),
        },
      });
      dispatch('checkDownloadStatus', {
        marketplace,
      });
    } catch (error) {
      console.log(error);
      dispatch('clearDownload', {
        marketplace,
      });
    }
  },
  async uploadMarketplaceTemplate({ dispatch }, { marketplace, file }) {
    if (!['walmart', 'shopify'].includes(marketplace)) return;
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.open(
      'POST',
      `${process.env.VUE_APP_SERVICE_URL}${marketplace}-repricer/upload-sku-list`,
      true
    );
    request.setRequestHeader(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With'
    );
    request.setRequestHeader('Access-Control-Allow-Origin', '*');
    request.setRequestHeader(
      'Authorization',
      'Bearer ' + localStorage.getItem('token')
    );
    dispatch('setUpload', {
      marketplace,
      upload: {
        ...initialUploadState,
        status: 'PENDING',
        message: 'Your file is being uploaded. Please wait...',
      },
    });
    request.onreadystatechange = async () => {
      if (request.readyState === 4 && request.status === 200) {
        let { ApiStatus, ApiStatusCode, ApiStatusMessage, Data } = JSON.parse(
          request.response
        );
        if (!ApiStatus) {
          dispatch('setUpload', {
            marketplace,
            upload: {
              status: 'ERROR',
              message: ApiStatusMessage,
              uploadProgress: 0,
              processProgress: null,
            },
          });
        } else if (ApiStatusCode !== 200) {
          dispatch('setUpload', {
            marketplace,
            upload: {
              status: 'ERROR',
              message: 'Something went wrong. Please try again later.',
              uploadProgress: 0,
              processProgress: null,
            },
          });
        } else {
          dispatch('setUpload', {
            marketplace,
            upload: {
              taskId: Data.UUID,
              uploadProgress: 100,
              processProgress: 0,
              message: 'Upload successful. Processing file.',
            },
          });
          dispatch('checkUploadStatus', {
            marketplace,
          });
        }
      }
    };
    formData.append('marketplace', localStorage.getItem('marketplace'));
    formData.append('sellerId', localStorage.getItem('store'));
    formData.append('templateType', '2');
    formData.append('updateFile', file);
    request.send(formData);
    request.onprogress = (e) => {
      if (e.lengthComputable) {
        const percent = Math.round((e.loaded / e.total) * 100);
        dispatch('setUpload', {
          marketplace,
          upload: {
            uploadProgress: percent < 100 ? percent : 99,
          },
        });
      }
    };
  },
  updateStore({ commit }, { marketplace, oStore }) {
    commit('SET_UPDATING_STORE', {
      marketplace,
      oStore,
    });
  },
  async getMarketplaceStore({ commit, rootGetters }, { marketplace }) {
    if (!['walmart', 'shopify', 'shipstation'].includes(marketplace)) return;
    try {
      const url =
        marketplace === 'shipstation'
          ? 'supply-chain/3pl/shipstation-store/get-store-list'
          : `${marketplace}-store/get-store-list`;
      const response = await api.bearer.post(
        {
          storeCode:
            marketplace === 'shipstation'
              ? rootGetters.selectedStore?.evaStoreId
              : null,
        },
        url
      );
      const { ApiStatus, Data: stores } = response.data;
      if (!ApiStatus)
        throw new Error(
          `Something went wrong while fetching ${marketplace} Stores. Please try again later...`
        );
      commit('SET_STORES', {
        marketplace,
        stores: stores.map((store) => ({
          ...store,
          id:
            marketplace === 'shopify'
              ? store.shopifyShopName
              : marketplace === 'walmart'
              ? `${store.walmartMarketName}-${store.walmartPartnerId}`
              : `${store.evaStoreCode}-${store.evaWarehouseCode}-${store.shipstationStoreCode}-${store.shipstationWarehouseCode}`,
        })),
      });
    } catch (error) {
      commit('SET_STORES', {
        marketplace,
        stores: [],
      });
      console.log(error);
    }
  },
  async getMarketplaceStores({ dispatch }) {
    await dispatch('getMarketplaceStore', { marketplace: 'shopify' });
    await dispatch('getMarketplaceStore', { marketplace: 'walmart' });
    await dispatch('getMarketplaceStore', { marketplace: 'shipstation' });
  },
  async changeMarketplaceStatus({ commit, getters }, { marketplace }) {
    if (!['walmart', 'shopify', 'shipstation'].includes(marketplace)) return;
    try {
      const url =
        marketplace === 'shipstation'
          ? 'supply-chain/3pl/shipstation-store/update-shipstation-store-connection-status'
          : `${marketplace}-store/update-${marketplace}-store-connection-status`;
      const response = await api.bearer.post(
        {
          ...getters['updatingStore'](marketplace),
        },
        url
      );
      const { ApiStatus } = response.data;
      if (!ApiStatus)
        throw new Error(
          `Something went wrong while disconnecting store from ${marketplace} Stores. Please try again later...`
        );
      commit('UPDATE_STORE', {
        marketplace,
        oStore: {
          ...getters['updatingStore'](marketplace),
        },
      });
      commit('SET_UPDATING_STORE', {
        marketplace,
        oStore: null,
      });
    } catch (error) {
      console.log(error);
      throw new Error(
        `Something went wrong while disconnecting store from ${marketplace} Stores. Please try again later...`
      );
    }
  },
};
