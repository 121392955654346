<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <div id="appDiv">
      <router-view @setAppClasses="setAppClasses" />
    </div>
    <RenewTokenPrompt
      :active="
        shouldRenewToken &&
        !isRenewTokenPostponed &&
        !isRenewingToken &&
        !isLinkAccountPage
      "
      @cancel="cancelRenewToken"
      @confirm="goToSellerCentral"
      :title="lang('general.prompt.renew_token.title')"
      maxWidth="400px"
      icon="warning"
      :message="lang('general.prompt.renew_token.hint')"
      confirmButtonIcon="external"
      :confirmButtonText="lang('general.prompt.renew_token.confirm_text')"
      :cancelButtonText="lang('general.prompt.renew_token.cancel_text')"
      :animated="true"
      :animation-duration="250"
      animation-type="fade"
      :closeable="hasCancelRenewToken"
      :hasCancelButton="hasCancelRenewToken"
    >
    </RenewTokenPrompt>
    <RenewTokenPrompt
      :active="
        shouldRenewVendorToken &&
        !isRenewVendorTokenPostponed &&
        !isRenewingVendorToken
      "
      @cancel="cancelRenewToken"
      @confirm="goToVendorCentral"
      :title="lang('general.prompt.renew_token_vendor.title')"
      maxWidth="400px"
      icon="warning"
      :message="lang('general.prompt.renew_token_vendor.hint')"
      confirmButtonIcon="external"
      :confirmButtonText="
        lang('general.prompt.renew_token_vendor.confirm_text')
      "
      :cancelButtonText="lang('general.prompt.renew_token_vendor.cancel_text')"
      :animated="true"
      :animation-duration="250"
      animation-type="fade"
      :closeable="hasCancelRenewVendorToken"
      :hasCancelButton="hasCancelRenewVendorToken"
    >
    </RenewTokenPrompt>
    <MaintenanceBanner />
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js';
import jwt from '@/http/requests/auth/jwt/index.js';
import MaintenanceBanner from '@/components/utility/MaintenanceBanner';
import 'vue2-datepicker/index.css';
import localForage from 'localforage';
import RenewTokenPrompt from '@/components/utility/Prompt.vue';
import moment from 'moment';
import marketplacesCurrency from '@/json/marketplacesCurrency';
import marketplaceList from '@/json/marketplaceList';
import { adminDashboardOnlyAccountPermissions } from '@/constants/pages.ts';

export default {
  metaInfo() {
    return {
      title: 'Amazon Solution Provider Network',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Eva provides the best solutions for Amazon Sellers; easy setup, 24/7 Live Support, affordable prices. Sign up for a 15-day free trial for the fastest Amazon Repricer.',
        },
      ],
    };
  },
  data() {
    return {
      vueAppClasses: [],
      pathName: '',
      isUsed: true,
      trialMinutes: null,
      readOnlyPromp: false,
    };
  },
  components: {
    MaintenanceBanner,
    RenewTokenPrompt,
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val);
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr');
    },
    $route(val) {
      const store = val.query.store;
      const marketplace = val.query.marketplace;
      let userInfo = this.$store.getters['userInfo/uInfo'];
      let storeArray;
      if (userInfo && userInfo.user) {
        storeArray = userInfo.user.store;
      }
      let screenPermissionList = [];
      if (storeArray && storeArray.length > 0) {
        for (let i in storeArray) {
          if (
            store === storeArray[i].storeId &&
            marketplace === storeArray[i].marketplaceName
          ) {
            for (let j in storeArray[i].screenPermissionList) {
              screenPermissionList.push(
                storeArray[i].screenPermissionList[j].PermissionCode
              );
            }
          }
        }
      }

      if (
        screenPermissionList.length > 0 &&
        !screenPermissionList.includes(val.meta.permissionName) &&
        val.path !== '/no-permissions'
      ) {
        location.reload();
      }
      const queryString = window.location.pathname;
      this.pathName = queryString;
      if (this.pathName == '/login') {
        let body = document.body;
        body.classList.remove('create-first-marketplace');
      }
      if (
        window.location.hostname == 'iapp.eva.guru' ||
        window.location.hostname == 'iapptest.eva.guru'
      ) {
        this.createDataLayer();
      }
      this.checkPostponedRenewTokenPrompt();
    },
    activeUserInfo(val) {
      if (val.readOnly && val.readOnly === true) {
        this.readOnlyPromp = true;
      } else {
        this.readOnlyPromp = false;
      }
    },
    upgradePlan() {
      if (this.upgradePlan == true) {
        this.readOnlyPromp = true;
      } else {
        this.readOnlyPromp = false;
      }
    },
    readOnlyPromp() {
      if (!this.readOnlyPromp) {
        this.$store.dispatch('updateUpgrageYourPlan', false);
      }
    },
  },
  computed: {
    newReimbursementPackageType() {
      return (
        this.$store.getters['packagesAndOrderForm/getReimbursementPackage']
          ?.packageType || null
      );
    },
    isLinkAccountPage() {
      const pageUrl = this.$route;
      if (pageUrl.name === 'link-account') {
        return true;
      }
      this.$store.dispatch(
        'updateTheme',
        localStorage.getItem('themeMode') || 'dark'
      );
      return false;
    },
    activeUserInfo() {
      return this.$store.getters['userInfo/uInfo'];
    },
    isTrial() {
      return this.$store.state.AppActiveStoreTrial;
    },
    upgradePlan() {
      return this.$store.state.upgradeYourPlan;
    },
    selectedStore() {
      return this.$store.getters['selectedStore'];
    },
    shouldRenewToken() {
      if (!this.selectedStore || this.selectedStore?.isVendor) return false;
      let today = moment();
      let tokenCreatedDate = moment(
        this.selectedStore.sellerApiAuthCodeCreatedAt
      );
      let diff = today.diff(tokenCreatedDate, 'months');

      return this.selectedStore.sellerApiTokenStatus || diff >= 11;
    },
    hasCancelRenewToken() {
      return (
        (this.selectedStore && !this.selectedStore.sellerApiTokenStatus) ||
        this.isUserEvaEmployee
      );
    },
    isUserEvaEmployee() {
      const userPermissionList =
        this.activeUserInfo?.user?.userPermissionList ?? [];
      return userPermissionList.some((permission) =>
        adminDashboardOnlyAccountPermissions.includes(permission.PermissionCode)
      );
    },
    isRenewTokenPostponed() {
      return this.$store.getters['renewToken/postponeObject']?.postponed;
    },
    isRenewingToken() {
      return !this.$route.query?.marketplace && !this.$route.query?.store;
    },
    shouldRenewVendorToken() {
      if (!this.selectedStore || !this.selectedStore?.isVendor) return false;
      let today = moment();
      let tokenCreatedDate = moment(
        this.selectedStore.sellerApiAuthCodeCreatedAt
      );
      let diff = today.diff(tokenCreatedDate, 'months');

      return this.selectedStore.sellerApiTokenStatus || diff >= 11;
    },
    hasCancelRenewVendorToken() {
      return this.selectedStore && !this.selectedStore.sellerApiTokenStatus;
    },
    isRenewVendorTokenPostponed() {
      return this.$store.getters['renewToken/postponeObject']?.postponed;
    },
    isRenewingVendorToken() {
      return !this.$route.query?.marketplace && !this.$route.query?.store;
    },
  },
  methods: {
    goToOrderForm() {
      let localStorageTrial = JSON.parse(localStorage.getItem('trial'));
      localStorageTrial.viewOrderForm = true;
      this.$store.dispatch(
        'packagesAndOrderForm/setPackagesAndOrderFormPopupStatus',
        true
      );
      localStorage.setItem('trial', JSON.stringify(localStorageTrial));
      this.$store.dispatch('updateTrialInfo', localStorageTrial);
    },
    createDataLayer() {
      let userInfo = this.$store.getters['userInfo/uInfo'];
      if (userInfo && userInfo.user) {
        const user = userInfo.user;
        const storeId = localStorage.getItem('store');
        const storeName = localStorage.getItem('storeName');
        const marketplace = localStorage.getItem('marketplace');
        const foundMarketplace = marketplacesCurrency.find(
          (element) => marketplace === element.name
        );
        const timeZone = foundMarketplace?.time_zone;
        const timeZoneDiff = foundMarketplace?.time_zone_diff;
        let existingDataLayer = window.dataLayer.findIndex(
          (item) => item.event == 'app_customer'
        );
        if (existingDataLayer && existingDataLayer != -1) {
          window.dataLayer[existingDataLayer] = {
            event: 'app_customer',
            EvaAppCustomer: {
              email: user?.email,
              firstName: user?.firstName,
              lastName: user?.lastName,
              telephoneNumber: user?.telephoneNumber,
              userId: user?.userId,
              storeId: storeId || null,
              storeName: storeName || null,
              marketplace: marketplace || null,
              url: window.location.href,
              loginStatus: user.userId ? 1 : 0,
              time_zone: timeZone,
              time_zone_diff: timeZoneDiff,
            },
          };
        } else {
          window.dataLayer.push({
            event: 'app_customer',
            EvaAppCustomer: {
              email: user?.email,
              firstName: user?.firstName,
              lastName: user?.lastName,
              telephoneNumber: user?.telephoneNumber,
              userId: user?.userId,
              storeId: storeId || null,
              storeName: storeName || null,
              marketplace: marketplace || null,
              url: window.location.href,
              loginStatus: user.userId ? 1 : 0,
              time_zone: timeZone,
              time_zone_diff: timeZoneDiff,
            },
          });
        }
      } else {
        window.dataLayer.push({
          event: 'app_customer',
          EvaAppCustomer: {
            email: 'visitor',
            firstName: 'visitor',
            lastName: 'visitor',
            telephoneNumber: 'visitor',
            userId: 'visitor',
            storeId: 'visitor',
            storeName: 'visitor',
            marketplace: 'visitor',
            url: 'visitor',
            loginStatus: 0,
            time_zone: 'America/Los_Angeles',
            time_zone_diff: '-08:00',
          },
        });
      }
    },
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark');
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);
      // Set --vh property
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
    },
    changetimezone(operationTime) {
      const options = {
        hourCycle: 'h23',
        timeZone: 'America/Los_Angeles',
      };
      let gmtTime = new Date(operationTime);
      const pstTime = gmtTime.toLocaleString('en-US', options);
      return pstTime;
    },
    goToSellerCentral() {
      if (!this.selectedStore?.region) return;
      window.localStorage.setItem(
        'sellerCentral',
        JSON.stringify({
          region: {
            ...[
              { label: 'North America', regionCode: 'na' },
              { label: 'Europe', regionCode: 'eu' },
              { label: 'Far East', regionCode: 'fe' },
            ].find((r) => r.regionCode === this.selectedStore?.region),
          },
          isMerchant: this.selectedStore.storeType,
          countryCode: this.activeUserInfo?.user?.countryCode,
          callingCode: this.activeUserInfo?.user?.callingCode,
          telephoneNumber: this.activeUserInfo?.user?.telephoneNumber,
          timeZone: {
            zone: '',
          },
          formErrors: [null, null],
        })
      );

      localStorage.setItem(
        'linkAccountSelectRegion',
        JSON.stringify({
          ...[
            { label: 'North America', regionCode: 'na' },
            { label: 'Europe', regionCode: 'eu' },
            { label: 'Far East', regionCode: 'fe' },
          ].find((r) => r.regionCode === this.selectedStore?.region),
        })
      );

      localStorage.setItem(
        'linkAccountSelectMarketType',
        JSON.stringify({
          ...[
            {
              id: 1,
              name: 'Brand',
              subtitle: '(Private Label)',
              type: 'Brand',
              description:
                'Control brand presence, direct sales and representation.',
              icon: 'local-mall',
              storeType: 1,
            },
            {
              id: 2,
              name: 'Reseller',
              subtitle: '',
              type: 'Reseller',
              description:
                'Manage diverse resale operations and product offerings.',
              icon: 'storefront',
              storeType: 2,
            },
          ].find((r) => r.storeType === this.selectedStore?.storeType),
        })
      );

      localStorage.setItem(
        'linkAccountSelectMarket',
        JSON.stringify({
          id: 2,
          name: 'Seller',
          type: 'Seller',
          display: true,
          description: 'Connect your Amazon store',
          icon: require('@/assets/images/link-account/Amazon.png'),
        })
      );

      localStorage.setItem(
        'linkAccountUserInfo',
        JSON.stringify({
          displayName: this.selectedStore.storeName,
          country: {
            marketplaceName: localStorage.getItem('marketplace'),
            marketplace: marketplacesCurrency.find(
              (marketPlaceItem) =>
                marketPlaceItem.name === localStorage.getItem('marketplace')
            ).marketplace_id,
          },
          phoneCountryCode: this.activeUserInfo?.user?.countryCode,
          callingCode: this.activeUserInfo?.user?.callingCode,
          telephoneNumber: this.activeUserInfo?.user?.telephoneNumber,
          phoneNumber: this.activeUserInfo?.user?.telephoneNumber,
          nationalNumber: this.activeUserInfo?.user?.telephoneNumber,
        })
      );
      window.location.href =
        this.selectedStore.region == 'eu'
          ? `https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.ebed567c-3685-405f-ba24-f4c410bcd270&state=renew_token:eu:${this.selectedStore.marketplaceName}`
          : this.selectedStore.region == 'fe'
          ? `https://sellercentral.amazon.com.au/apps/authorize/consent?application_id=amzn1.sp.solution.ebed567c-3685-405f-ba24-f4c410bcd270&state=renew_token:fe:${this.selectedStore.marketplaceName}`
          : `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.ebed567c-3685-405f-ba24-f4c410bcd270&state=renew_token:na:${this.selectedStore.marketplaceName}`;
    },
    cancelRenewToken() {
      this.$store.dispatch('renewToken/postpone', {
        minutes: 60 * 24,
        store: this.selectedStore.storeId,
        marketplace: this.selectedStore.marketplaceName,
      });
    },
    checkPostponedRenewTokenPrompt() {
      if (this.isRenewTokenPostponed) {
        let postpone = this.$store.getters['renewToken/postponeObject'];
        let now = moment();
        let postponedAt = moment(postpone.postponedAt);
        let diff = now.diff(postponedAt, 'minutes');
        if (
          diff >= postpone.postponedForMinutes ||
          (postpone.postponedForStore &&
            postpone.postponedForStore !== this.selectedStore?.storeId) ||
          (postpone.postponedForMarketplace &&
            postpone.postponedForMarketplace !==
              this.selectedStore?.marketplaceName)
        ) {
          this.$store.dispatch('renewToken/resetPostponed');
        }
      }
    },
    goToVendorCentral() {
      if (!this.selectedStore?.region) return;
      window.localStorage.setItem(
        'vendorRenewToken',
        JSON.stringify({
          storeId: this.selectedStore?.storeId,
          marketplaceName: this.selectedStore?.marketplaceName,
        })
      );
      const vendorUrl = marketplaceList.filter(
        (item) => item.name === this.selectedStore?.marketplaceName
      )?.[0].vendorCentralUrl;
      window.location.href = `${vendorUrl}/apps/authorize/consent?application_id=amzn1.sp.solution.ebed567c-3685-405f-ba24-f4c410bcd270&state=renew_vendor_token:${this.selectedStore?.region}:${this.selectedStore?.marketplaceName}`;
    },
  },
  mounted() {
    const queryString = window.location.pathname;
    this.pathName = queryString;
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  async created() {
    // jwt
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en');
      this.$i18n.locale = 'en';
    } else {
      this.$i18n.locale = localStorage.getItem('lang');
    }
    jwt.init();
    const dir = this.$vs.rtl ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.handleScroll);
    const userInfo = await localForage.getItem('userInfo');
    await this.$store.dispatch('userInfo/loadInfo', userInfo);
    this.checkPostponedRenewTokenPrompt();
  },
  provide() {
    return {
      store: this.$store,
      vs: this.$vs,
      router: this.$router,
      route: this.$route,
      lang: this.lang.bind(this),
    };
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
//html-menu start
.html-menu {
  background: white;
  border: 1px solid rgba(60, 60, 60, 0.26);
  float: left;
  position: relative;
  border-radius: 5px;
  ul {
    position: relative;
    width: 100px;
    float: left;
    li {
      position: relative;
      padding: 6px 8px;
      width: 100%;
      float: left;
      .title-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      ul {
        display: none;
        visibility: hidden;
        opacity: 0;
        height: 0;
        position: absolute;
        z-index: 1;
        background: white;
        right: 0;
        top: 94%;
        width: 100%;
        border-radius: 5px;
        li {
          padding: 6px 8px;
          div.w-full {
            display: flex;
            align-items: center;
            span.feather-icon {
              margin-right: 4px;
            }

            a {
              color: #626262;
            }
          }
          &:hover {
            background-color: #3542ad;
            color: white !important;

            a {
              color: white !important;
            }
          }
        }
      }
      &:hover {
        ul {
          display: block;
          visibility: visible;
          opacity: 1;
          height: auto;
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }
  }

  &:hover {
    ul {
      li {
        ul {
          -webkit-box-shadow: 4px 10px 15px -1px #939393;
          box-shadow: 4px 10px 15px -1px #939393;
        }
      }
    }
  }
}

.theme-dark {
  .html-menu {
    background: #21409a;
    border: none;
    ul {
      li {
        ul {
          background: #21409a;
        }
      }
    }
    &:hover {
      ul {
        li {
          ul {
            -webkit-box-shadow: 4px 10px 15px -1px #000000;
            box-shadow: 4px 10px 15px -1px #000000;
          }
        }
      }
    }
  }
}
.reverse-menu {
  .html-menu {
    ul {
      li {
        ul {
          bottom: 100%;
          top: auto;
        }
      }
    }
    &:hover {
      ul {
        li {
          ul {
            -webkit-box-shadow: 1px -6px 15px 3px #939393;
            box-shadow: 1px -6px 15px 3px #939393;
          }
        }
      }
    }
  }
}

.theme-dark {
  .reverse-menu {
    .html-menu {
      &:hover {
        ul {
          li {
            ul {
              -webkit-box-shadow: 1px -6px 15px 3px #000000;
              box-shadow: 1px -6px 15px 3px #000000;
            }
          }
        }
      }
    }
  }
}
.transparent-tr {
  border: none !important;
  background: #f8f8f8 !important;
  &:hover {
    border: none !important;
    background: #f8f8f8 !important;
  }
}

.theme-dark {
  .transparent-tr {
    border: none !important;
    background: #161a1d !important;
    &:hover {
      border: none !important;
      background: #161a1d !important;
    }
  }
}

.html-menu.same-btn {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgb(0, 153, 255) none repeat scroll 0% 0%;
  ul {
    width: auto;
    li {
      padding: 8px 12px;
      color: white;

      .sub-title-content {
        display: flex;
        align-items: center;
        .feather-icon {
          padding-right: 8px;
        }
      }
      ul {
        z-index: 99999;
        width: 100%;
        background: #21409a;
        li {
          color: white !important;

          a {
            color: white !important;
          }

          &:hover {
            color: white;
          }
        }
      }
    }
  }
}

.theme-dark {
  .html-menu.same-btn {
    ul {
      li {
        color: white !important;
      }
    }
  }
}
//html-menu end

.scroll-modal {
  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 0;
  }

  ::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border: 1px solid #393939;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #393939;
    border-radius: 0;
    //outline: 1px solid slategrey;
  }
  .vs-dialog {
    overflow: auto;
    max-height: 90%;

    &::-webkit-input-placeholder {
      /* Edge */
      color: #161a1d !important;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #161a1d !important;
    }

    &::placeholder {
      color: #161a1d !important;
    }
  }
}

.custom-collapse-simple {
  p {
    float: left;
    width: 100%;
    flex-flow: wrap !important;
    margin-bottom: 8px;
    font-size: 11px;
    display: block !important;
  }

  .vs-collapse-item--header {
    background: transparent !important;
    padding: 0;
    // font-size: 12px;

    div {
      padding-left: 8px;
    }

    .vs-icon {
      background: transparent !important;
      color: #161a1d !important;
    }

    .icon-header {
      right: auto !important;
    }
  }

  .vs-collapse-item--content .con-content--item {
    padding-top: 0 !important;
    //  font-size: 12px;
    font-weight: normal;
  }
}

.theme-dark .repricer-dialog .vs-dialog .vue-select-standard input.vs__search {
  border: transparent !important;
}

.vue-select-standard {
  width: 100% !important;
  min-height: 29px;

  &.v-select .vs__dropdown-toggle {
    height: 100%;
  }

  .vs__clear {
    margin-right: 2px !important;
    display: none !important;
  }

  .vs__selected {
    font-weight: normal;
    font-size: 11px !important;
  }

  .vs__search {
    padding: 0 !important;
    height: 23px;
    font-size: 11px !important;

    ::-webkit-input-placeholder {
      /* Edge */
      font-size: 11px !important;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 11px !important;
    }

    ::placeholder {
      font-size: 11px !important;
    }
  }

  .vs__dropdown-menu {
    max-width: 90px !important;
    width: 90px !important;
    min-width: 90px;
    max-height: 150px !important;

    ::-webkit-input-placeholder {
      /* Edge */
      color: #161a1d !important;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #161a1d !important;
    }

    ::placeholder {
      color: #161a1d !important;
    }

    li {
      font-size: 11px !important;
    }
  }

  &.v-select .vs__dropdown-toggle {
    background: #fff;
    border: 1px solid #161a1d;
    padding: 0 !important;
  }

  .vs__selected {
    margin-top: 0 !important;
  }

  &.vs--single.vs--open .vs__selected {
    position: relative !important;
    margin-top: 0 !important;
  }

  .vs__dropdown-toggle {
    padding: 9.1px 0 !important;
    width: 100% !important;
  }
}

.theme-dark {
  .pr-rounding-selector,
  .pr-twin-sku-selector {
    .vue-select-standard {
      .vs__search {
        color: #21409a !important;
        &::placeholder {
          color: #21409a !important;
        }
      }
    }
  }
  .vue-select-standard {
    width: 100% !important;

    .vs__dropdown-menu {
      max-width: 90px !important;
      width: 90px !important;
      min-width: 90px;
      max-height: 150px !important;

      ::-webkit-input-placeholder {
        /* Edge */
        color: #161a1d !important;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #161a1d !important;
      }

      ::placeholder {
        color: #161a1d !important;
      }
    }

    &.v-select .vs__dropdown-toggle {
      background: #fff;
      border: 1px solid #161a1d;
      padding: 0 !important;
    }

    .vs__selected {
      margin-top: 0 !important;
    }

    &.vs--single.vs--open .vs__selected {
      position: relative !important;
      margin-top: 0 !important;
    }

    .vs__dropdown-toggle {
      padding: 9.1px 0 !important;
      width: 100% !important;
    }

    .vs__search {
      //display: none !important;
      color: #161a1d !important;
      padding-right: 0;
      margin-top: 0;
      height: 27px;
    }

    .vs__search::-webkit-input-placeholder {
      /* Edge */
      color: #161a1d !important;
      font-size: 10px !important;
      opacity: 0.8;
    }

    .vs__search:-ms-input-placeholder {
      /* Internet Explorer */
      color: #161a1d !important;
      font-size: 10px !important;
      opacity: 0.8;
    }

    .vs__search::placeholder {
      color: #161a1d !important;
      font-size: 10px !important;
      opacity: 0.8;
    }

    .vs__clear {
      display: none !important;
    }

    .vs__selected {
      font-size: 11px !important;
      font-weight: normal;
      color: #161a1d !important;
    }

    &.v-select .vs__dropdown-menu .vs__dropdown-option {
      font-size: 12px !important;
      padding-left: 10px;
    }

    &.v-select .vs__dropdown-menu {
      background: #fff;
    }

    &.v-select .vs__dropdown-menu .vs__dropdown-option {
      color: #161a1d;
    }
  }
}

.theme-dark #comepete-chart-import {
  .highcharts-legend {
    g {
      g {
        .highcharts-legend-item:nth-child(1) {
          path:nth-child(2) {
            fill: #ffffff;
          }
        }
        .highcharts-legend-item:nth-child(2) {
          .highcharts-point {
            fill: white !important;
          }
        }
      }
    }
  }
}
#comepete-chart-import {
  .highcharts-legend {
    g {
      g {
        .highcharts-legend-item:nth-child(1) {
          path:nth-child(2) {
            fill: black;
            color: transparent;
            stroke-width: 0px;
          }
          path:nth-child(1) {
            fill: white;
            stroke-width: 0px;
          }
        }
        .highcharts-legend-item {
          path:nth-child(2) {
            fill: rgb(247, 37, 133);
          }
          path:nth-child(1) {
            stroke-width: 3px;
          }
        }
        .highcharts-legend-item:nth-child(2) {
          .highcharts-point {
            fill: black !important;
          }
          .highcharts-graph {
            fill: none;
            display: none;
            stroke-width: 0px;
          }
        }

        .highcharts-legend-item:nth-child(4),
        .highcharts-legend-item:nth-child(5),
        .highcharts-legend-item:nth-child(6),
        .highcharts-legend-item:nth-child(7),
        .highcharts-legend-item:nth-child(8),
        .highcharts-legend-item:nth-child(9),
        .highcharts-legend-item:nth-child(10),
        .highcharts-legend-item:nth-child(11),
        .highcharts-legend-item:nth-child(12),
        .highcharts-legend-item:nth-child(13) {
          .highcharts-point {
            fill: none !important;
          }
        }
      }
    }
  }
  /*  .highcharts-legend g:nth-child(2) path,
    .highcharts-legend g:nth-child(2) .highcharts-point{
      fill: none !important;
    }
    .highcharts-legend g:nth-child(3) path,
    .highcharts-legend g:nth-child(3) .highcharts-point{
      fill: none !important;
    }
    .highcharts-legend g:nth-child(4) path,
    .highcharts-legend g:nth-child(4) .highcharts-point{
      fill: none !important;
    }*/
}
.vs-sidebar:hover {
  z-index: 5000000 !important;
}
.prompt-footer-hidden.con-vs-dialog {
  .vs-dialog {
    footer {
      display: none !important;
    }
  }
}

.no-scroll {
  overflow: hidden !important;
}

.aggregated-custom-filter {
  .menu ul li {
    white-space: normal !important;
  }

  .menu > ul li ul li {
    height: auto !important;
  }
}
.aggregated-custom-filter {
  .menu {
    //margin: 0px 16px 0px 16px;
    position: relative;

    ul {
      z-index: 999;
      list-style: none;
      padding: 8px;
      margin: 0;
      width: 100%;

      li,
      li a {
        color: #626262;
        cursor: pointer;
        transition: 0ms;
        text-decoration: none;
        white-space: nowrap;

        a {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
        }
      }

      li {
        padding-right: 36px;
        width: 100%;

        &::before {
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #626262;
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .link {
        &::before {
          padding-right: 0;
          display: none;
        }
      }
    }

    > ul {
      display: flex;
      height: var(--menu-height);
      align-items: center;
      background-color: transparent;
      border: 1px solid rgba(60, 60, 60, 0.26);
      border-radius: 4px;

      li {
        position: relative;
        margin: 0 8px;
        width: 100%;

        ul {
          visibility: hidden;
          opacity: 0;
          padding: 0;
          min-width: 60px;
          background-color: #fff;
          position: absolute;
          top: calc(var(--menu-height) + 5px);
          border: 1px solid rgba(60, 60, 60, 0.26);
          left: 50%;
          transform: translateX(-50%);
          transition: 0ms;
          transition-delay: 0ms;

          li {
            margin: 0;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 30px;
            padding-right: 40px;
            width: 100%;

            &::before {
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 5px solid #626262;
            }

            ul {
              top: -2%;
              left: 100%;
              transform: translate(0);
            }

            &:hover {
              background-color: #303b98;
              color: #fff;

              &::before {
                border-left: 5px solid #fff;
              }

              a {
                color: white;
              }

              ul {
                li {
                  a {
                    color: #626262;
                  }

                  &:hover {
                    a {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }

        &:hover {
          > ul {
            opacity: 1;
            visibility: visible;
            transition-delay: 0ms;
          }
        }
      }
    }
  }

  .menu > ul li ul li ul {
    width: auto !important;
  }
}

.theme-dark {
  .aggregated-custom-filter {
    .menu {
      background-color: #21272c;

      &.disabled {
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 9;
          background: rgba(0, 0, 0, 0.1);
        }

        .medium-btn {
          opacity: 0.5;
        }

        ul > li:before {
          opacity: 0.5;
        }
      }

      ul {
        li,
        li a {
          color: #fff;
        }

        li {
          &::before {
            border-top-color: #fff;
          }
        }
      }

      > ul {
        background-color: #161a1d;

        li {
          ul {
            background-color: #161a1d;

            li {
              &::before {
                border-left-color: #fff;
                border-top-color: transparent;
              }

              &:hover {
                background-color: #303b98;
              }
            }
          }
        }
      }
    }
  }
}

.replishment-dialog {
  .custom-tooltip-roi {
    width: 100% !important;
    .vs-input {
      width: 80px !important;
    }
  }

  .repeat-input {
    width: 80px !important;
  }
}

.theme-dark #page-login .vx-card input {
  background: #262c49 !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.vs-radio--borde {
  border: 2px solid #32aeff !important;
  width: 97% !important;
  height: 98% !important;
}

.vs-radio--input:checked + .vs-radio .vs-radio--circle {
  background: none !important;
  border: 2px solid #32aeff;
}

.vs-radio--input:checked + .vs-radio .vs-radio--borde {
  opacity: 1 !important;
  transform: scale(0.5);
  z-index: 1;
  background: #32aeff;
}

.buy-box-chart {
  .highcharts-container {
    overflow: inherit !important;
    position: inherit !important;
  }

  .highcharts-label-box.highcharts-tooltip-box {
    fill: transparent !important;
    stroke: transparent !important;
  }

  .highcharts-tooltip {
    span:first-child {
      padding: 2px;
      border-radius: 5px;
      border: 1px solid #ffffff;
      font-size: 10px !important;
    }
  }
}

.theme-dark {
  .upgrade-plan {
    .content-area__content {
      &:before {
        background: rgba(0, 0, 0, 0.4);
      }
    }

    .upgrade-text-content {
      //background: #21409a;
      // color: white;
    }
  }

  .content-area-full {
    @media only screen and (max-width: 1199px) {
      margin-top: 24px !important;
    }
  }
}

.upgrade-plan {
  .content-area__content {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.1);
      z-index: 50998;
      border-radius: 5px;
    }
  }

  .upgrade-text-content {
    display: flex;
    position: fixed;
    left: calc(50% - 160px);
    top: 40%;
    z-index: 50999;
    background: #fff;
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    color: #21409a;
  }
  .upgrade-text-content.reimbursement-upgrade-text {
    left: calc(50% - 275px);
  }
}

.upgrade-text-content {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .upgrade-plan {
    .upgrade-text-content {
      left: calc(50% - 175px);
    }
    .upgrade-text-content.reimbursement-upgrade-text {
      left: 5%;
      width: 90% !important;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .dynamic-w {
    .vs-sidebar {
      max-width: 260px !important;
    }
  }
}

.sidebar-marketplace-select {
  font-size: 0.83rem;

  ul {
    li {
      padding-left: 4px !important;
    }
  }

  .vs__actions {
    width: 20px;
    padding: 0;
  }

  .vs__selected {
    padding: 0;
    margin: 0;
  }

  .vs__dropdown-toggle {
    //align-items: flex-end;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 10px;
    .vs__actions {
      width: 27px;
    }
  }
}

.vue-tooltip.h-tooltip,
.vue-tooltip.tooltip-custom {
  background-color: #323232 !important;
  z-index: 9999999;
  box-shadow: none;
  border-radius: 6px;

  .tooltip-arrow {
    border-top-color: #323232 !important;
    border-bottom-color: #323232 !important;
  }
}

.tooltip-content {
  font-size: 11px;
}

.cards-bg {
  background-color: transparent !important;

  .vx-card__header {
    padding-top: 0.8rem !important;
  }

  .vx-card__action-buttons {
    padding-right: 12px !important;
  }

  .vx-card__body {
    padding-bottom: 0 !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
}

.repricer-page {
  #theme-customizer .customizer-btn {
    z-index: 55999 !important;
  }

  .con-vs-dialog {
    z-index: 51000 !important;

    &.repricer-info-dialog {
      z-index: 51001 !important;
    }
  }

  .alert-light-prompt {
    z-index: 50000 !important;
  }

  .vx-navbar-wrapper {
    z-index: 10000 !important;
  }
}

.alert-light-prompt {
  &.con-vs-dialog .vs-dialog header {
    background-color: #fff !important;
    position: absolute;
    width: auto;
    right: 0;

    .vs-icon {
      background: #fff !important;
      color: #161a1d !important;
    }

    .dialog-title {
      color: #fff !important;
      display: none;
    }
  }

  .vs-dialog {
    background: #fff !important;
  }

  .vs-dialog-text {
    margin: 2rem 1rem;
  }

  .success-prompt {
    display: flex;
    flex-flow: column;

    &:before {
      //content: '';
      position: absolute;
      width: 250px;
      height: 250px;
      border-radius: 100%;
      //background: rgba(221,23,146,.1);
      background-image: linear-gradient(
        180deg,
        #f71b79 0%,
        #e921a3 100%
      ) !important;
      box-shadow: 0px 2px 6px #b1bfc0;
      opacity: 0.1;
      //bottom: 105px;
      margin-top: -150px;
      left: calc(50% - 137px);
    }

    img {
      height: 75px;
      width: auto;
      margin-bottom: 16px;
    }
  }

  .success-prompt-description {
    color: #626262;
  }
}

.daily-sales-chart-content {
  .highcharts-data-label {
    //font-weight: 400 !important;
    font-size: 9px !important;

    tspan {
      //font-weight: 400 !important;
      font-size: 9px !important;
    }
  }
}

.colourful-btn {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
  border-radius: 3px !important;
  transition: background-color 0.5s ease;

  .vs-button-text {
    color: white !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  &.vs-button-primary {
    background-image: linear-gradient(
      180deg,
      #f71b79 0%,
      #e921a3 100%
    ) !important;
    box-shadow: 0px 2px 6px #b1bfc0;
  }

  &.funding {
    padding: 0 !important;
    span {
      line-height: 13px !important;
    }
    .vs-button-text {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      width: 165px;
    }
    &.upgrade-button {
      background-image: linear-gradient(
        180deg,
        #f71b79 0%,
        #e921a3 100%
      ) !important;
      &:hover {
        background: linear-gradient(to right, #004493, #161a1d) !important;
      }
    }
    &.vs-button-primary {
      //background-image: linear-gradient(90deg, #90ED7D 0%, #4D943E 100%) !important;
      background-image: linear-gradient(
        180deg,
        #00dd82 0%,
        #00b449 100%
      ) !important;
      box-shadow: 0px 2px 6px #b1bfc0;
    }
    &:hover {
      span {
        color: white !important;
      }
    }
  }

  &.funding-upgrade {
    padding: 0 !important;
    span {
      line-height: 13px !important;
    }
    .vs-button-text {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      width: 165px;
    }
    &.vs-button-primary {
      background-image: linear-gradient(
        180deg,
        #f71b79 0%,
        #e921a3 100%
      ) !important;
      box-shadow: 0px 2px 6px #b1bfc0;
    }
    &:hover {
      background: linear-gradient(to right, #004493, #161a1d) !important;
      span {
        color: white !important;
      }
    }
  }

  &.menu-in {
    //border-radius: 4px !important;
    //width: 100%;
    //margin-left: 0 !important;
    padding: 0 !important;
    margin: 0 10px !important;
    .vs-button-text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: auto;
    }

    &.small-btn {
      padding: 0 !important;
    }

    svg {
      fill: none !important;
    }
  }

  &.upgrade-btn.vs-button-primary {
    background-image: linear-gradient(
      90deg,
      #90ed7d 0%,
      #4d943e 100%
    ) !important;
    padding: 0.65rem 2rem 0.45rem 2rem !important;

    .vs-button-text {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-top: -4px;
      }
    }
  }

  &:hover {
    background: #fff !important;
    background-image: none !important;

    span {
      color: #21409a !important;
    }
  }

  span {
    font-size: 1.2rem !important;
    line-height: 0;
  }

  &.small-btn {
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    span {
      font-size: 15px !important;
    }
  }
}

.dd-menu {
  min-width: 200px !important;
  padding-top: 10px;
  left: 0% !important;
}

@media only screen and (max-width: 435px) {
  .btn-row {
    font-size: 0.8rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

@media only screen and (max-width: 430px) {
  .placeholder-fontsize {
    .input-span-placeholder,
    th {
      font-size: 0.5rem !important;
      margin-top: 0.4rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .dd-menu {
    left: 50% !important;
  }
}

.vuesax-app-is-ltr .con-vs-dialog .vs-dialog header .vs-dialog-cancel {
  -webkit-transform: translate(0px, 0px) !important;
  transform: translate(0px, 0px) !important;
}

.btn-light {
  background: transparent !important;
  border: 1px solid #21409a !important;
  color: #21409a !important;
  border-radius: 6px;
}

.btn-light.vs-button-primary.vs-button-filled {
  background: transparent !important;
}

.btn-light.menu ul li::before {
  border-top: 5px solid #21409a;
}

.btn-light.menu ul li {
  color: #21409a !important;
}

.menu > ul {
  //border: 0px solid transparent !important;
}

.disabled.btn-light.menu ul li {
  color: #9495cd !important;
  cursor: default !important;
}

.disabled.btn-light.menu ul li::before {
  border-top: 5px solid #9495cd;
}

.disabled.btn-light {
  border: 1px solid #9495cd !important;
  color: #9495cd !important;
}

.disabled .display-disabled {
  display: none !important;
}

.btn-light.menu > ul li ul li:hover {
  color: #fff !important;
}

.con-vs-loading {
  z-index: 10000000000 !important;
}

.theme-dark {
  #inventory-status {
    .highcharts-legend g:nth-child(3) path,
    .highcharts-legend g:nth-child(3) .highcharts-point {
      fill: #ff9655 !important;
    }
  }
}
#compitition-sales-chart {
  .highcharts-series-1 {
    .highcharts-point {
      fill: #ea5f7f;
    }
    .highcharts-legend-item.highcharts-legend-item-hidden {
      .highcharts-point {
        fill: rgb(175, 173, 173) !important;
      }
    }
  }
}

#compitition-sales-chart
  .highcharts-legend
  .highcharts-legend-item-hidden
  .highcharts-point {
  fill: rgb(175, 173, 173) !important;
}
#profit-analytics-import-tab {
  .highcharts-series-2.highcharts-tracker {
    path {
      fill: #ff9233 !important;
    }
  }

  .highcharts-legend g:nth-child(3) path,
  .highcharts-legend g:nth-child(3) .highcharts-point {
    fill: #ff9233 !important;
  }

  .highcharts-legend g:nth-child(3).highcharts-legend-item-hidden {
    .highcharts-point {
      fill: rgb(175, 173, 173) !important;
    }
  }

  .highcharts-legend g g g:nth-child(2) path,
  .highcharts-legend g g g:nth-child(2) .highcharts-point {
    fill: rgba(234, 90, 127, 1) !important;
  }

  .highcharts-legend g g g:nth-child(2).highcharts-legend-item-hidden {
    .highcharts-point {
      fill: rgb(175, 173, 173) !important;
    }
  }

  .highcharts-legend g g g:nth-child(1) path,
  .highcharts-legend g g g:nth-child(1) .highcharts-point {
    fill: rgba(14, 174, 255, 1) !important;
  }

  .highcharts-legend g g g:nth-child(1).highcharts-legend-item-hidden {
    .highcharts-point {
      fill: rgb(175, 173, 173) !important;
    }
  }
}

#profit-analytics-import {
  .highcharts-legend g:nth-child(3).highcharts-legend-item-hidden {
    .highcharts-point {
      fill: rgb(175, 173, 173) !important;
    }
  }

  .highcharts-legend g:nth-child(3) path,
  .highcharts-legend g:nth-child(3) .highcharts-point {
    fill: #ff9233 !important;
  }

  .highcharts-series-2.highcharts-tracker {
    path {
      fill: #ff9233 !important;
    }
  }

  .highcharts-legend g g g:nth-child(2) path,
  .highcharts-legend g g g:nth-child(2) .highcharts-point {
    fill: rgba(234, 90, 127, 1) !important;
  }

  .highcharts-legend g g g:nth-child(2).highcharts-legend-item-hidden {
    .highcharts-point {
      fill: rgb(175, 173, 173) !important;
    }
  }

  .highcharts-legend g g g:nth-child(1) path,
  .highcharts-legend g g g:nth-child(1) .highcharts-point {
    fill: rgba(14, 174, 255, 1) !important;
  }

  .highcharts-legend g g g:nth-child(1).highcharts-legend-item-hidden {
    .highcharts-point {
      fill: rgb(175, 173, 173) !important;
    }
  }
}

#inventory-status {
  .highcharts-legend g:nth-child(3) path,
  .highcharts-legend g:nth-child(3) .highcharts-point {
    fill: #ff9655 !important;
  }
}

.vs-tooltip.vs-tooltip-top.vs-tooltip-null {
  z-index: 10000000000 !important;
}

.cursor-table {
  cursor: default !important;

  .vs-table-text {
    cursor: default !important;
  }
}

.hand-pinter {
  cursor: pointer;
}

.flag-1 {
  background: #00aeff;
  border-radius: 7px;
  color: white;
  padding: 3px 8px;
  font-weight: bold;
  margin-top: 4px;
}

.flag-2 {
  background: #21409a;
  border-radius: 7px;
  color: white;
  padding: 3px 8px;
  font-weight: bold;
  margin-top: 4px;
}

.highcharts-container {
  width: 100% !important;
}

.highcharts-root {
  width: 100% !important;
}

.full-page-prompt.con-vs-dialog {
  margin-top: 75px !important;
}

@media only screen and (max-width: 1600px) {
  .scorecard-title h4 {
    font-size: 1rem;
  }
  #page-user-view table td {
    font-size: 0.75rem !important;
    padding-bottom: 0.1rem !important;
    min-width: 95px !important;
  }
  .full-page-prompt.con-vs-dialog {
    margin-top: 75px !important;
  }
  .router-view {
    padding: 0.6rem !important;
  }
  .custom-img-container {
    height: 40px !important;
    width: 40px !important;
  }

  .vs-con-table .vs-con-tbody .con-vs-checkbox.align-left-switch {
    justify-content: flex-start !important;
    //border-top: 1px solid rgba(255,255,255,.1);
    //padding-top: 8px;
    .con-slot-label {
      font-size: 0.65rem !important;
    }
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
    padding: 10px 8px !important;
  }

  .responsive-table.custom-table-small {
    .flex-div {
      flex-flow: nowrap;

      .min-w {
        min-width: 145px !important;
        margin-right: 16px !important;
      }
    }

    table td {
      font-size: 0.65rem !important;

      .number-font {
        //font-size: 10px !important;
      }
    }

    .vs-checkbox--input {
      font-size: 0.65rem !important;
    }

    .vs-con-table table {
      font-size: 0.65rem !important;
    }

    .vs-button {
      font-size: 0.65rem !important;
    }

    .vs-input--input.normal,
    .vs-con-tbody .vs-table--tbody-table {
      font-size: 0.75rem !important;
    }

    .vs-con-input input {
      font-size: 0.75rem !important;
    }

    .input-span-placeholder,
    th {
      font-size: 0.65rem !important;
    }
  }

  .vue-select-standard {
    .vs__selected {
      font-size: 10px !important;
    }

    .vs__dropdown-toggle {
      width: 100% !important;
    }
  }
}

.flex-div {
  flex-flow: nowrap;

  .min-w {
    min-width: 170px !important;
  }
}

.theme-dark .v-nav-menu .feather-icon {
  color: #fff !important;
}

.single-form-wizard {
  .wizard-nav.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }

  .wizard-footer-left {
    display: block !important;

    .wizard-btn {
      text-align: left;
      padding-left: 0;
    }
  }

  &.custom-form-wizard .wizard-footer-right .wizard-btn {
    border: 0 solid transparent !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}

.tr-small.td.vs-table--td span {
  font-size: 0.8rem !important;
}

.theme-dark .spendChart {
  .highcharts-legend g:nth-child(3) path {
    fill: #39aefd !important;
  }
}

.spendChart {
  .highcharts-legend g:nth-child(3) path {
    fill: #39aefd !important;
  }
}

.theme-dark .monitoringChart {
  .highcharts-legend g:nth-child(3) path {
    fill: #ff9233 !important;
  }
}

.monitoringChart {
  .highcharts-legend g:nth-child(3) path {
    fill: #ff9233 !important;
  }
}

.theme-dark .monitoringChart {
  .highcharts-legend g.highcharts-legend-item-hidden path {
    fill: rgb(204, 204, 204) !important;
  }
}
.theme-dark .chartElem.new-format {
  .chart-title {
    color: white;
  }
}
.chartElem.new-format {
  .chart-title {
    position: absolute;
    top: 4px;
    left: 8px;
    color: #626262;
    font-weight: bold;
    font-size: 18px;
    z-index: 1;
  }

  .chart {
    padding-top: 32px;
  }

  @media only screen and (max-width: 768px) {
    .custom-date-dropdown {
      font-size: 12px;
      top: 32px;
    }
    .chart {
      padding-top: 56px;
    }
  }
}

.monitoringChart {
  .highcharts-legend g.highcharts-legend-item-hidden path {
    fill: rgb(204, 204, 204) !important;
  }
}

.textAlignRight {
  text-align: right;
  min-width: 120px !important;

  .vs-table-text {
    text-align: right !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
  }
}
.textAlignRightRep {
  text-align: right;
  min-width: 110px !important;

  .vs-table-text {
    text-align: right !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
  }
}
.textAlignRightMinWidth {
  text-align: right;
  min-width: 70px !important;

  .vs-table-text {
    text-align: right !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
  }
}

.vs-table--tbody-table .tr-values {
  tr {
    background-color: transparent !important;
  }
}

.theme-dark {
  .vs-table--tbody-table .tr-values {
    border-top: 1px solid #393939;
  }

  .stripe {
    .vs-table--tbody-table
      .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
      //background-color: transparent !important;
      .date1tablebackground {
        //border-bottom:1px solid #2c3252;
      }
    }
  }
}

.vs__dropdown-menu {
  max-height: 200px !important;
}

.create-first-marketplace {
  .vx-navbar-wrapper.navbar-default {
    z-index: 99999 !important;
  }

  .vx-navbar-wrapper {
    z-index: 99999 !important;
  }
}

.marketplace-custom-wizard {
  &.size-medium {
    .vs-dialog {
      overflow-y: inherit !important;
    }

    &.scroll {
      .vs-dialog {
        overflow-y: auto !important;
      }
    }
  }

  &.con-vs-dialog {
    //z-index: 51000;
  }

  .wizard-card-footer {
    float: left;
    width: 100%;
    margin-bottom: 16px;

    .wizard-footer-left {
      display: none;
    }
  }

  .wizard-footer-left {
    display: none;
  }
}

.vs-tooltip {
  z-index: 5099902 !important;
  max-width: 270px !important;
  min-width: 150px;
  text-align: center;
  &.buy-box-tooltips {
    max-width: 460px !important;
  }
}

.flex-btn {
  min-width: 218px;
  margin-left: 16px;

  &.small-btn {
    min-width: 135px;

    .vs-button-text {
      font-size: 14px;
    }
  }

  .vs-button-text {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
}

.theme-dark .alert-text {
  color: white !important;
}

.custom-alert {
  height: auto !important;

  .alert-text {
    color: #21409a;
    display: flex;
    align-items: center;
    flex-flow: row;
    justify-content: space-between;
  }
}

.label {
  z-index: 1 !important;
}

.highcharts-tooltip span {
  background-color: rgba(26, 194, 204, 1);
  border: 1px solid rgba(26, 194, 204, 1);
  opacity: 1;
  z-index: 9999 !important;
}

.custom-map-chart .highcharts-tooltip span {
  background-color: #fff !important;
  border: 1px solid #fff !important;
}

@media only screen and (max-width: 768px) {
  .flex-btn {
    margin-left: 0;
    margin-top: 16px !important;
  }
  .custom-alert {
    .alert-text {
      flex-flow: wrap;
    }
  }
}

.con-vs-dropdown--menu {
  z-index: 98 !important;
}

.vx-navbar-wrapper.navbar-default {
  z-index: 10000 !important;
}

.custom-table {
  .lables-width {
    min-width: 70px;
  }
}

.vs-dropdown-menu {
  z-index: 1000000001 !important;
}

.custom-date-dropdown {
  position: absolute !important;
  z-index: 9;
  right: 20px;
  color: #2c2c2c;
  top: 8px;
  font-weight: bold;

  .a-icon {
    color: #2c2c2c;
  }
}

.daily-dropdown.custom-date-dropdown {
  //z-index: 0!important;
}

.custom-table-small {
  .vs-con-table table {
    font-size: 0.9rem !important;
  }

  .vs-button,
  .vs-input--input.normal,
  .vs-con-tbody .vs-table--tbody-table {
    font-size: 15px !important;
  }

  &.repricer-table {
    .vs-button,
    .vs-input--input.normal,
    .vs-con-tbody .vs-table--tbody-table {
      font-size: 0.9rem !important;
    }
  }

  .input-span-placeholder,
  th {
    font-size: 0.8rem !important;
  }

  .vs-input--input.normal {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .input-span-placeholder.vs-input--placeholder.normal {
    padding-left: 0px !important;
    padding-right: 0px !important;
    text-align: left !important;
  }

  .vs-table--tbody-table .tr-values td {
    padding: 1.5px;
  }
}

.size-xl {
  z-index: 10001;

  .vx-card .vx-card__collapsible-content .vx-card__body {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

#avatar-col {
  width: auto !important;
}

#case-chart-table {
  .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 10px 8px !important;
  }

  .vs-table--thead {
    border-bottom: 3px solid #393939 !important;
  }

  .vs-table--tbody-table {
    min-width: 600px;
  }
}

.theme-dark {
  #case-chart-table {
    .vs-table--thead {
      border-bottom: 3px solid #fff;
    }

    .vs-table--tbody {
      background: #161a1d;
      border: 2px solid transparent !important;
    }
  }
  .store-table.repricer-store-table .vs-table--tbody {
    background: white;
  }
}

.vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
  padding: 24px 8px !important;
}

.full-page-prompt
  .vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td {
  padding: 37px 8px !important;
}

@media only screen and (max-width: 1600px) {
  .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 24px 15px !important;
  }
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
  padding-left: 5px !important;
}

.con-vs-dropdown--menu {
  min-width: 160px;
}

.theme-dark {
  .custom-date-dropdown {
    color: white;

    .a-icon {
      color: white;
    }
  }
}

.theme-dark {
  .v-nav-menu .header-sidebar .feather-icon svg {
    color: white !important;
  }
}

.v-nav-menu .vs-sidebar {
  //z-index: 50999 !important;
}

.v-nav-menu .vs-sidebar--item .router-link-active {
  background: #21409a !important;
  box-shadow: none !important;
}
.theme-dark {
  .v-nav-menu .vs-sidebar--item .router-link-active {
    background: #2d363c !important;
  }
}

.theme-dark {
  .empty-chart {
    background: #21272c;
    color: #ffffff;

    .title {
      color: white;
    }

    p {
      color: white;
    }
  }
}

.empty-chart {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 30px;
  color: #2c2c2c;
  position: relative;
  background: #fff;

  .title {
    position: absolute;
    top: 4px;
    left: 8px;
    color: #2c2c2c;
    font-weight: 600;
    font-size: 18px;
  }

  p {
    color: #2c2c2c;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .vs-progress-primary {
    width: 50%;
  }

  @media only screen and (max-width: 991px) {
    height: 200px;
  }
}

.expenses-overview-chart .highcharts-legend {
  .highcharts-legend-item:first-child {
    span {
      min-height: 59px !important;
      height: 59px !important;
    }
  }
}

.reasons-overview-chart .highcharts-legend {
  .highcharts-legend-item:first-child {
    span {
      min-height: 29px !important;
      height: 29px !important;
    }
  }
}

.reasons-overview-chart .highcharts-legend-item {
  &:first-child {
    .legend-row {
      color: white !important;
    }
  }
}

.expenses-overview-chart .highcharts-legend-item {
  &:first-child {
    .legend-row {
      color: white !important;
    }
  }
}

.sku-score-card-container .highcharts-legend-item {
  text {
    font-size: 10px !important;
  }

  path {
    font-size: 10px !important;
  }

  span {
    font-size: 10px !important;
    top: 4.5px !important;
  }
}
.profit-flex-item {
  .highcharts-legend-item {
    span {
      top: 3px !important;
      left: 23px !important;
    }
  }
}
.reasons-overview-chart .legend-row {
  display: flex;
  min-width: 330px;
  margin-right: 100px;
  margin-left: 75px;

  .left-content {
    min-width: 280px;
    display: flex;
    align-items: center;
    color: #2c2c2c;

    &.title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 16px;
    }
  }

  .right-content {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #2c2c2c;

    &.title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 16px;
    }
  }
}

.expenses-overview-chart .legend-row {
  display: flex;
  min-width: 300px;
  margin-right: 100px;
  margin-left: 75px;

  .left-content {
    min-width: 270px;
    display: flex;
    align-items: center;
    color: #2c2c2c;

    &.title {
      font-size: 15px;
      font-weight: bold;
      padding-bottom: 16px;
    }
  }

  .right-content {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #2c2c2c;

    &.title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 16px;
    }
  }
}

.theme-dark {
  .expenses-overview-chart .legend-row {
    .left-content {
      color: white;
    }

    .right-content {
      color: white;
    }
  }

  .reasons-overview-chart .legend-row {
    .left-content {
      color: white;
    }

    .right-content {
      color: white;
    }
  }
}

@media only screen and (max-width: 450px) {
  .reasons-overview-chart .legend-row {
    .left-content {
      &.title {
        font-size: 14px;
      }
    }

    .right-content {
      &.title {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .expenses-overview-chart .legend-row {
    margin-right: 0;
    margin-left: 0;
  }
  .reasons-overview-chart .legend-row {
    margin-right: 0;
    margin-left: 0;
  }
}

.select-border-bottom .vs__dropdown-toggle {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-radius: 0 !important;
}

.theme-dark .select-border-bottom .vs__dropdown-toggle {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.theme-dark .vs__search {
  color: white !important;
  background-color: transparent !important;
}

.border-line {
  border: 1px solid #ffffff !important;
  border-radius: 5px !important;
}

.theme-dark .vs-input-primary.isFocus .vs-input--label {
  color: white !important;
}

.vue-select-up .vs__dropdown-menu {
  top: auto !important;
  bottom: 33px !important;
}

.select-none-border {
  .vs-select--input {
    border: none !important;
  }
}

.theme-dark .vs-con-table .vs-con-tbody .vs-table--tbody-table tr {
  background: #212744;
}

.theme-dark .custome-input input {
  background: transparent !important;
  border: none;
}

.theme-dark .custome-input-costs input {
  background: transparent !important;
  border: none;
}

.theme-dark .custom-switch.vs-switch {
  background: rgb(184, 180, 180);
}

.theme-dark .repricer-custom-switch.vs-switch {
  background: rgb(183, 179, 179);
}

.repricer-custom-switch.vs-switch {
  background: rgb(199, 199, 199);
}

.custom-switch.vs-switch.vs-switch-success.vs-switch-active {
  background: #00aeff;
}

.repricer-custom-switch.vs-switch.vs-switch-success.vs-switch-active {
  background: #00aeff;
}

.theme-dark .custom-switch.vs-switch.vs-switch-success.vs-switch-active {
  background: #00aeff;
}

.theme-dark
  .repricer-custom-switch.vs-switch.vs-switch-success.vs-switch-active {
  background: #00aeff;
}

.theme-dark .login-form {
  .input-span-placeholder,
  .vs-icon {
    color: white !important;
  }

  .vs-input-primary .vs-input--input:focus {
    border-color: white !important;
  }
}

.customizer-btn {
  z-index: 509991;
}

#theme-customizer .vs-sidebar {
  z-index: 50999000 !important;
}

.w-full-chart {
  width: 100% !important;
  padding-bottom: 4px !important;

  .highcharts-container {
    width: 100% !important;

    svg {
      width: 100% !important;

      .highcharts-background {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}

.theme-dark .chartElem {
  background: #21272c;

  .highcharts-axis-labels.highcharts-yaxis-labels text {
    fill: white !important;
  }

  .highcharts-axis-labels.highcharts-xaxis-labels text {
    fill: white !important;
  }

  .highcharts-title {
    fill: white !important;
  }

  .highcharts-axis-title {
    fill: white !important;
  }

  .highcharts-legend-item text {
    fill: white !important;
  }

  .highcharts-grid.highcharts-yaxis-grid path {
    stroke: #7d8de4 !important;
  }

  .highcharts-label.highcharts-data-label text {
    fill: white !important;
  }

  .highcharts-label.highcharts-data-label text tspan {
    stroke-width: 0 !important;
  }
}

.chartElem {
  background-color: #fff;
  border-radius: 10px;
}

.highcharts-axis-labels.highcharts-yaxis-labels text {
  fill: #2c2c2c !important;
}

.highcharts-axis-labels.highcharts-xaxis-labels text {
  fill: #2c2c2c !important;
}

.highcharts-title {
  fill: #2c2c2c !important;
}

.highcharts-axis-title {
  fill: #2c2c2c !important;
}

.highcharts-legend-item text {
  fill: #2c2c2c !important;
}

.highcharts-grid.highcharts-yaxis-grid path {
  stroke: #dfdfdf !important;
}

.highcharts-label.highcharts-data-label text {
  fill: #2c2c2c !important;
}

.highcharts-label.highcharts-data-label text tspan {
  stroke-width: 0 !important;
}

.highcharts-map-series .highcharts-label.highcharts-data-label text {
  fill: #fff !important;
}

.highcharts-text-outline {
  stroke-width: 0 !important;
  fill: white !important;
  stroke: white !important;
}

.responsive-chart {
  min-height: 500px;
  height: 50%;
}

@media only screen and (max-width: 991px) {
  .responsive-chart {
    min-height: 50%;
    height: 100%;
  }
}

.custom-form-wizard {
  .wizard-header {
    display: none;
  }

  .wizard-progress-with-circle {
    background-color: #dfdfdf;
  }

  .wizard-footer-right {
    .wizard-btn {
      background-image: linear-gradient(
        90deg,
        #a533c1,
        #bd27b2,
        #cf1ca2,
        #dd1792,
        #e71d82
      );
      border-radius: 40px;
      font-weight: bold;
      font-size: 18px;
      border-color: white !important;
      border-style: solid !important;
      border-width: 2px !important;
    }
  }

  .wizard-footer-left {
    .wizard-btn {
      background-color: transparent !important;
      color: #21409a !important;
      border-radius: 40px;
      border: 2px solid white;
      border: none;
    }
  }

  .wizard-tab-content {
    padding: 10px 0px 10px !important;
  }

  @media only screen and (max-width: 768px) {
    .wizard-tab-content {
      padding: 16px 0 0 0;
    }
    .wizard-card-footer {
      padding: 0;
    }
  }
}

.theme-dark .vue-form-wizard .wizard-nav-pills > li > a .stepTitle {
  color: #dae1e7 !important;
  font-weight: bold;
}

.theme-dark .custom-step {
  .copy-btn {
    .vs-button-text {
      color: white;
    }
  }
}

.theme-dark .custom-form-wizard .wizard-footer-left .wizard-btn {
  color: white !important;
}

.overflow-x-hidden {
  .vs-dialog {
    overflow-x: hidden !important;
  }
}

.account-login {
  .con-vs-dialog .vs-dialog {
    height: 100%;
    overflow-y: auto;
  }
}

.select-content {
  &.phone {
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-bottom: 1px solid rgba(60, 60, 60, 0.26) !important;
    border-radius: 4px;

    .input-tel__input,
    .country-selector__input {
      //caret-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    .country-selector__input,
    .input-tel__input {
      background: transparent !important;
      border: none !important;
      color: #626262;
    }

    .country-selector__label {
      //color: white !important;
    }

    .country-selector__toggle__arrow path.arrow {
      //fill: white;
    }

    .input-tel.is-valid .input-tel__label {
      color: #32aeff !important;
    }

    .input-tel__label {
      color: #ea5455 !important;
    }
  }
}

.theme-dark {
  .select-content {
    &.phone {
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-radius: 0;

      .country-selector__input,
      .input-tel__input {
        color: white !important;
      }

      .country-selector__label {
        color: white !important;
      }

      .country-selector__toggle__arrow path.arrow {
        fill: white;
      }
    }
  }
}

.none-header .vs-dialog-cancel--icon {
  margin-right: 16px;
  margin-top: 11px;
  position: absolute !important;
  top: 4px;
  right: 0;
}

.none-header .vx-card .vx-card__header {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

* {
  font-family: Poppins, sans-serif;
  font-weight: 400;
}
strong {
  font-weight: bold;
}

.size-xl .vs-dialog {
  max-width: 85% !important;
  max-height: 95% !important;
  overflow-y: auto !important;
  position: relative;
  top: 0;
}

.size-xl .vs-dialog-header {
  position: sticky !important;
  z-index: 1;
  top: 0;
  display: none;
}

.vs-dialog header {
  display: none;
}

.size-medium-large .vs-dialog {
  max-width: 45% !important;
  max-height: 98% !important;
  overflow-y: auto !important;
  position: relative;
  top: 0;
}

.size-medium-large.funding-modal .vs-dialog {
  max-width: 75% !important;
  .vs-dialog-text {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
}

.size-medium-large .vs-dialog footer {
  display: none !important;
}

@media only screen and (max-width: 1600px) {
  .size-medium-large .vs-dialog {
    max-width: 57% !important;
  }
  .size-medium-large.funding-modal .vs-dialog {
    max-width: 75% !important;
  }
}

@media only screen and (max-width: 768px) {
  .size-medium-large .vs-dialog,
  .size-xl .vs-dialog,
  .size-large .vs-dialog {
    max-width: 90% !important;
    max-height: 85% !important;
  }
  .size-medium-large.funding-modal .vs-dialog {
    max-width: 90% !important;
  }
}

.size-xl .vs-dialog footer {
  display: none !important;
}

.size-xl .vs-dialog .dialog-title {
  display: none !important;
}

.size-xl .vs-dialog .vs-dialog-text {
  padding: 0 !important;
}

.size-large .vs-dialog {
  max-width: 50% !important;
  max-height: 98% !important;
  overflow-y: auto !important;
  position: relative;
}

.size-large .vs-dialog footer {
  display: none !important;
}

.size-medium .vs-dialog {
  max-width: 670px !important;
  max-height: 98% !important;
  position: relative;
}

.size-medium .vs-dialog footer {
  display: none !important;
}

.light-modal {
  .custom-form-wizard .wizard-footer-left .wizard-btn {
    color: #9d9d9d !important;
  }

  .vs-dialog {
    position: relative;
  }

  .vs-dialog-header {
    background: #fff !important;
    z-index: 9999;
  }

  .dialog-title {
    display: none !important;
  }

  .vs-dialog {
    background-color: #fff !important;
  }

  .vs-dialog-text {
    margin-top: -50px !important;
    position: relative !important;

    .wizard-tab-content {
      height: 533px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .light-modal {
    .vs-dialog-text {
      margin-top: -30px !important;
    }
  }
}

@media only screen and (max-width: 450px) {
  .reasons-overview-chart .legend-row {
    display: flex;
    margin-right: 0px;
    margin-left: 0px;
    min-width: 280px;

    .left-content {
      min-width: 220px;
    }

    .right-content {
      min-width: 60px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .reasons-overview-chart .legend-row {
    min-width: 250px;

    .left-content {
      min-width: 180px;
    }

    .right-content {
      min-width: 50px;
    }
  }
}

.size-small .vs-dialog {
  max-width: 500px !important;
  max-height: 98% !important;
  position: relative;
}

.size-small.little-small .vs-dialog {
  max-width: 420px !important;
  max-height: 98% !important;
  position: relative;
}

.size-small .vs-dialog footer {
  display: none !important;
}

.scroll .vs-dialog {
  overflow-y: auto !important;
}

.custom-map-chart .highcharts-legend-box {
  height: 40px !important;
  max-height: 40px !important;
  margin-bottom: 40px !important;
  fill: transparent !important;
}

.custom-map-chart .highcharts-coloraxis-labels text {
  fill: white !important;
}

.custom-line-chart .highcharts-grid-line {
  stroke-width: 0.5 !important;
}

.custom-table.notdata-nodisplay {
  .not-data-table.vs-table--not-data {
    display: none !important;
  }
}

.theme-dark .highcharts-legend-item span {
  color: white !important;
}

.title-display .highcharts-title {
  tspan {
    opacity: 0;
  }
}

.theme-dark .highcharts-reset-zoom {
  text {
    color: white !important;
    fill: white !important;
  }
}

.highcharts-reset-zoom {
  text {
    color: #2c2c2c !important;
    fill: #2c2c2c !important;
  }
}

.title-display .highcharts-button-box {
  display: none;
}

@media only screen and (max-width: 991px) {
  .size-medium .vs-dialog {
    overflow-y: auto !important;
  }
  .size-small .vs-dialog {
    overflow-y: auto !important;
  }
}

.theme-semi-dark {
  .v-select .vs__selected {
    color: white;
  }
}
.theme-semi-dark .marketplace-wizard {
  .v-select .vs__selected {
    color: #626262 !important;
  }
}
.theme-semi-dark .add-user-selector {
  .v-select .vs__selected {
    color: #626262 !important;
  }
}
#replenishment-chart {
  .highcharts-xaxis {
    display: none;
  }
  .highcharts-grid.highcharts-yaxis-grid path {
    stroke: transparent !important;
  }
}

.payment-step {
  .StripeElement {
    .ElementsApp {
      background: red;
    }
  }
  .InputContainer {
    border: 2px solid #21409a !important;
  }
}

/* BELL */
.notification-bell {
  position: absolute;
  top: 32px;
  left: calc(50% - 11px);
  cursor: default;

  &:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 16px;
    top: 2px;
    left: 6px;
    border-radius: 100%;
    box-shadow: 0 4px 9px rgba(64, 64, 64, 0.5);
  }

  svg {
    color: #32aeff;
  }

  &.red {
    cursor: pointer;

    svg {
      fill: #e40000 !important;
      stroke: #e40000 !important;
    }

    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 16px;
      top: 2px;
      left: 6px;
      border-radius: 100%;
      box-shadow: 0 4px 9px transparent !important;
    }
  }

  &.orange {
    cursor: pointer;

    svg {
      fill: #ef8122 !important;
      stroke: #ef8122 !important;
    }

    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 16px;
      top: 2px;
      left: 6px;
      border-radius: 100%;
      box-shadow: 0 4px 9px transparent !important;
    }
  }
}

.notification-bell * {
  display: block;
  margin: 0 auto;
  //background-color: rgb(0, 174, 255);
  //box-shadow: 0px 4px 9px rgba(255,255,255,.5);
}

.theme-dark {
  .notification-bell {
    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      left: 7px;
      top: 6px;
      border-radius: 100%;
      //box-shadow: 0 4px 9px rgba(255,255,255,.5);
    }

    svg {
      color: #ffffff;
    }

    &.red {
      &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left: 5px;
        top: 1px;
        border-radius: 100%;
        box-shadow: 0 4px 9px 1px #e40000;
      }
    }

    &.orange {
      &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left: 5px;
        top: 1px;
        border-radius: 100%;
        box-shadow: 0 4px 9px 1px #d96807;
      }
    }
  }
}

.notification-td {
  position: relative;
}

.rep-repricer-noti {
  color: #161a1d;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 4px;
  padding-right: 4px;
}

.rep-noti-pad {
  padding-right: 16px;
}

.notification-content {
  background-color: white;
  padding-left: 16px;
  padding-bottom: 16px;
  position: absolute;
  min-width: 326px;
  top: 68px;
  left: 24px;
  border-radius: 4px;
  z-index: 999;
  display: none;
  cursor: default;
  border: 1px solid #161a1d;

  .vs-icon.vs-dialog-cancel {
    &.--icon {
      position: absolute;
      right: 0;
    }
    display: flex;
    color: #2c1d80;
  }

  &.active {
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #161a1d;
  }
}

.theme-dark {
  .notification-content {
    &:before {
      border-bottom: 5px solid #ffffff;
    }
  }
}

.notification-title {
  color: #161a1d;
  border-bottom: 1px solid #161a1d;
  margin-bottom: 4px;
}

.manage-setting-repricer-sub {
  float: left;
  //padding-bottom: 10px!important;
}

.manage-setting-repricer-title.custom-tooltip-roi {
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100%;

  p {
    margin-bottom: 0px !important;
  }
}

.manage-setting-repricer-sub-sec {
  padding-left: 14px !important;
  border-radius: 0px !important;
}

.manage-setting-repricer-sub-sec-width {
  width: 40%;
}

.manage-setting-repricer-sub.custom-tooltip-roi {
  padding: 0px;
}

.pricing-setting {
  border-right: 2px solid #21409a;
  border-radius: 0px !important;
  padding-right: 14px !important;
  // margin-right: 14px !important;
}

.msg-setting-default {
  font-size: 10px;
  font-weight: normal;
  color: #161a1d;
  padding-top: 10px !important;
  border-top: 2px solid #21409a !important;
}

.manage-setting-repricer-sub-first {
  width: 496px;
}

.store-filter {
  width: 100%;

  .menu ul li::before {
    border-top-color: #161a1d !important;
  }

  .menu > ul li ul li:hover {
    color: #fff !important;
  }
  .menu > ul li ul {
    width: 108% !important;
  }
  .menu > ul {
    border: 1px solid #161a1d !important;
    background-color: white;

    li {
      :before {
        border-top-color: #161a1d !important;
      }

      color: #161a1d !important;
    }
  }

  .menu > ul li ul {
    padding: 0;
    background-color: #fff;
    border: 1px solid #161a1d !important;
  }
}

.hide-menu {
  display: none;
}
.store-table {
  .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
    padding-left: 0px !important;
  }

  overflow-y: auto;
  max-height: 390px;
  min-height: 135px;
  background-color: white !important;

  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border: 1px solid #21409a;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #21409a;
    border-radius: 0;
    //outline: 1px solid slategrey;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table tr {
    background: #ffffff !important;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table tr.selected {
    background: #ffffff !important;
  }

  .vs-table--thead {
    border-bottom: 2px solid #161a1d;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead tr {
    background: #ffffff !important;
  }

  .vs-con-table .vs-con-tbody {
    border: 0px !important;
  }

  .vs-table-text {
    font-size: 14px !important;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px !important;

    span {
      display: flex !important;
      align-items: center;
      justify-content: left;
    }
  }

  .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 0px !important;
  }

  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
    color: #ffffff !important;
    background-color: #303b98 !important;

    .sync-progress svg {
      color: #ffffff !important;
    }

    .sync-completed svg {
      color: #ffffff !important;
    }

    td {
      span {
        color: white;
      }
    }
  }

  .vs-table--tbody-table .tr-values {
    border: 0px !important;
    box-shadow: none !important;
  }

  .vs-con-table td:first-child {
    border: 0px !important;
  }

  .tr-values vs-table--tr tr-table-state-null is-selected .selected {
    border: 0px !important;
    border-spacing: 0px !important;
  }

  .td-check {
    width: 50px;
  }
}
.store-table.permission-table .vs-con-tbody.vs-table--tbody {
  height: 402px !important;
  background: transparent !important;
}
.store-table-height {
  min-height: 155px;
}

.store-table.add-user-stores-table {
  max-height: 430px;
}

.notification-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-size: 13px;
  background: rgba(0, 174, 255, 0.27);
  border-radius: 4px;
  padding: 8px;

  .alert-circle {
    min-width: 15px;
    min-height: 15px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-right: 8px;

    &.red {
      background: #e40000;
    }

    &.orange {
      background: #ff9233;
    }
  }

  .alert-img {
    width: auto;
    height: 50px;
    border: 1px solid #161a1d;
    border-radius: 4px;
    margin-left: 8px;
  }
}

.store-table {
  .vs-con-table .vs-con-tbody {
    background: #ffffff;
  }

  table {
    margin-bottom: 5px;
  }

  .tr-values.tr-table-state-null.is-selected.selected {
    background: #665252 !important;
  }
}

.updated-sku-size {
  font-size: 10px;
  color: #161a1d;
  font-weight: 400;

  &:hover {
    color: #ffffff;
  }
}

/* BELL */

.not-found-dis-color {
  color: #ffffff !important;
}
.remove-sku-item {
  cursor: pointer;
  svg {
    fill: #ff1000;
    color: #ffffff;
  }
}
.theme-dark .remove-sku-item {
  svg {
    color: #393939;
  }
}

.theme-dark .new-user-prompt .vs-dialog input {
  -webkit-text-fill-color: #7b7b7b !important;
  -webkit-box-shadow: none;
}
.store-table table {
  margin-bottom: 2px !important;
}
.theme-dark .new-user-prompt .vs-dialog input:hover {
  background: #fff !important;
}
.new-user-prompt,
.order-prompt {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #7b7b7b !important;
    -webkit-box-shadow: none !important;
    transition: all 0.3s ease !important;
  }
}
#payment-successful.con-vs-dialog {
  .vs-dialog-dark {
    backdrop-filter: blur(20px) !important;
  }
}
.theme-dark input:-webkit-autofill,
.theme-dark input:-webkit-autofill:hover,
.theme-dark input:-webkit-autofill:focus,
.theme-dark textarea:-webkit-autofill,
.theme-dark textarea:-webkit-autofill:hover,
.theme-dark textarea:-webkit-autofill:focus,
.theme-dark select:-webkit-autofill,
.theme-dark select:-webkit-autofill:hover,
.theme-dark select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #262c49 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.upload-excel-popup .vs-dialog {
  width: 750px !important;
}
.setting-price-flex.upload-dialog .custom-tooltip-roi {
  width: 100% !important;
}
.highcharts-legend-item.highcharts-line-series {
  display: flex;
  align-items: center;
  image {
    width: 12px !important;
    height: 12px !important;
    transform: translate(-6px, -7px) !important;
  }
  &.highcharts-legend-item-hidden {
    image {
      -webkit-filter: grayscale(100%) !important; /* Safari 6.0 - 9.0 */
      filter: grayscale(100%) !important;
    }
  }
}
.datapicker-dialog.con-vs-dialog .vs-dialog {
  display: table;
  background: transparent !important;
  .datepiker-position {
    background: #fff !important;
    background-color: #fff;
    border: 5px solid #fff;
    border-radius: 5px;
  }
  .not-current-month {
    display: none;
  }
  .mx-datepicker-btn-confirm {
    background: #21409a;
    color: white;
  }
}
.datapicker-profit-dialog.con-vs-dialog .vs-dialog {
  display: table;
  background: transparent !important;
  .datepiker-position {
    background: #fff !important;
    background-color: #fff;
    border: 5px solid #fff;
    border-radius: 5px;
  }
  .not-current-month {
    display: none;
  }
  .mx-datepicker-btn-confirm {
    background: #21409a;
    color: white;
  }
}
.datapicker-dialog.con-vs-dialog .vs-dialog header {
  display: none !important;
}
.datapicker-profit-dialog.con-vs-dialog .vs-dialog header {
  display: none !important;
}
.datapicker-dialog {
  justify-content: end !important;
  .vs-dialog {
    align-self: flex-start;
  }
}
.mx-datepicker-popup {
  z-index: 9999999 !important;
}

.theme-dark .datepiker-position input {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  color: #555 !important;
}
.datapicker-dialog.con-vs-dialog {
  justify-content: end !important;
  .vs-dialog {
    align-self: flex-start;
  }
}
.custom-date-dropdown.advertising-export {
  #profit-export-btn {
    margin-right: 0rem !important;
    margin-left: 0.75rem !important;
  }
  .medium-btn svg {
    margin-right: 9px !important;
    width: 20px !important;
    height: 20px !important;
  }
  .non-padding.vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(
      .large
    ) {
    padding: 0.4rem 0.2rem !important;
    font-size: 0.9rem !important;
  }
}
.repricer-action-button-groups .dwn-menu.medium-btn svg {
  margin-right: 6px !important;
}
.datapicker-dialog .vs-dialog {
  box-shadow: none !important;
}
.score-card.analytics-cards .height-for-subinfo .font-bold.percent-span {
  color: #2c2c2c !important;
  //font-weight: 400!important;

  span {
    color: #2c2c2c !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.theme-dark
  .score-card.analytics-cards
  .height-for-subinfo
  .font-bold.percent-span {
  color: #ffffff !important;

  span {
    color: #ffffff !important;
  }
}
.theme-dark .con-vs-dialog.stripe-update-cards .vs-dialog {
  background: #ffffff !important;
}
.theme-dark .con-vs-dialog.create-payment-prompt .vs-dialog {
  background: #fff !important;
}
.repricer-dialog.opt-minprice-dialog .vs-dialog {
  width: 600px !important;
  max-width: 600px !important;
  min-width: auto !important;
}
.score-card.analytics-cards .compare-txt-font {
  font-style: italic !important;
  font-weight: 300 !important;
  font-size: 1rem !important;
}
.custom-tooltip-roi {
  .titile-of-tab {
    b {
      font-size: 1rem;
    }
    font-size: 1rem !important;
  }
  .tab-titles {
    font-size: 0.9rem !important;
    b {
      font-size: 0.9rem !important;
    }
  }
  .tab-titles-clear .vs-button--text {
    font-size: 0.7rem !important;
  }
  .tab-sub-title-1 {
    font-size: 0.75rem !important;
  }
  .tab-sub-title-2 {
    font-size: 0.7rem !important;
  }
  .tab-info-title {
    font-size: 0.7rem !important;
  }
  .tabs-price-rounding-currency {
    font-size: 0.9rem !important;
  }
  .tab-info-title-text {
    font-size: 0.7rem !important;
    b {
      font-size: 0.75rem !important;
    }
    .vs-collapse-item--content .con-content--item {
      font-size: 0.7rem !important;
    }
  }
}
.titile-of-tab {
  b {
    font-size: 1rem;
  }
  font-size: 1rem !important;
}
.tab-titles {
  font-size: 0.9rem !important;
  b {
    font-size: 0.9rem !important;
  }
}
.tab-titles-clear .vs-button--text {
  font-size: 0.7rem !important;
}
.tab-sub-title-1 {
  font-size: 0.75rem !important;
}
.tab-sub-title-2 {
  font-size: 0.7rem !important;
}
.tab-info-title {
  font-size: 0.7rem !important;
}
.tabs-price-rounding-currency {
  font-size: 0.9rem !important;
}
.tab-info-title-text {
  font-size: 0.65rem !important;
  b {
    font-size: 0.7rem !important;
  }
  .vs-collapse-item--content .con-content--item {
    font-size: 0.65rem !important;
  }
}
.health-report-table.vs-con-table .vs-con-tbody {
  overflow: unset !important;
}
.health-report-table .vs-table--content {
  overflow: unset !important;
}
.textAlignCenterRep.textAlignRightRep .vs-table-text {
  justify-content: center !important;
}
.textAlignRightHealth {
  text-align: center;
  min-width: 110px !important;

  .vs-table-text {
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.profit-flex {
  display: flex;
  flex-flow: wrap;
  float: left;
  width: 100%;
  padding: 4px 16px;

  &-item {
    display: flex;

    &.second {
      width: 218px !important;

      .repricer-tooltip {
        //  height: 254px !important;
      }
    }

    &.first {
      width: calc(100% - 218px);

      .chartElem {
        width: calc(100% - 12px);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    &-item {
      &.second {
        width: 100% !important;
        height: auto !important;
      }

      &.first {
        width: 100%;

        .chartElem {
          width: 100%;
        }
      }
    }
  }
}
.profit-flex {
  &-item {
    display: flex;

    &.second.inventory-chart-box {
      width: 218px !important;
    }

    &.first.inventory-chart-box {
      width: calc(100% - 218px) !important;
    }
  }
  @media only screen and (max-width: 768px) {
    &-item {
      &.second.inventory-chart-box {
        width: 100% !important;
        height: auto !important;
      }

      &.first.inventory-chart-box {
        width: 100% !important;

        .chartElem {
          width: 100% !important;
        }
      }
    }
  }
}
.theme-dark {
  .legenditem {
    color: white !important;
  }

  .custom-tooltip-rep {
    background: white !important;
    color: #21409a !important;

    p {
      &.title {
        font-size: 0.75rem;
        border-bottom: 2px solid #ffffff;
      }
    }
  }
}
.custom-tooltip-rep {
  height: 100% !important;
  font-size: 0.75rem !important;
  background: rgba(var(--vs-primary), 1) !important;
  color: white;
  padding: 12px;
  border-radius: 5px;
  z-index: 999999;

  &.active {
    display: block;
  }

  &.advance {
  }

  &.position-bottom {
    bottom: 0;
  }

  small {
    font-size: 0.75rem !important;
  }

  hr {
    border: 0.1rem solid !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    margin-bottom: 0.4rem;
  }

  .vs-inputx.vs-input--input {
    background: transparent !important;
    border: 1px solid #21409a !important;
    padding: 5px 2px !important;
    color: #21409a !important;
    font-size: 0.75rem !important;
  }

  .vs-button-text.vs-button--text {
    font-size: 0.75rem;
  }

  .button-padding {
    padding: 4px !important;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    margin-bottom: 0.1rem;

    &.title {
      font-size: 0.75rem;
      border-bottom: 2px solid #ffffff;

      img {
        margin-right: 0;
      }
    }

    span {
      font-size: 12px !important;
      font-weight: bold;
    }

    b {
      font-weight: bold;
      font-size: 0.75rem;
    }

    small {
      font-weight: normal;
      font-size: 0.75rem !important;
      width: 100%;
    }

    .link {
      color: #00aeff;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .formLoader {
    width: 40px !important;
  }

  @media only screen and (max-width: 1900px) {
    font-size: 0.75rem !important;
    small {
      font-size: 0.75rem !important;
    }
    .vs-button-text.vs-button--text {
      font-size: 0.75rem;
    }
    .vs-inputx.vs-input--input {
      font-size: 0.85rem !important;
    }
    p {
      b {
        font-size: 0.75rem;
      }

      &.title {
        font-size: 0.75rem;
      }

      span {
        font-size: 0.75rem !important;
      }
    }
  }
}

.scrollable-table {
  .vs-table {
    min-width: 700px;
    &--tbody {
      max-height: calc(100vh - 320px);
    }

    &--thead {
      position: sticky;
      top: 0;
      z-index: 500;
    }
  }
}
.scrollable-table .fnsku-detail-table.vs-con-table {
  .vs-table {
    min-width: 250px;
  }
  .vs-table--content .vs-con-tbody {
    background: transparent;
    border: 0px;
    :hover {
      background-color: transparent !important;
    }
  }
  .fnsku-info {
    text-align: right;
    display: flex;
  }
  .mappingItem-fnsku-width {
    min-width: 200px;
  }
}
.theme-dark .fnsku-detail-table .vs-table--tbody-table .tr-values {
  border-top: 0px;
}
.fnsku-detail-table {
  border: 1px solid #e9e8e8;
  border-radius: 5px;
  .vs-table--tbody-table .tr-values {
    &:nth-child(even) {
      background: #a39f9f26 !important;
    }
    &:nth-child(odd) {
      background: #dbd7d72e !important;
    }
    :hover {
      background: transparent !important;
    }
  }
}
.fnsku-detail-table-header {
  display: inline-table;
}
.theme-dark
  .inv-table
  .vs-table--tbody-table
  .fnsku-detail-table-header.tr-values {
  border-top: 0px;
}

.theme-dark .fnsku-detail-table {
  border: 1px solid #093458;
  .vs-table--tbody-table .tr-values {
    &:nth-child(even) {
      background: #dbd7d70d !important;
    }
    &:nth-child(odd) {
      background: #dbd7d708 !important;
    }
    :hover {
      background: transparent !important;
    }
  }
}
.score-card.card-2-first {
  height: 504px !important;
  display: grid !important;
}
.score-card.card-2-first.card-2-open-cards-second {
  height: 264px !important;
}
.score-card.card-2-first.card-2-open-cards-first {
  height: 304px !important;
}
.score-card.card-2-second {
  height: 70px !important;
  display: grid !important;
}
.score-card.card-2-third {
  height: 120px !important;
  display: grid !important;
}
.score-card.card-2-open-cards-a {
  height: 270px !important;
  display: grid !important;
}
.score-card.card-2-open-cards-b {
  height: 360px !important;
  display: grid !important;
}
.score-card.analytics-cards .truncate.weight-normal-head span {
  font-weight: normal !important;
}
.theme-semi-dark
  .parent-pricing-child-actions
  .inventory-protection
  .v-select
  .vs__selected {
  color: #626262 !important;
}
.theme-semi-dark .v-select.select-reason-category .vs__selected {
  color: #626262;
}
#profit-analytics-chart {
  .highcharts-legend .highcharts-legend-item.highcharts-area-series {
    :nth-child(1) {
      display: none;
    }
  }
}
#price-flex-sync {
  .price-flex-input {
    width: 100%;
    max-width: 75px;
    min-width: 60px;
    .vs-con-input {
      .vs-inputx {
        height: 20px !important;
        font-size: 12px !important;
        padding: 5px !important;
        padding-top: 7px !important;
        border: 1px solid #264294 !important;
        color: #264294 !important;
      }
    }
    .input-span-placeholder.vs-input--placeholder.normal {
      padding: 3px !important;
      // font-size: 8px !important;
    }
  }
  .price-flex-select {
    width: 100%;
    max-width: 75px;
    z-index: 9999 !important;
    .vs__dropdown-toggle {
      border: 1px solid #22409a;
      background: #fff;
      border-radius: 5px;
      height: 20px !important;

      .vs__selected {
        font-size: 12px !important;
        color: #264294;
        margin-top: -2px !important;
      }
    }
    .vs__dropdown-menu {
      //dropdown
      width: 75px !important;
      min-width: 75px !important;
      max-width: 75px !important;
      // margin-top: -20px !important;
      .vs__dropdown-option {
        padding: 0 15px !important;
      }
      // .vs__selected-options {
      //   max-width: 75px !important;
      //   width: 100% !important;
      // }
    }
  }
}

.theme-dark {
  #price-flex-sync {
    .price-flex-input {
      .vs-con-input {
        .vs-inputx {
          border: 1px solid #fff !important;
          color: #fff !important;
        }
      }
    }
    .price-flex-select {
      .vs__dropdown-toggle {
        border: 1px solid #fff;
        background: transparent;

        .vs__selected {
          color: #fff;
        }
      }
    }
  }
}

.theme-dark {
  .invoice-receipt-dialog {
    .vs-dialog {
      background: #fff !important;
      .dark-theme-text {
        color: black;
      }
    }
  }
}
.detail-summary-table .vs-con-tbody {
  max-height: 500px !important;
}
.vendor-management-table.custom-table .vs-table--search-input {
  margin-left: 18px !important;
}
.router-view {
  .upgrade-banner {
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    z-index: 11111;
  }

  &.has-upgrade-banner {
    .router-content {
      pointer-events: none;
    }

    .upgrade-banner,
    .has-upgrade-banner-overlay {
      pointer-events: auto;
    }

    .has-upgrade-banner-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1111;
      backdrop-filter: blur(3px);
    }
  }
}
</style>
