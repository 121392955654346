import state from './moduleManageVendorState';
import mutations from './moduleManageVendorMutations';
import actions from './moduleManageVendorActions';
import getters from './moduleManageVendorGetters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
