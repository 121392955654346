const data = {
  screenPermissionList: [
    {
      name: 'Store Overview',
      permission: 1,
      label: 'StoreOverview',
      actionsLess: 2,
      path: '/',
      permissionName: 'store_overview',
    },
    {
      name: 'Aggregated Stores View',
      permission: 1,
      label: 'AggregatedStoresView',
      actionsLess: 2,
      path: '/aggregated-stores-view',
      permissionName: 'aggregated_stores_view',
    },
    {
      name: 'Operations Center',
      permission: 1,
      label: 'OperationsCenter',
      actionsLess: 2,
      path: '/daily-sales',
      permissionName: 'operations_center',
    },
    {
      name: 'Pricing Management',
      permission: 2,
      label: 'PricingManagement',
      actionsLess: 3,
      path: '/pricing-management',
      permissionName: 'pricing_management',
    },
    {
      name: 'Parent Asin Management',
      permission: 2,
      label: 'ParentAsinManagement',
      actionsLess: 3,
      path: '/parent-asin-management',
      permissionName: 'parent_asin_management',
    },
    {
      name: 'Advertising',
      permission: 2,
      label: 'Advertising',
      actionsLess: 0,
      path: '/advertising',
      permissionName: 'advertising',
    },
    {
      name: 'Reimbursement',
      permission: 2,
      label: 'Reimbursement',
      actionsLess: 3,
      path: '/reimbursement-analytics',
      permissionName: 'reimbursement',
    },
    {
      name: 'Inventory Management',
      permission: 2,
      label: 'InventoryManagement',
      actionsLess: 3,
      path: '/replenishment',
      permissionName: 'inventory_management',
    },
    {
      name: 'Sku Performance',
      permission: 1,
      label: 'SkuPerformance',
      actionsLess: 2,
      path: '/SKU-performance',
      permissionName: 'sku_performance',
    },
    {
      name: 'Integrations',
      permission: 2,
      label: 'Integrations',
      actionsLess: 1,
      path: '/integrations',
      permissionName: 'edit_integrations',
    },
    {
      name: 'Search Product',
      permission: 2,
      label: 'SearchProduct',
      actionsLess: 2,
      path: '/search-product',
      permissionName: 'search_product',
    },
    {
      name: 'Get Funding Button',
      permission: 2,
      label: 'GetFundingButton',
      actionsLess: 1,
      path: '/',
      permissionName: 'get_funding_button',
    },
    {
      name: 'Start Monthly Plan Button',
      permission: 2,
      label: 'StartMonthlyPlanButton',
      actionsLess: 1,
      path: '/',
      permissionName: 'start_monthly_plan_button',
    },
    {
      name: 'Payment Methods',
      permission: 0,
      label: 'PaymentMethods',
      actionsLess: 1,
      path: '/',
      permissionName: 'edit_payment_methods',
    },
    {
      name: 'Settings',
      permission: 2,
      label: 'Settings',
      actionsLess: 3,
      path: '/settings',
      permissionName: 'settings',
    },
  ],
};
export default data.screenPermissionList;
