import state from './moduleUserInfoState.js';
import mutations from './moduleUserInfoMutations.js';
import actions from './moduleUserInfoActions.js';
import getters from './moduleUserInfoGetters.js';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
