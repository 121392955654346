export default {
  opportunityFilters: {
    searchKeyword: '',
    filterList: [
      {
        name: 'KAD',
        value: 'salesAccountManager',
        type: 'multiple',
        childrenActive: false,
        filterSearch: '',
        children: [],
      },
      {
        name: 'BDR',
        value: 'salesDevelopmentRepresentative',
        type: 'multiple',
        childrenActive: false,
        filterSearch: '',
        children: [],
      },
      {
        name: 'CS Manager',
        value: 'customerSuccessManager',
        type: 'multiple',
        childrenActive: false,
        filterSearch: '',
        children: [],
      },
      {
        name: 'Stage',
        value: 'stage',
        type: 'multiple',
        childrenActive: false,
        children: [],
      },
      {
        name: 'Solution Of Interest',
        value: 'solutionOfInterest',
        type: 'multiple',
        hasNested: false,
        childrenActive: false,
        children: [],
      },
      {
        name: 'Lead Source',
        value: 'leadSource',
        type: 'single',
        childrenActive: false,
        children: [],
      },
      {
        name: 'Lead Source Event',
        value: 'leadSourceEvent',
        type: 'multiple',
        childrenActive: false,
        children: [],
      },
      {
        name: 'Reason',
        value: 'reason',
        type: 'multiple',
        childrenActive: false,
        children: [
          {
            name: 'All',
            value: 'all',
            selected: false,
          },
          {
            name: 'Pricing',
            value: 'Pricing',
            selected: false,
          },
          {
            name: 'Functionality',
            value: 'Functionality',
            selected: false,
          },
          {
            name: 'Lost to Competitor',
            value: 'Lost to Competitor',
            selected: false,
          },
          {
            name: 'InHouse Management',
            value: 'InHouse Management',
            selected: false,
          },
          {
            name: 'No Interest',
            value: 'No Interest',
            selected: false,
          },
          {
            name: 'No Interaction',
            value: 'No Interaction',
            selected: false,
          },
          {
            name: 'Not a Seller',
            value: 'Not a Seller',
            selected: false,
          },
        ],
      },
      {
        name: 'Forecast',
        value: 'forecast',
        type: 'multiple',
        childrenActive: false,
        children: [
          {
            name: 'All',
            value: 'all',
            selected: false,
          },
          {
            name: 'Forecast',
            value: 'Forecast',
            selected: false,
          },
          {
            name: 'Upside',
            value: 'Upside',
            selected: false,
          },
          {
            name: 'Commit',
            value: 'Commit',
            selected: false,
          },
        ],
      },
      {
        name: 'Close Probability',
        value: 'closeProbability',
        type: 'multiple',
        childrenActive: false,
        children: [
          {
            name: 'All',
            value: 'all',
            selected: false,
          },
          {
            name: '0%',
            selected: false,
            value: 0,
          },
          {
            name: '10%',
            selected: false,
            value: 10,
          },
          {
            name: '25%',
            selected: false,
            value: 25,
          },
          {
            name: '50%',
            selected: false,
            value: 50,
          },
          {
            name: '80%',
            selected: false,
            value: 80,
          },
          {
            name: '100%',
            selected: false,
            value: 100,
          },
        ],
      },
      {
        name: 'Opportunity Source',
        value: 'sourceFilter',
        type: 'multiple',
        childrenActive: false,
        children: [
          {
            name: 'All',
            value: 'all',
            selected: false,
          },
          {
            name: 'CRM - Manual',
            selected: false,
            value: 1,
          },
          {
            name: 'Suspect to Prospect',
            selected: false,
            value: 2,
          },
          {
            name: 'Demand Generation',
            selected: false,
            value: 3,
          },
          {
            name: 'Marketting Form',
            selected: false,
            value: 4,
          },
          {
            name: 'Prospect to Customer',
            selected: false,
            value: 5,
          },
        ],
      },
      {
        name: 'Action - Status',
        value: 'status',
        type: 'multiple',
        childrenActive: false,
        children: [
          {
            name: 'All',
            value: 'all',
            selected: false,
          },
          {
            name: 'To Do',
            selected: false,
            value: 1,
          },
          {
            name: 'In Progress',
            selected: false,
            value: 2,
          },
          {
            name: 'Done',
            selected: false,
            value: 3,
          },
          {
            name: 'Cancel',
            selected: false,
            value: 4,
          },
        ],
      },
      {
        name: 'Action - Due Status',
        value: 'dueStatus',
        type: 'single',
        childrenActive: false,
        children: [
          { name: 'All', value: 'all', selected: false },
          { name: 'On Time', value: 1, selected: false },
          { name: 'Overdue', value: 2, selected: false },
        ],
      },
      {
        name: 'Priority',
        value: 'priority',
        type: 'multiple',
        childrenActive: false,
        children: [
          { name: 'All', value: 'all', selected: false },
          { name: 'Cold', value: 1, selected: false },
          { name: 'Warm', value: 2, selected: false },
          { name: 'Hot', value: 3, selected: false },
        ],
      },
      {
        name: 'Account Status',
        value: 'accountStatus',
        type: 'multiple',
        childrenActive: false,
        children: [
          { name: 'All', value: 'all', selected: false },
          { name: 'Customer', value: 0, selected: false },
          { name: 'Prospect', value: 1, selected: false },
          { name: 'Churn', value: 2, selected: false },
          { name: 'Suspect', value: 3, selected: false },
        ],
      },
    ],
  },
};
